import { ComponentProps, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import withSizes from "react-sizes";

import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EditIcon from "@mui/icons-material/Edit";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";

import { Account, User, userManager } from "@tesseract/core";
import type { Theme } from "@tesseract/theme";
import DarkModeToggle from "./DarkModeToggle";
import MenuItems from "./MenuItems";
import breakpoints from "utils/styles/breakpoints";
import { actionGenerators } from "features/ExtensionMessageHandler/state";
import { useIsChromeExtension } from "hooks/useIsChromeExtension";
import LegalInformationLinks from "features/Legal/components/LegalInformationLinks";
import Avatar from "components/Avatar";
import { getTotalUnansweredCount } from "features/MainNavigation/utils";

const StyledBadge = styled(Badge)`
  span {
    top: 5px;
  }
`;

type Props = {
  accountNavbar: {
    members: object[];
  };
  appColors: Theme;
  currentAccount: Account.Raw;
  currentUser: User.Raw;
  updateAppColors: () => void;
};

type ComposedProps = {
  bottomNav: boolean;
};

function UserMenu({
  accountNavbar,
  appColors,
  bottomNav,
  currentAccount,
  currentUser,
  updateAppColors,
}: Props & ComposedProps) {
  const popperAnchorRef = useRef<HTMLButtonElement>(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const dispatch = useDispatch();
  const totalBadgeCount = getTotalUnansweredCount(accountNavbar);
  const isExtension = useIsChromeExtension();

  if (isExtension) {
    const origin =
      process.env.NODE_ENV === "production"
        ? "chrome-extension://hjnjkiddcmpamechmdjonecfjckboean"
        : "*";
    window.parent.postMessage(
      { type: "setExtensionIcon", payload: totalBadgeCount },
      origin,
    );
  }

  // Make a simple request:
  if (typeof chrome !== "undefined" && chrome.runtime) {
    chrome.runtime
      .sendMessage("hjnjkiddcmpamechmdjonecfjckboean", {
        type: "setExtensionIcon",
        payload: totalBadgeCount,
      })
      .then((response) => {
        console.log(response);
        return null;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleClose = () => {
    return setPopperOpen(false);
  };
  const handleClick: ComponentProps<typeof Button>["onClick"] = () => {
    return setPopperOpen(!popperOpen);
  };

  const logout: ComponentProps<typeof Button>["onClick"] = (event) => {
    event.preventDefault();
    dispatch(actionGenerators.sendTesseractLogoutMessage());
    userManager.signoutRedirect();
  };

  return (
    <Box flex="0 0 auto" mr={1}>
      <Button
        aria-label="Open User Menu"
        data-testid="open-user-menu"
        onClick={handleClick}
        ref={popperAnchorRef}
      >
        <StyledBadge
          badgeContent={
            accountNavbar.members.length > 1 ? totalBadgeCount : null
          }
          color="error"
          sx={{
            "& .MuiBadge-badge": {
              color: "customColors.badgeText",
            },
          }}
        >
          <Avatar subject={currentUser} size={38} />
        </StyledBadge>
      </Button>
      <Popper open={popperOpen} anchorEl={popperAnchorRef.current} transition>
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: bottomNav ? "bottom right" : "100% 0 0",
              }}
              timeout={350}
            >
              <Box
                aria-label="User Menu"
                width="320px"
                mr={{ xs: 0, sm: 1 }}
                mb={{ xs: 1, sm: 0 }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper elevation={8}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "row", sm: "column" }}
                      alignItems="center"
                      p={{ xs: 3, sm: 4 }}
                      border={1}
                      borderColor="divider"
                      borderTop={0}
                      borderRight={0}
                      borderLeft={0}
                      position="relative"
                    >
                      <Tooltip title="Edit Profile">
                        <Box position="absolute" top="20px" right="20px">
                          <Link to="/profile" onClick={handleClose}>
                            <IconButton size="large">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </Box>
                      </Tooltip>
                      <Box flex="0 0 auto">
                        <Avatar
                          subject={currentUser}
                          size={bottomNav ? 60 : 88}
                        />
                      </Box>
                      <Box
                        flex="1 1 auto"
                        minWidth={0}
                        ml={{ xs: 2, sm: 0 }}
                        mr={{ xs: 4, sm: 0 }}
                        textAlign={{ xs: "left", sm: "center" }}
                        whiteSpace="nowrap"
                      >
                        <Box
                          component="h3"
                          mt={{ xs: 0, sm: 2 }}
                          mb={0}
                          color="primary.main"
                          fontSize={{ xs: "16px", sm: "20px" }}
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {currentUser.name}
                        </Box>
                        <Box
                          component="h4"
                          fontWeight="fontWeightRegular"
                          m={0}
                          fontSize={{ xs: "14px", sm: "16px" }}
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {currentUser.email}
                        </Box>
                      </Box>
                    </Box>
                    <MenuItems
                      closeMenu={handleClose}
                      currentUser={currentUser}
                      currentAccount={currentAccount}
                    />
                    <Box
                      border={1}
                      borderColor="divider"
                      borderBottom={0}
                      borderRight={0}
                      borderLeft={0}
                      px={2}
                      py={1}
                      display="flex"
                      alignItems="space-between"
                      justifyContent="space-between"
                    >
                      <DarkModeToggle
                        appColors={appColors}
                        currentAccount={currentAccount}
                        updateAppColors={updateAppColors}
                      />
                      <Button
                        aria-label="Log Out"
                        data-testid="log-out-button"
                        onClick={logout}
                        color="primary"
                      >
                        <PowerSettingsNewIcon />
                        <Box style={{ textTransform: "none" }} ml={1}>
                          Log Out
                        </Box>
                      </Button>
                    </Box>
                    <LegalInformationLinks closePopover={handleClose} />
                  </Paper>
                </ClickAwayListener>
              </Box>
            </Grow>
          );
        }}
      </Popper>
    </Box>
  );
}

export default withSizes<ComposedProps, ComposedProps & Props>(({ width }) => {
  return {
    bottomNav: width < breakpoints.medium,
  };
})(UserMenu) as (p: Props) => JSX.Element;
