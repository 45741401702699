import { useState, MouseEvent } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {
  BullhornCandidateLink,
  GreenhouseCandidateLink,
} from "features/ContactSync";
import { Contact } from "features/ContactSidebar/types";

interface ContactSyncHyperlinkProps {
  contact: Contact;
}

function ContactSyncHyperlink({ contact }: ContactSyncHyperlinkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return contact?.data?.greenhouse?.Candidate &&
    contact?.data?.bullhorn?.Candidate ? (
    /*
      If contact has both Greenhouse and Bullhorn candidate data, render a button that opens a menu with two candidate links
    */
    <>
      <Button
        onClick={handleMenuOpen}
        sx={{
          color: (theme) => {
            return theme.palette.text.hyperlink;
          },
          fontSize: "12px",
          fontWeight: 400,
          marginRight: "auto",
          padding: 0,
          paddingLeft: "4px",
          textDecoration: "underline",
          textTransform: "none",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        2 integrations
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem
          sx={{
            fontSize: "16px",
          }}
          onClick={() => {
            return window
              .open(contact.data?.greenhouse?.Candidate?.link ?? "", "_blank")
              ?.focus();
          }}
        >
          Greenhouse ID {contact.data?.greenhouse?.Candidate?.id ?? ""}
        </MenuItem>
        <MenuItem
          sx={{
            fontSize: "16px",
          }}
          onClick={() => {
            return window
              .open(contact.data?.bullhorn?.Candidate?.link ?? "", "_blank")
              ?.focus();
          }}
        >
          Bullhorn ID {contact.data?.bullhorn?.Candidate?.id ?? ""}
        </MenuItem>
      </Menu>
    </>
  ) : (
    /*
      If contact has only one integration candidate data, render the corresponding candidate link
    */
    <>
      <BullhornCandidateLink contact={contact} fromConversation />
      <GreenhouseCandidateLink contact={contact} fromConversation />
    </>
  );
}

export { ContactSyncHyperlink };
