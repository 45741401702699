import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Root from "./components/Root";
import { actionGenerators, saga, reducer, selectors } from "./state";
import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";

class NewCampaign extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    contactCollection: selectors.selectContactCollection(state, props),
    contactFilter: selectors.selectContactFilter(state, props),
    contactImport: selectors.selectContactImport(state, props),
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
    deliveryStats: selectors.selectDeliveryStats(state, props),
    newCampaignContainer: selectors.selectNewCampaign(state, props),
    recipients: selectors.selectRecipients(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "newCampaignContainer",
  reducer,
});
const withSaga = injectSaga({ key: "newCampaignContainer", saga });

export default compose(withReducer, withSaga, withConnect)(NewCampaign);
