import startCase from "lodash/startCase";
import { compactInteger } from "humanize-plus";
import { Box, Typography } from "@mui/material";
import { CampaignMetricProps } from "./types";

// TODO: Unit test for this component

function CampaignMetric({ campaignAnalytics, metric }: CampaignMetricProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      key={metric}
      p="0.5rem"
    >
      <Typography variant="overline" color="text.secondary" whiteSpace="nowrap">
        {startCase(metric)}
      </Typography>
      <Typography variant="dataMedium" color="primary">
        {compactInteger(
          /rate/i.test(metric)
            ? campaignAnalytics[metric] * 100
            : campaignAnalytics[metric],
          1,
        )}
        {/rate/i.test(metric) && (
          <Typography variant="dataMedium" color="primary">
            %
          </Typography>
        )}
      </Typography>
    </Box>
  );
}

export default CampaignMetric;
