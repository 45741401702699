import Box from "@mui/material/Box";
import BullhornLogo from "utils/images/bullhorn-logo-notext.png";

// Add to this as more Contact Sync partners are onboarded
export type PartnerNames = "bullhorn";

interface PartnerLogoProps {
  partner: PartnerNames;
}

function PartnerLogo({ partner }: PartnerLogoProps) {
  const logoMap = {
    bullhorn: BullhornLogo,
  };

  return (
    <Box
      component="img"
      height="14px"
      width="17px"
      margin="2px"
      alt="Partner logo"
      src={logoMap[partner]}
    />
  );
}

export { PartnerLogo };
