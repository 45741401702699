import { styled } from "@mui/material";
import { GreenhouseCandidateLinkProps } from "./types";

const CustomSchemaIDLink = styled("a", {
  shouldForwardProp: (prop) => {
    return prop !== "fromConversation";
  },
})<Pick<GreenhouseCandidateLinkProps, "fromConversation">>(({ fromConversation, theme }) => {
  return {
    color: theme.palette.text.hyperlink,
    fontSize: "12px",
    fontWeight: 400,
    margin: 0,
    marginLeft: fromConversation ? "4px" : 0,
    textAlign: "left",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  };
});

function GreenhouseCandidateLink({
  contact,
  fromConversation,
}: GreenhouseCandidateLinkProps) {
  const candidate = contact?.data?.greenhouse?.Candidate;

  return candidate ? (
    <CustomSchemaIDLink
      href={candidate?.link ?? ""}
      fromConversation={fromConversation}
      target="_blank"
    >
      Greenhouse ID {candidate?.id}
    </CustomSchemaIDLink>
  ) : null;
}

export { GreenhouseCandidateLink };
