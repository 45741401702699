import { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReportIcon from "@mui/icons-material/Report";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import Tooltip from "@mui/material/Tooltip";

const OPTIONS = [
  {
    key: "assign",
    primary: "Manage Assigned Contact",
    secondary: "Configure whose contact information you want to see",
    icon: <AssignmentIndIcon />,
  },
  {
    key: "unsubscribe",
    primary: "Opt Out Number",
    secondary: "This number will no longer receive messages",
    icon: <SpeakerNotesOffIcon />,
  },
  {
    key: "block",
    primary: "Block Number",
    secondary: "You will no longer receive messages from this contact",
    icon: <ReportIcon />,
  },
  {
    key: "unblock",
    primary: "Unblock Number",
    secondary: "You will receive messages from this contact",
    icon: <ReportOffIcon />,
  },
];

const styles = () => {
  return {
    menuItem: {
      height: "auto",
      whiteSpace: "normal",
    },
  };
};

class PhoneActionsOption extends Component {
  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    blockConversationRequest: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    hasMultipleAssociated: PropTypes.bool.isRequired,
    showAssignContacts: PropTypes.func.isRequired,
    subscribeConversationRequest: PropTypes.func.isRequired,
    unblockConversationRequest: PropTypes.func.isRequired,
    unsubscribeConversationRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  getAuthorizedOptions = () => {
    const { conversation, hasMultipleAssociated } = this.props;
    return OPTIONS.filter(({ key }) => {
      return key === "assign"
        ? hasMultipleAssociated
        : conversation[`${key}Conversation`];
    });
  };

  handleActionsOptionClick = ({ key }) => {
    return () => {
      const { conversation, showAssignContacts } = this.props;
      this.handleOptionsClose();
      if (key === "assign") return showAssignContacts();
      return this.props[`${key}ConversationRequest`](
        conversation[`${key}Conversation`],
      );
    };
  };

  handleOptionsClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOptionsClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const options = this.getAuthorizedOptions();
    return (
      <>
        <Tooltip title="Phone options" disableFocusListener>
          <IconButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleOptionsClick}
            size="large"
            sx={{ color: "text.secondary" }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          disableAutoFocusItem
          getContentAnchorEl={null}
          MenuListProps={{ disablePadding: true }}
          onClose={this.handleOptionsClose}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={option.key}
                onClick={this.handleActionsOptionClick(option)}
                className={classes.menuItem}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText
                  primary={option.primary}
                  secondary={option.secondary}
                />
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(PhoneActionsOption);
