import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ConversationThread, {
  ConversationThreadWrapper,
} from "components/ConversationThread";

const Root = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  height: 100%;
  width: 100%;

  ${ConversationThreadWrapper} {
    height: 100%;
    width: 100%;
  }
`;

export default class ConversationPreview extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    conversationPreviewContainer: PropTypes.object.isRequired,
    paginateTimelineRequest: PropTypes.func.isRequired,
    typingIndicatorsContainer: PropTypes.object.isRequired,
  };

  renderContent() {
    const {
      appSettings,
      paginateTimelineRequest,
      conversationPreviewContainer: { denormalizedRecord: conversation },
      typingIndicatorsContainer,
    } = this.props;
    const conversationTypingIndicators =
      typingIndicatorsContainer.typingIndicators[conversation.id] || [];
    return conversation.timeline && conversation.timeline.members ? (
      <ConversationThread
        appSettings={appSettings}
        activeConversation={conversation}
        containerQueryParams={{
          short: true,
          small: true,
        }}
        paginateTimelineRequest={paginateTimelineRequest}
        typingIndicators={conversationTypingIndicators}
      />
    ) : null;
  }

  render() {
    return <Root>{this.renderContent()}</Root>;
  }
}
