/* eslint-disable react/no-unstable-nested-components */
import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { withRouter } from "react-router-dom";

import CampaignRecipientsBatchActions from "./CampaignRecipientsBatchActions";
import CampaignRecipientsFailures from "./CampaignRecipientsFailures";
import Table from "components/Table";

// TODO: Refactor this component to use DataGridPro
// Will refactor out styled-components in that PR
const Wrapper = styled.div`
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex-flow: column;
  overflow-x: auto;
  position: relative;
  width: 100%;
`;

const ContactName = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-weight: 700;
`;

const ContactNumber = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 0.75rem;
`;

const IconWrapper = styled.span`
  cursor: help;
  margin-left: 6px;
`;

class Root extends Component {
  static propTypes = {
    campaignRecipientCollection: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setCompose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      allSelected: false,
      selected: [],
      selectedRecords: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { campaignRecipientCollection } = this.props;

    const { allSelected, selected } = this.state;
    if (
      allSelected &&
      prevProps.campaignRecipientCollection.id !==
        campaignRecipientCollection.id
    ) {
      const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
        (recipient) => {
          return recipient.id;
        },
      );
      this.setState({ selected: union(selected, visibleIds) });
    }
  }

  setAllSelected = (allSelected) => {
    const { campaignRecipientCollection } = this.props;
    const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
      (recipient) => {
        return recipient.id;
      },
    );
    this.setState((prevState) => {
      return {
        allSelected,
        selected: allSelected ? union(prevState.selected, visibleIds) : [],
        selectedRecords: [],
      };
    });
  };

  setSelected = (selected) => {
    this.setState({ allSelected: false, selected });
  };

  setSelectedRecords = (selected) => {
    this.setState({ allSelected: false, selectedRecords: selected });
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (campaignRecipient) => {
          return get(campaignRecipient, ["contactPhone", "contact", "name"]);
        },
        showContentInTooltip: true,
      },
      {
        title: "Number",
        getTableCellContent: (campaignRecipient) => {
          return get(campaignRecipient, [
            "contactPhone",
            "formattedPhoneNumber",
          ]);
        },
        nowrap: true,
      },
      {
        title: (() => {
          const sentAtDate = get(this.props, [
            "campaignRecipientCollection",
            "members",
            0,
            "sentAt",
          ]);
          const formattedDate = `(${moment(sentAtDate).format("L")})`;
          return `Sent At ${(sentAtDate && formattedDate) || ""}`;
        })(),
        getTableCellContent: (campaignRecipient) => {
          const sentAt = get(campaignRecipient, ["sentAt"]);
          return sentAt ? moment(sentAt).format("hh:mm:ss a z") : "-";
        },
        showContentInTooltip: true,
      },
      {
        title: "Delivered",
        align: "center",
        getTableCellContent: (campaignRecipient) => {
          const delivered = get(campaignRecipient, ["delivered"]);
          const unknown = get(campaignRecipient, ["unknown"]);
          const tooltipContent = "Did not receive a delivery confirmation";
          if (delivered) {
            return <CheckIcon />;
          }
          if (unknown) {
            return (
              <Tooltip title={tooltipContent} placement="bottom">
                <IconWrapper>
                  <HelpOutlineIcon fontSize="medium" />
                </IconWrapper>
              </Tooltip>
            );
          }
          return "-";
        },
      },
      {
        title: "Replied In",
        align: "center",
        getTableCellContent: (campaignRecipient) => {
          const replyLag = get(campaignRecipient, ["replyLag"]);
          return replyLag ? moment.duration(replyLag).humanize() : "-";
        },
      },
      {
        title: (() => {
          return (
            <CampaignRecipientsFailures
              match={this.props.match}
              location={this.props.location}
            />
          );
        })(),
        getTableCellContent: (campaignRecipient) => {
          const state = get(campaignRecipient, ["state"]);
          const error = get(campaignRecipient, ["errorDescription"]);
          if (state === "cutoff") {
            const tooltipContent =
              "Message not sent because delivery was attempted after the provided cutoff time";
            return (
              <div>
                <span>Cutoff</span>
                <Tooltip title={tooltipContent} placement="bottom">
                  <IconWrapper>
                    <HelpOutlineIcon fontSize="medium" />
                  </IconWrapper>
                </Tooltip>
              </div>
            );
          }
          if (state === "canceled") {
            const tooltipContent =
              "Message not sent because campaign was canceled";
            return (
              <div>
                <span>Canceled</span>
                <Tooltip title={tooltipContent} placement="bottom">
                  <IconWrapper>
                    <HelpOutlineIcon fontSize="medium" />
                  </IconWrapper>
                </Tooltip>
              </div>
            );
          }
          return error || "-";
        },
        showContentInTooltip: true,
      },
    ];
  };

  getCollapsedColumns = () => {
    return [
      {
        title: "Contact",
        getTableCellContent: (campaignRecipient) => {
          const contactName = get(campaignRecipient, [
            "contactPhone",
            "contact",
            "name",
          ]);
          const contactNumber = get(campaignRecipient, [
            "contactPhone",
            "formattedPhoneNumber",
          ]);
          return (
            <div>
              <ContactName>{contactName}</ContactName>
              {contactNumber && <ContactNumber>{contactNumber}</ContactNumber>}
            </div>
          );
        },
      },
    ];
  };

  handleSetCompose = () => {
    const { campaignRecipientCollection, setCompose } = this.props;
    const { selected } = this.state;
    const recipientIds = selected.map((selectedId) => {
      return campaignRecipientCollection.members.find((recipient) => {
        return recipient.id === selectedId;
      }).contactPhone.id;
    });
    setCompose({ active: true, recipientIds });
  };

  render() {
    const { campaignRecipientCollection, currentAccount } = this.props;
    const { allSelected, selected, selectedRecords } = this.state;
    return (
      <Wrapper>
        {!isEmpty(campaignRecipientCollection) && selected.length > 0 && (
          <CampaignRecipientsBatchActions
            allSelected={allSelected}
            campaignRecipientCollection={campaignRecipientCollection}
            currentAccount={currentAccount}
            handleSetCompose={this.handleSetCompose}
            selected={selected}
            selectedRecords={selectedRecords}
            setAllSelected={this.setAllSelected}
            setSelected={this.setSelected}
            setSelectedRecords={this.setSelectedRecords}
            {...this.props}
          />
        )}
        <Table
          collapsedColumns={this.getCollapsedColumns()}
          columns={this.getColumns()}
          collection={campaignRecipientCollection}
          selected={selected}
          selectedRecords={selectedRecords}
          setSelected={this.setSelected}
          setSelectedRecords={this.setSelectedRecords}
          withBatchActions
        />
      </Wrapper>
    );
  }
}

export default withRouter(Root);
