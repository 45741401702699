/* eslint-disable react-hooks/exhaustive-deps */
import { useState, MouseEvent, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Location } from "history";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Keyword as CoreKeyword } from "@tesseract/core";
import { Keyword, KeywordTableProps, Order } from "../models/AutomationModels";
import stripKeywordId from "../utils/stripKeywordId";
import { StyledTableCell, StyledTableRow } from "./styles";
import KeywordTableHeader from "./KeywordTableHeader";
import { CachedIcon, DeleteIcon, EditIcon, GroupsIcon } from "icons";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import PageLoader from "components/PageLoader";

import { useCurrentAccount } from "hooks";

import createUUID from "utils/uuid";
import { isDefined } from "utils/isDefined";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function KeywordTable({
  discardedKeywords = false,
  keywords,
  page,
  searchTerm = "",
  handleFetchAutomationKeywords,
  reimplementKeyword,
  setPage,
  toggleDeleteModal,
  toggleEditModal,
}: KeywordTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Keyword>("keyword");
  const [loading, setLoading] = useState<boolean>(true);

  const { name: accountName } = useCurrentAccount();
  const rowsPerPage = 10;

  useEffect(() => {
    setLoading(!keywords?.items);
  }, [keywords?.items]);

  useEffect(() => {
    const getPage = async () => {
      setLoading(true);
      await handleFetchAutomationKeywords({
        active: !discardedKeywords,
        items: rowsPerPage,
        keywordCont: searchTerm,
      });
      setLoading(false);
    };

    getPage().catch((error) => {
      console.error(error);
    });
  }, [page, searchTerm]);

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Keyword,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getComparator = <Key extends keyof Keyword>(
    orderArg: Order,
    orderByArg: Key,
  ): ((a: Keyword, b: Keyword) => number) => {
    return orderArg === "desc"
      ? (a, b) => {
          return descendingComparator(a, b, orderByArg);
        }
      : (a, b) => {
          return -descendingComparator(a, b, orderByArg);
        };
  };

  const { items = [] } = keywords ?? {};
  const tableLabel = discardedKeywords
    ? "Deleted Keyword Automation Table"
    : "Keyword Automation Table";

  return (
    <>
      {loading && <PageLoader />}

      {!loading && items.length === 0 && (
        <Box py={4}>
          <BlankState
            image={<Logo color="disabled" />}
            title={`No ${
              discardedKeywords ? "inactive" : "active"
            } keywords found`}
            subTitle={
              discardedKeywords
                ? "Inactive keywords will appear here"
                : "Create a keyword and auto-reponse using the 'Create New' keyword button"
            }
          />
        </Box>
      )}

      {!loading && items.length > 0 && (
        <Table aria-label={tableLabel}>
          <KeywordTableHeader
            discardedKeywords={discardedKeywords}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {items
              .sort(getComparator(order, orderBy))
              .map((row: CoreKeyword.Raw) => {
                const [month, date, year] = new Date(
                  discardedKeywords ? row.deletedAt : row.createdAt,
                )
                  .toLocaleDateString("en-US")
                  .split("/");
                const keywordGroupId = stripKeywordId(row.id);
                const groupRoute = `_groups/${keywordGroupId}`;
                const monthIndex = Number.parseInt(month, 10);
                const writeAccess = accountName === row.createdBy;
                return (
                  <StyledTableRow key={createUUID()}>
                    <StyledTableCell>
                      {row.keyword.toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell>{row.responseBody}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.timesReceived}
                    </StyledTableCell>
                    <StyledTableCell>{row.createdBy}</StyledTableCell>
                    <StyledTableCell>
                      {`${monthIndex}-${date}-${year}`}
                    </StyledTableCell>
                    <StyledTableCell sx={{ paddingRight: "8px" }}>
                      <Box display="flex">
                        <Box>
                          <Tooltip
                            title={
                              writeAccess
                                ? "Open Edit Keyword Modal"
                                : "You do not have edit access."
                            }
                          >
                            <IconButton
                              onClick={() => {
                                if (writeAccess) toggleEditModal(row);
                              }}
                            >
                              <EditIcon color="primary" sx={{ fontSize: 22 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title="View Keyword Subscribers">
                            <IconButton
                              component={RouterLink}
                              to={(location: Location) => {
                                return `${location.pathname}${groupRoute}`;
                              }}
                            >
                              <GroupsIcon
                                color="primary"
                                sx={{ fontSize: 22 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box>
                          {discardedKeywords ? (
                            <Tooltip
                              title={
                                writeAccess
                                  ? "Reimplement Keyword"
                                  : "You cannot reimplement."
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  if (writeAccess && reimplementKeyword)
                                    reimplementKeyword(row);
                                }}
                              >
                                <CachedIcon
                                  color="primary"
                                  sx={{ fontSize: 22 }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                writeAccess
                                  ? "Open Delete Keyword Modal"
                                  : "You do not have delete access."
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  if (
                                    writeAccess &&
                                    isDefined(toggleDeleteModal)
                                  ) {
                                    toggleDeleteModal(row);
                                  }
                                }}
                              >
                                <DeleteIcon
                                  color="primary"
                                  sx={{ fontSize: 22 }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={keywords.page.count}
                onPageChange={handlePageChange}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                style={{ borderBottom: "none" }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </>
  );
}

export default KeywordTable;
