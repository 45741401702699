import { Box, Typography, useTheme } from "@mui/material";
import { NoResultsScreenProps } from "./types";
import BlankSearchIcon from "icons/BlankSearchIcon";
import NoResultsIcon from "icons/NoResultsIcon";

function NoResultsScreen({ source, active }: NoResultsScreenProps) {
  const textOptions = {
    noResults: `No ${source} found.`,
    contacts: "You can search by name or number to find an individual contact.",
    conversations: "You can search by name, number, or conversation content.",
    sequences: "Search by sequence name.",
  };

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="1.5rem 1rem"
      height="100%"
      width="100%"
    >
      {active ? (
        <NoResultsIcon mode={theme.palette.mode} />
      ) : (
        <BlankSearchIcon mode={theme.palette.mode} />
      )}
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        pt="1rem"
      >
        {active ? textOptions.noResults : textOptions[source]}
      </Typography>
    </Box>
  );
}

export { NoResultsScreen };
