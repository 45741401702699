import { Account, User } from "@tesseract/core";

const getMessagingRestrictedCopy = (
  account: Account.Raw,
  user: User.Raw,
  prefix = "Outbound messaging",
) => {
  if (account?.settings?.messagingManuallyDisabled) {
    return `${prefix} is suspended due to a lack of proper 10DLC registration. Please contact an Organization Admin for resolution.`;
  }

  if (user?.adminUserSchema) {
    return `${prefix} is disabled. \n You will need to update your \n Organization's 10DLC Use Case.`;
  }

  return `${prefix} is disabled. \n An Organization Admin will need to \n update your 10DLC Use Case.`;
};

export default getMessagingRestrictedCopy;
