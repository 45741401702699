/**
 *
 * ConversationSidebar
 *
 */

import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { reducer, selectors, actionGenerators, saga } from "./state";
import MainView from "./components/MainView";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";

class ConversationSidebar extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    scheduledMessagesContainer: selectors.selectScheduledMessages(state, props),
  };
};

const withReducer = injectReducer({
  key: "scheduledMessages",
  reducer,
});
const withSaga = injectSaga({ key: "scheduledMessages", saga });

const withConnect = connect(mapStateToProps, { ...actionGenerators });

export default compose(withReducer, withSaga, withConnect)(ConversationSidebar);
