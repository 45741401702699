import { Component } from "react";
import PropTypes from "prop-types";
import MainView from "./components/MainView";
import withRecord from "higherOrderComponents/withRecord";

export class DeliveryStats extends Component {
  static propTypes = {
    deliveryStatsId: PropTypes.string.isRequired,
    fetchDeliveryStatsRequest: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { deliveryStatsId, fetchDeliveryStatsRequest, location } = this.props;
    if (
      (location.pathname.includes("/new") &&
        !prevProps.location.pathname.includes("/new")) ||
      (location.pathname.endsWith("scheduled") &&
        !prevProps.location.pathname.endsWith("scheduled"))
    ) {
      fetchDeliveryStatsRequest(deliveryStatsId);
    }
  }

  render() {
    return <MainView {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "Campaigns/containers/DeliveryStats",
  shape: {},
  type: "deliveryStats",
})(DeliveryStats);
