/*
Attempt to silently sign in the user when the app boots or 401s.

If it succeeds during boot, then the user is loaded into redux and the app loads.
If it succeeds on a 401, then the app is reloaded.

If the silent sign in fails, then the user is redirected to the login screen.
*/

import { userManager } from "@tesseract/core";
import moment from "moment";
import sessionStorageHelper from "utils/sessionStorageHelper";

const redirectToLoginScreen = () => {
  return userManager.signinRedirect({
    data: {
      redirectUrl: window.location.pathname,
    },
  });
};

export default async () => {
  try {
    const user = await userManager.getUser();
    const accessToken = sessionStorageHelper.getItem("access_token");
    if (
      !accessToken &&
      (!user || moment.unix(user.expires_at).isBefore(moment()))
    ) {
      await userManager.signinSilent();
    }
    return Promise.resolve();
  } catch (error) {
    userManager.clearStaleState();
    return redirectToLoginScreen();
  }
};
