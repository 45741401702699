/* eslint-disable no-restricted-globals */
import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withSizes from "react-sizes";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import isMatch from "lodash/isMatch";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

import { fetchPreviousImports } from "../api/fetchPreviousImports";
import ContactsSideNav from "./ContactsSideNav";
import breakpoints from "utils/styles/breakpoints";
import List from "components/List";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import H4 from "components/H4";

const DEFAULT_FILTER_SLUGS = new Set([
  "account",
  "blocked",
  "opted-out",
  "never_messaged",
  "unmessageable",
  "all",
]);

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: auto;
`;

const ContactFilter = styled.li``;

const ContactFilterList = styled(List)`
  flex: 0 0 auto;
`;

const DefaultFiltersSection = styled.div``;

const SavedFiltersSection = styled.div`
  margin-top: 40px;
`;

const SavedSearchHeader = styled(H4)`
  padding: 16px 20px;
`;

const SavedSearchNavLink = styled(PageSidebarNavLink)`
  padding: 4px;
  padding-left: 20px;
`;

const DeleteButton = styled(IconButton)`
  &:disabled {
    visibility: hidden;
  }
`;

class Root extends Component {
  static propTypes = {
    contactFilterCollection: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    deleteContactFilterRequest: PropTypes.func.isRequired,
    fetchContactFilterCollectionRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isSinglePanel: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeleteOn: undefined,
      previousImports: [],
    };
  }

  async componentDidMount() {
    await this.fetchPreviousImports();
  }

  fetchPreviousImports = async () => {
    try {
      const response = await fetchPreviousImports(this.props.currentAccount);
      if (response.ok) {
        const previousImports = await response.json();

        this.setState((previousState) => {
          return {
            ...previousState,
            previousImports,
          };
        });
      }
    } catch {
      console.error("Error fetching previous imports.");
    }
  };

  handleClick = () => {
    if (this.props.isSinglePanel) {
      this.props.toggleSidebar();
    }
  };

  handleDelete = (filter) => {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();
      const {
        deleteContactFilterRequest,
        fetchContactFilterCollectionRequest,
        contactFilterCollection,
      } = this.props;
      deleteContactFilterRequest(filter.id, null, {
        successCallback: () => {
          fetchContactFilterCollectionRequest(contactFilterCollection.id);
        },
      });
    };
  };

  handleMouseEnter = (filterId) => {
    return () => {
      this.setState({ showDeleteOn: filterId });
    };
  };

  handleMouseLeave = () => {
    this.setState({ showDeleteOn: undefined });
  };

  renderContactFilterList = (filters, { type = "default" } = {}) => {
    const { showDeleteOn } = this.state;
    const { currentAccount, match: routerMatch } = this.props;
    return (
      <ContactFilterList>
        {filters.map((filter) => {
          const [pathname, query] = `/${
            currentAccount.slug
          }/contacts/${decodeURIComponent(filter.slug)}`.split("?");
          const filterParams = queryString.parse(query);
          const filterParamsWithoutPageAndCursor = {
            ...filterParams,
            cursor: undefined,
            page: undefined,
          };
          const search = isEmpty(filterParamsWithoutPageAndCursor)
            ? ""
            : `?${queryString.stringify(filterParamsWithoutPageAndCursor)}`;
          const isActiveFunc = (match, location) => {
            const locationParams = queryString.parse(location.search);
            const locationParamsWithoutPageAndCursor = {
              ...locationParams,
              cursor: undefined,
              page: undefined,
            };
            return (
              location.pathname === pathname &&
              isMatch(
                filterParamsWithoutPageAndCursor,
                locationParamsWithoutPageAndCursor,
              )
            );
          };
          const NavComponent =
            type === "saved" ? SavedSearchNavLink : PageSidebarNavLink;
          return (
            <ContactFilter key={filter.id}>
              <NavComponent
                aria-label="Filter List Option"
                to={{ pathname, search }}
                onClick={this.handleClick}
                onMouseEnter={this.handleMouseEnter(filter.id)}
                onMouseLeave={this.handleMouseLeave}
                isActive={isActiveFunc}
                data-product-tour={`ContactFilterOption-${filter.slug}`}
              >
                {filter.title}
                {type === "saved" && (
                  <Tooltip title="Delete Saved Search">
                    <DeleteButton
                      onClick={this.handleDelete(filter)}
                      disabled={
                        !(
                          showDeleteOn === filter.id ||
                          isActiveFunc(routerMatch, location)
                        )
                      }
                    >
                      <DeleteIcon fontSize="medium" />
                    </DeleteButton>
                  </Tooltip>
                )}
              </NavComponent>
            </ContactFilter>
          );
        })}
      </ContactFilterList>
    );
  };

  render() {
    const { contactFilterCollection, currentAccount } = this.props;
    const defaultFilters = contactFilterCollection.members.filter((filter) => {
      return DEFAULT_FILTER_SLUGS.has(filter.slug);
    });
    const savedFilters = contactFilterCollection.members.filter((filter) => {
      return !DEFAULT_FILTER_SLUGS.has(filter.slug);
    });
    const { previousImports } = this.state;

    return (
      <Wrapper>
        <DefaultFiltersSection>
          <ContactsSideNav
            currentAccount={currentAccount}
            savedFilters={savedFilters}
            previousImports={previousImports.members}
            handleDelete={this.handleDelete}
          />
        </DefaultFiltersSection>
      </Wrapper>
    );
  }
}

export default withSizes(({ width }) => {
  return {
    isSinglePanel: width < breakpoints.medium,
  };
})(Root);
