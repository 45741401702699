import Box from "@mui/material/Box";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import InfoTooltip from "components/InfoTooltip";

export default function (description, title) {
  return (
    <InfoTooltip
      title={
        <Box
          aria-label={`Description for ${title}`}
          color="text.secondary"
          fontSize={12}
          p={2}
          maxWidth="320px"
        >
          {description}
        </Box>
      }
    >
      <Box
        aria-label={`Tooltip for ${title}`}
        display="flex"
        height="100%"
        px={1}
        py={2}
        style={{ zIndex: 1 }}
      >
        <InfoIconOutlined
          color="inherit"
          style={{
            cursor: "help",
          }}
        />
      </Box>
    </InfoTooltip>
  );
}
