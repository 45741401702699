import { utcToZonedTime, format } from "date-fns-tz";

/** Ex: 09/04/1992 - 12:22AM */
export const getNiceLongDate = (/** @type {Date} */ date) => {
  return format(date, "MM/dd/yyyy - h:mma");
};

/** Ex: 09/04 */
export const getUsShortHandDate = (/** @type {Date} */ date) => {
  return format(date, "MM/dd");
};

/** Ex: 12:22AM, 2022 */
export const getRestOfUsShorthand = (/** @type {Date} */ date) => {
  return format(date, "h:mma, yyyy");
};

export const formatInTimeZone = (date, fmt, timezone) => {
  return format(utcToZonedTime(date, timezone), fmt, { timezone });
};

/** Ex: 2022-09-04, in UTC */
export const formatYearMonthDayUtc = (/** @type {Date} */ date) => {
  return formatInTimeZone(date, "yyyy-MM-dd", "UTC");
};
