import withStyles from "@mui/styles/withStyles";
import AccordionDetails from "@mui/material/AccordionActions";

export default withStyles((theme) => {
  return {
    root: {
      background: theme.palette.customColors.backgrounds.secondary,
      padding: 0,
    },
  };
})((props) => {
  return <AccordionDetails {...props} />;
});
