import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { styled, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { parseISO } from "date-fns";
import { ContractTerm } from "../hooks/useCumulativeBillingAnalytics/types";

type AsOfDateFilterProps = {
  asOfDate: Date;
  getBillingAnalytics: () => void;
  selectedContractTerm: ContractTerm;
  setAsOfDate: Dispatch<SetStateAction<Date>>;
};

const getCurrentDate = () => {
  const today = new Date();
  const currentDate = new Date(today.setDate(today.getDate() - 1));
  return currentDate;
};

function AsOfDateFilter({
  asOfDate,
  getBillingAnalytics,
  selectedContractTerm,
  setAsOfDate,
}: AsOfDateFilterProps) {
  const { isCurrent, minDate, maxDate } = useMemo(() => {
    return {
      minDate: parseISO(selectedContractTerm.startDate),
      maxDate: parseISO(selectedContractTerm.endDate),
      isCurrent: selectedContractTerm.label === "Current term",
    };
  }, [
    selectedContractTerm.endDate,
    selectedContractTerm.label,
    selectedContractTerm.startDate,
  ]);

  useEffect(() => {
    if (isCurrent) {
      const dateValue = getCurrentDate();
      setAsOfDate(dateValue);
      return;
    }
    setAsOfDate(new Date(maxDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractTerm]);

  const StyledLabel = styled(Typography)(({ theme }) => {
    return {
      fontFamily: "Helvetica Neue",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "0.15px",
      paddingRight: "10px",
      color: theme.palette.text.secondary,
    };
  });

  const StyledTypography = styled(Typography)(({ theme }) => {
    return {
      fontFamily: "Helvetica Neue",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "138%",
      letterSpacing: "0.2px",
      color: theme.palette.text.primary,
      paddingLeft: "5px",
      width: "300px",
    };
  });

  const StyledDatePicker = styled(DatePicker)(({ theme }) => {
    return {
      "& .MuiDateCalendar-root": {
        color: theme.palette.primary.main,
        backgroundColor: "#BBDEFB",
      },
    };
  });

  return (
    <Box>
      <Box
        alignItems="center"
        aria-label="Billing Analytics Filters"
        display="flex"
        flexDirection={{ md: "row", sm: "row", xs: "column" }}
        flex="0 0 auto"
        justifyContent="space-between"
        mb={{
          md: "16px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            display="flex"
            alignItems={{ sm: "center", xs: "start" }}
            width="100%"
            flexDirection={{ sm: "row", xs: "column" }}
            justifyContent="space-between"
          >
            <StyledTypography> Overall usage </StyledTypography>
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingTop={{
                md: "0px",
                xs: "20px",
              }}
              paddingBottom={{
                md: "0px",
                xs: "30px",
              }}
            >
              <StyledLabel display="flex" alignItems="center">
                {" "}
                As of{" "}
              </StyledLabel>
              <StyledDatePicker
                data-testid="As of Date Picker"
                disableFuture
                label="Date"
                onChange={(value: any) => {
                  setAsOfDate(value);
                  getBillingAnalytics();
                }}
                value={asOfDate}
                maxDate={isCurrent ? getCurrentDate() : maxDate}
                minDate={minDate}
              />
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

export default AsOfDateFilter;
