import { ReactNode } from "react";
import { Box, SwipeableDrawer } from "@mui/material";

function MobileNavDrawer({
  actionButton,
  children,
  open,
  toggleDrawer,
}: {
  actionButton: ReactNode;
  children: ReactNode;
  open: boolean;
  toggleDrawer: () => void;
}) {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      sx={{ position: open ? "fixed" : "relative" }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: (theme) => {
              return theme.palette.customColors.overlay;
            },
          },
        },
      }}
    >
      <Box
        width="15rem"
        height="100%"
        sx={{
          backgroundColor: (theme) => {
            return theme.palette.grayscale[50];
          },
        }}
      >
        <Box margin="1rem">{actionButton}</Box>
        {children}
      </Box>
    </SwipeableDrawer>
  );
}

export { MobileNavDrawer };
