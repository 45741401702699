import PropTypes from "prop-types";
import get from "lodash/get";
import moment from "moment";

import { Field } from "formik";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Box from "@mui/material/Box";
import MomentUtils from "@date-io/moment";

import renderOptionalTooltip from "../utils/renderOptionalTooltip";
moment.suppressDeprecationWarnings = true;

function PersonalizationDatePicker({
  personalization,
  errors,
  setFieldTouched,
  setFormIsDirty,
  touched,
  values,
}) {
  const { description, title } = personalization;
  const handleChange = (date, form, field) => {
    if (!date) {
      form.setFieldValue(field.name, "");
    } else {
      form.setFieldValue(field.name, date.format("YYYY-MM-DD"));
    }
  };
  return (
    <Box my={4}>
      <Field name={`${title}.value`}>
        {({ field, form }) => {
          return (
            <Box
              display="flex"
              flex="0 0 auto"
              style={{ height: "4em", alignItems: "center" }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  clearable
                  error={
                    touched[title] &&
                    Boolean(get(errors, [title, "value"], undefined))
                  }
                  format="L"
                  helperText={
                    touched[title] && get(errors, [title, "value"], undefined)
                  }
                  inputVariant="outlined"
                  label={title}
                  margin="normal"
                  onBlur={() => {
                    setFieldTouched(title);
                    setFormIsDirty(form.dirty);
                  }}
                  onChange={(date) => {
                    setFieldTouched(title);
                    handleChange(date, form, field);
                  }}
                  placeholder="Enter a date"
                  style={{ width: "80%" }}
                  value={
                    get(values, [title, "value"])
                      ? get(values, [title, "value"])
                      : null
                  }
                />
              </MuiPickersUtilsProvider>
              <Box ml={2} style={{ alignItems: "center" }}>
                {description && renderOptionalTooltip(description, title)}
              </Box>
            </Box>
          );
        }}
      </Field>
    </Box>
  );
}

PersonalizationDatePicker.propTypes = {
  personalization: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalizationDatePicker;
