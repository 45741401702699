import ConversationImport from "../components/ConversationImport";
import ManageOptOuts from "../components/ManageOptOuts";
import { Account } from "models/Account";

import { SettingsSection } from "models/SettingsSection";

export const getContactSettings = ({
  account,
  isAdmin,
}: {
  account: Account;
  isAdmin: boolean;
}): SettingsSection | null => {
  const organizationAccount = account?.type === "organization";

  const settings = []
    .concat(
      isAdmin
        ? {
            // @ts-ignore
            title: "Manage Opt Outs",
            to: `/manage-opt-outs`,
            OptionComponent: ManageOptOuts,
          }
        : [],
    )
    .concat(
      account.phoneNumbers.length > 0 && !organizationAccount
        ? {
            // @ts-ignore
            title: "Conversation Import",
            to: `/conversation-import`,
            OptionComponent: ConversationImport,
          }
        : [],
    );

  return {
    title: "Contact Settings",
    path: "contact",
    settings,
  };
};
