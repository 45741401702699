import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import Recipients from "../../../sharedComponents/Recipients";
import RecipientsExclusionSection from "../../../sharedComponents/RecipientsExclusionSection";
import Groups from "../Groups";
import H3 from "components/H3";

import NewCampaignContactFilter from "features/Campaigns/containers/NewCampaignContactFilter";
import NewContactImport from "features/ContactImports/containers/NewContactImport";
import NewCampaignContactCollection from "features/Campaigns/containers/NewCampaignContactCollection";

import SelectGroupIcon from "icons/SelectGroupIcon";

export default function CampaignRecipients({
  activeStep,
  campaignType,
  contactCollectionId,
  contactFilterId,
  contactImport,
  currentAccount,
  dailyCampaignRecipients,
  exclusionTimeInput,
  getRecipientCount,
  recipients,
  selectGroup,
  setCampaignRecipients,
  setGroup,
  setExclusionTimeInput,
  setSelectGroup,
}) {
  const [fileUploaded, setFileUploaded] = useState(false);

  // TODO: Remove campaignsResponsiveness once Campaigns redesign work is live

  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  // TODO: Add loader when Recurring campaign is selected and we go directly to group selection

  useEffect(() => {
    if (
      campaignType === "recurring" &&
      activeStep === 1 &&
      campaignsResponsiveness
    ) {
      setSelectGroup(true);
    }
  }, [activeStep, campaignType, campaignsResponsiveness, setSelectGroup]);

  const theme = useTheme();

  const mobileView = useMediaQuery(() => {
    return theme.breakpoints.down("sm");
  });

  const responsiveCopy = {
    oneTime:
      "Upload a spreadsheet, select a group, or select contacts that you would like to receive your campaign. At a minimum the spreadsheet must contain a contact name and phone number.",
  };

  const stepSectionStyles = {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: "0",
    p: campaignsResponsiveness ? "0rem" : "1.5rem",
    width: "100%",
  };

  const recipientCount = getRecipientCount();
  const headerText = `Recipients ${recipientCount || "0"}`;
  const oneTimeCampaign = campaignType === "single";
  const error =
    recipientCount > dailyCampaignRecipients &&
    "Number of campaign recipients exceeds maximum daily limit for account";
  const selectRecipientsText = oneTimeCampaign
    ? campaignsResponsiveness
      ? responsiveCopy.oneTime
      : `Please upload a spreadsheet, select a group, or select contacts
    that you would like to receive your campaign. At a minimum the
    spreadsheet must contain a contact name and phone number.`
    : "Please select the group that you would like to receive your campaign.";

  const handleExclusionTimeChange = (timeInput) => {
    return setExclusionTimeInput(timeInput);
  };

  const optOutMessage = (
    <>
      <b>Please note:</b> When this Campaign is sent, a message will not attempt
      to send to any number that has since opted out.
    </>
  );

  if (selectGroup) {
    return (
      <Box {...stepSectionStyles}>
        {campaignsResponsiveness ? (
          <Typography
            align="center"
            alignSelf="stretch"
            data-testid="text"
            style={{ fontSize: "1.5rem" }}
            variant="h4"
            paddingTop={mobileView ? "2rem" : "0rem"}
            paddingLeft="1rem"
            paddingRight="1rem"
            paddingBottom="0rem"
          >
            Select group
          </Typography>
        ) : (
          <Typography
            align="center"
            component="h2"
            sx={{ fontSize: "2.5rem" }}
            variant="h2"
          >
            Select Group
          </Typography>
        )}
        <Box my={campaignsResponsiveness ? "0rem" : "2rem"}>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
            padding="1rem"
            paddingTop="0rem"
            paddingBottom={campaignsResponsiveness ? "2rem" : "0rem"}
          >
            Choose one group to receive the campaign.
          </Typography>
        </Box>
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
          campaignsResponsiveness={campaignsResponsiveness}
        />
        <Groups
          groupFilterCollectionId={
            currentAccount.groupFilters ||
            `/${currentAccount.slug}/group_filters`
          }
          currentAccount={currentAccount}
          setGroup={setGroup}
          setGroupRecipients={setCampaignRecipients}
          mobileView={mobileView}
        />
      </Box>
    );
  }
  if (recipients.length > 0) {
    return (
      <Box {...stepSectionStyles}>
        <H3>{headerText}</H3>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <Recipients recipients={recipients} />
      </Box>
    );
  }
  if (
    contactFilterId ||
    (contactImport &&
      contactImport.contactFilter &&
      contactImport?.state === "processed")
  ) {
    const filterId =
      typeof contactImport?.contactFilter === "string"
        ? contactImport?.contactFilter
        : contactImport?.contactFilter?.id;
    const campaignContactFilterId = contactFilterId || filterId;

    return (
      <Box {...stepSectionStyles}>
        <H3>{headerText}</H3>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <NewCampaignContactFilter
          contactFilterId={campaignContactFilterId}
          currentUrl={document.location.href}
        />
      </Box>
    );
  }
  if (contactCollectionId) {
    return (
      <Box {...stepSectionStyles}>
        <H3>{headerText}</H3>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <NewCampaignContactCollection
          contactCollectionId={contactCollectionId}
        />
      </Box>
    );
  }
  return (
    <Box {...stepSectionStyles}>
      <Typography
        align="center"
        component="h2"
        style={{ fontSize: campaignsResponsiveness ? "1.5rem" : "2.5rem" }}
        variant={campaignsResponsiveness ? "h4" : "h2"}
      >
        {campaignsResponsiveness ? "Select recipients" : "Select Recipients"}
      </Typography>
      <Box m={campaignsResponsiveness ? "0" : "1rem 0 2rem"}>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          style={{ fontSize: "1rem" }}
        >
          {selectRecipientsText}
        </Typography>
      </Box>

      {campaignsResponsiveness ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          paddingTop="0px"
          height="200px"
        >
          {oneTimeCampaign && (
            <Box
              flexGrow="1"
              justifyContent="center"
              alignItems="center"
              maxWidth="1000px"
            >
              <NewContactImport
                contactImport={contactImport}
                fromCampaign
                setFileUploaded={setFileUploaded}
              />
            </Box>
          )}
          {!contactImport && !fileUploaded && (
            <Box
              flexGrow="1"
              display="flex"
              justifyContent="flex-start"
              width="200px"
            >
              <Box justifyContent="center" alignSelf="center">
                <Box textAlign="center" sx={{ p: "6px" }}>
                  <SelectGroupIcon mode={theme.palette.mode} />
                </Box>
                <Button
                  color="primary"
                  onClick={() => {
                    return setSelectGroup(true);
                  }}
                  variant="contained"
                >
                  Select Group
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" alignSelf="center">
          <Box display="flex" maxWidth="1000px" padding="0px">
            <Box display="flex" alignSelf="center">
              <Box display="flex" maxWidth="1000px">
                <Box display="flex" width="100vw">
                  {oneTimeCampaign && (
                    <Box flexGrow="1" justifyContent="center" maxWidth="1000px">
                      <NewContactImport
                        contactImport={contactImport}
                        fromCampaign
                        setFileUploaded={setFileUploaded}
                      />
                    </Box>
                  )}
                  {!contactImport && !fileUploaded && (
                    <Box flexGrow="1" display="flex" justifyContent="center">
                      <Box justifyContent="center" alignSelf="center">
                        <Box m="1rem" textAlign="center">
                          <FontAwesomeIcon
                            icon={faUsers}
                            style={{ fontSize: "4.5rem" }}
                            color="rgba(0, 0, 0, .5)"
                          />
                        </Box>
                        <Button
                          color="primary"
                          onClick={() => {
                            return setSelectGroup(true);
                          }}
                          variant="contained"
                        >
                          Select Group
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

CampaignRecipients.propTypes = {
  activeStep: PropTypes.number.isRequired,
  campaignType: PropTypes.string.isRequired,
  contactCollectionId: PropTypes.string,
  contactFilterId: PropTypes.string,
  contactImport: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
  dailyCampaignRecipients: PropTypes.number.isRequired,
  exclusionTimeInput: PropTypes.number,
  getRecipientCount: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  selectGroup: PropTypes.bool.isRequired,
  setCampaignRecipients: PropTypes.func.isRequired,
  setExclusionTimeInput: PropTypes.func.isRequired,
  setGroup: PropTypes.func.isRequired,
  setSelectGroup: PropTypes.func.isRequired,
};
