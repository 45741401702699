import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import Avatar from "components/Avatar";

import withRecord from "higherOrderComponents/withRecord";
import Table from "components/Table";

function GroupCell(group) {
  return (
    <Box display="flex" alignItems="center" py="4px">
      <Tooltip
        title={get(group, ["user", "name"]) || get(group, ["account", "name"])}
        placement="bottom"
      >
        <Box flex="0 0 auto" mr={2}>
          <Avatar subject={get(group, ["user"]) || get(group, ["account"])} />
        </Box>
      </Tooltip>
      <Link
        component={RouterLink}
        to={(location) => {
          return `${location.pathname}/${group.slug}`;
        }}
      >
        {group.name}
      </Link>
    </Box>
  );
}

const getCollapsedColumns = () => {
  return [
    {
      title: "Group",
      getTableCellContent: GroupCell,
    },
  ];
};

const getColumns = () => {
  return [
    {
      title: "Group",
      getTableCellContent: GroupCell,
    },
    {
      title: "No. of Contacts",
      align: "right",
      getTableCellContent: (group) => {
        return get(group, ["contacts", "totalItems"]);
      },
    },
    {
      title: "Created At",
      align: "right",
      getTableCellContent: (group) => {
        return moment(get(group, ["createdAt"])).format("LLL");
      },
    },
  ];
};

function GroupCollection({ groupCollection, groupFilter }) {
  return (
    <Table
      collection={groupCollection}
      collapsedColumns={getCollapsedColumns()}
      columns={getColumns()}
      dataTag={`GroupCollection-${groupFilter.slug}`}
      notExpandable
    />
  );
}

GroupCollection.propTypes = {
  groupCollection: PropTypes.object.isRequired,
  groupFilter: PropTypes.object.isRequired,
};

export default withRecord({
  actions: ["fetch"],
  container: "features/Groups/GroupFilter/GroupCollection/groupCollection",
  shape: { members: [{ account: {}, user: {}, contacts: {} }] },
  type: "groupCollection",
})(GroupCollection);
