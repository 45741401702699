import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function SettingsPageContent({
  children,
  overflow = true,
  maxWidth = "720px",
  ...props
}) {
  return (
    <Box
      display="block"
      minHeight="calc(100% - 55px)"
      overflow={overflow ? "scroll" : "unset"}
    >
      <Box
        component="main"
        margin="0 auto"
        maxWidth={maxWidth}
        px={{ xs: 2, sm: 4 }}
        py={4}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
}

SettingsPageContent.propTypes = {
  children: PropTypes.any.isRequired,
  maxWidth: PropTypes.string,
  overflow: PropTypes.bool,
  theme: PropTypes.object,
};

export default SettingsPageContent;
