import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { compose } from "redux";
import moment from "moment";

import debounce from "lodash/debounce";
import get from "lodash/get";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import withRecord from "higherOrderComponents/withRecord";
import CollectionTable from "components/CollectionTable";

const columns = [
  {
    title: "Phone Number",
    align: "left",
    getTableCellContent: (optOut) => {
      return optOut.formattedPhoneNumber;
    },
  },
  {
    title: "Originating Account",
    align: "left",
    getTableCellContent: (optOut) => {
      return get(optOut, ["originatingAccount", "name"]);
    },
  },
  {
    title: "Created Via",
    align: "left",
    getTableCellContent: (optOut) => {
      return optOut.createdVia;
    },
  },
  {
    title: "Created By",
    align: "left",
    getTableCellContent: (optOut) => {
      return get(optOut, ["user", "name"]);
    },
  },
  {
    title: "Created At",
    align: "left",
    getTableCellContent: (optOut) => {
      return moment(optOut.createdAt).format("LL");
    },
  },
];

const collapsedColumns = [
  {
    title: "Phone Number",
    align: "left",
    getTableCellContent: (optOut) => {
      return optOut.formattedPhoneNumber;
    },
  },
  {
    title: "Created At",
    align: "left",
    getTableCellContent: (optOut) => {
      return moment(optOut.createdAt).format("LL");
    },
  },
];

function OptOutsCollection({
  setOptOutsCollectionId,
  history,
  optOutsCollection,
}) {
  const [page, setPage] = useState(0);
  const [previousCollectionIds, setPreviousCollectionIds] = useState([]);

  const handleChangePage = (event, nextPage) => {
    const mutablePreviousCollectionIds = [...previousCollectionIds];
    if (page < nextPage) {
      // Going forward
      setOptOutsCollectionId(`${optOutsCollection.view.next}`);
      mutablePreviousCollectionIds.push(optOutsCollection.id);
      setPreviousCollectionIds(mutablePreviousCollectionIds);
    } else {
      // Previous
      const prevCollection = mutablePreviousCollectionIds.pop();
      setOptOutsCollectionId(`${prevCollection}`);
      setPreviousCollectionIds(mutablePreviousCollectionIds);
    }
    setPage(nextPage);
  };

  const handleSearch = (q) => {
    const onlyNumbers = q.replace(/\D/g, "");
    if (onlyNumbers.length >= 3) {
      return setOptOutsCollectionId(
        `${optOutsCollection.view.first}?q=${onlyNumbers}`,
      );
    }
    if (!q || q.length === 0) {
      setOptOutsCollectionId(optOutsCollection.id.split("?")[0]);
    }
    return null;
  };

  const updateSearch = (event) => {
    debounceSearch(event.target.value);
  };

  const debounceSearch = debounce((value) => {
    handleSearch(value);
  }, 500);

  return (
    <Box display="flex" flex="1 1 auto" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box display="flex" width={{ xs: "100%", sm: "250px" }}>
          <TextField
            hiddenLabel
            fullWidth
            type="tel"
            size="small"
            onChange={updateSearch}
            placeholder="Type at least 3 numbers to search"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box color="text.secondary">
                    <SearchIcon />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box position="relative">
        <CollectionTable
          changePageHandler={handleChangePage}
          collection={optOutsCollection}
          collapsedColumns={collapsedColumns}
          columns={columns}
          history={history}
          label="Settings-Opt Out Table"
          page={page}
        />
      </Box>
    </Box>
  );
}

OptOutsCollection.propTypes = {
  setOptOutsCollectionId: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  optOutsCollection: PropTypes.object.isRequired,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountSettings/components/ManageOptOuts/optOuts",
    shape: { members: [{ originatingAccount: {}, user: {} }] },
    type: "optOutsCollection",
    showLoader: () => {
      return false;
    },
  }),
)(OptOutsCollection);
