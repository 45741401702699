import { compose } from "redux";
import { useReducer } from "react";
import PropTypes from "prop-types";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { Popover, Tooltip, Box, IconButton, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { withTheme } from "styled-components";

import withPopover from "higherOrderComponents/withPopover";

const getResponsiveCount = () => {
  return Math.floor((window.innerWidth - 60) / 36);
};
function EmojiOption(props) {
  const {
    anchorEl,
    closeHandler,
    openHandler,
    selectionHandler,
    theme,
    disabled,
  } = props;

  const muiTheme = useTheme();
  const mobileScreen = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const [, forceUpdate] = useReducer((x) => {
    return x + 1;
  }, 0);

  const handleForceUpdate = () => {
    forceUpdate();
  };

  const handleOpen = (event) => {
    openHandler(event);
    /* The categories size and anchors are memoized, so we need to force the
    picker to update after the tooltip has finished animating.
    https://github.com/missive/emoji-mart/issues/31#issuecomment-268819465
    This was previously achieved by calling this.forceUpdate(), but now we utilize useReducer as
    suggested in the React docs: https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate */

    setTimeout(() => {
      handleForceUpdate();
    }, 500);
  };

  const handleSelect = (emoji) => {
    selectionHandler(emoji);
    closeHandler();
  };

  const isSmallView = useMediaQuery("(max-width: 390px)");

  const emojiPerLine = mobileScreen && isSmallView ? getResponsiveCount() : 9;

  return (
    <>
      <Tooltip title="Emojis" disableFocusListener>
        <IconButton
          disabled={disabled === true}
          aria-haspopup="true"
          onClick={handleOpen}
          size="large"
        >
          <InsertEmoticonIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeHandler}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box
          sx={{
            borderRadius: "4px",

            "& .emoji-mart": {
              border: "none",
              borderRadius: "4px",
              width: "100%",
            },
          }}
        >
          <Picker
            color={theme.colors.accent.main}
            emojiTooltip
            native
            onSelect={handleSelect}
            perLine={emojiPerLine}
            showPreview={false}
            showSkinTones={false}
            title="Click an emoji to insert it into your message"
          />
        </Box>
      </Popover>
    </>
  );
}

export default compose(withTheme, withPopover)(EmojiOption);

EmojiOption.propTypes = {
  anchorEl: PropTypes.any,
  closeHandler: PropTypes.func.isRequired,
  openHandler: PropTypes.func.isRequired,
  selectionHandler: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
