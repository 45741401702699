// DEBT: This component could use major refactors.
// - Replace old MuiDatePicker with MUI X DatePicker
// - Refactor to TypeScript functional component
// - Replace moment.js with date-fns and/or native Date object

import { Component } from "react";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Box, Dialog, DialogContent } from "@mui/material";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import { Props } from "./types";
import { DropdownTimePicker } from "components/DropdownTimePicker";
import { DialogHeader } from "components/DialogHeader";
import { DialogWrapper } from "components/DialogWrapper";
import { DialogFooter } from "components/DialogFooter";

export class Scheduler extends Component<
  Props,
  {
    scheduledAt: moment.Moment;
    timeInput: { label: string; value: string };
  }
> {
  static propTypes = {
    closeScheduler: PropTypes.func.isRequired,
    currentScheduledAt: PropTypes.object,
    isActive: PropTypes.bool,
    sendLaterHandler: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);
    const scheduledAt = (() => {
      if (props.currentScheduledAt) return props.currentScheduledAt;
      const now = moment();
      const minutes = now.minutes();
      const roundedMinutes = Math.ceil(minutes / 15) * 15;
      return now.startOf("hour").minutes(roundedMinutes);
    })();
    const timeInputValue = scheduledAt.format("h:mm a");
    this.state = {
      scheduledAt,
      timeInput: { label: timeInputValue, value: timeInputValue },
    };
  }

  handleSelect = (option) => {
    if (!option) return this.setState({ timeInput: { label: "", value: "" } });
    const { scheduledAt } = this.state;
    const selectedTime =
      option.label === "Now"
        ? moment()
        : moment(
          `${scheduledAt.format("MM/DD/YYYY")} ${option.valueWithTimezone}`,
          "MM/DD/YYYY h:mm a",
        );
    return this.setState({
      scheduledAt: selectedTime,
      timeInput: option,
    });
  };

  handleDateChange = (scheduledAt) => {
    this.setState({ scheduledAt });
  };

  handleSubmit = () => {
    const { closeScheduler, sendLaterHandler } = this.props;
    const { scheduledAt } = this.state;
    sendLaterHandler(scheduledAt);
    closeScheduler();
  };

  render() {
    const { closeScheduler, isActive } = this.props;
    const { scheduledAt, timeInput } = this.state;
    const value = scheduledAt;

    return (
      <DialogWrapper>
        <Dialog open={isActive === true}>
          <DialogHeader onClose={closeScheduler} title="Send later" />

          <DialogContent>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box marginBottom="0.4rem" marginRight="1rem">
                  <DatePicker
                    disablePast
                    format="LL"
                    margin="normal"
                    minDate={moment()}
                    minDateMessage="error"
                    label="Date"
                    value={value}
                    onChange={this.handleDateChange}
                    inputVariant="outlined"
                    inputProps={{
                      style: {
                        height: "auto",
                      },
                    }}
                  />
                </Box>
              </MuiPickersUtilsProvider>
              <DropdownTimePicker
                handleSelect={this.handleSelect}
                value={timeInput}
                selectedDate={scheduledAt.toISOString()}
              />
            </Box>
          </DialogContent>

          <DialogFooter
            confirmText="Schedule"
            isConfirmDisabled={!timeInput || scheduledAt.format("h:mm a") === timeInput.value}
            onCancel={closeScheduler}
            onConfirm={this.handleSubmit}
          />
        </Dialog>
      </DialogWrapper>
    );
  }
}
