import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import moment from "moment";
import Button from "@mui/material/Button";

import MessageBubble from "./MessageBubble";
import MessageAttachment from "./MessageAttachment";
import { MessageDeleteConfirmModal } from "./MessageConfirmModal";
import getMomentWithTimezone from "utils/getMomentWithTimezone";
import Scheduler from "components/Scheduler";

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 75%;
`;

const TextButton = styled(Button)`
  min-width: 0 !important;
  text-transform: none !important;
`;

export const EditControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${(props) => {
    return props?.theme?.fonts?.tinyFontSize;
  }};
  padding: 4px;
  color: ${(props) => {
    return props?.theme?.colors?.text?.secondary;
  }};

  ${TextButton} {
    &:hover {
      color: ${(props) => {
        return props?.theme?.colors?.text?.primary;
      }};

      &.delete {
        color: ${(props) => {
          return props?.theme?.colors?.error?.main;
        }};
      }
    }

    &.scheduled-at {
      font-weight: 700;
    }
  }

  a {
    font-weight: 600;
    margin-right: 5px;
  }
`;

const EditButton = styled(TextButton)``;
const DeleteButton = styled(TextButton)``;
const TimestampButton = styled(TextButton)``;
const SaveButton = styled(TextButton)``;
const CancelButton = styled(TextButton)``;
const ScheduleModal = styled(Scheduler)``;

export default class MessageEditWrapper extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    shouldCollapse: PropTypes.bool,
    updateMessageRequest: PropTypes.func,
    deleteMessageRequest: PropTypes.func,
    showModal: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      body: props.message.body,
      isEditing: false,
      showDeleteConfirmation: false,
      showScheduleModal: false,
    };
  }

  getMessageAttachments = () => {
    return this.props.message.attachments.members.map(
      ({ key, size, contentType, height, width }) => {
        return {
          key,
          size,
          contentType,
          width,
          height,
        };
      },
    );
  };

  closeShowScheduleModal = () => {
    return this.setState({ showScheduleModal: false });
  };

  closeConfirmation = () => {
    return this.setState({ showDeleteConfirmation: false });
  };

  toggleEdit = () => {
    return this.setState((prevState) => {
      return { isEditing: !prevState.isEditing };
    });
  };

  openScheduleModal = () => {
    return this.setState({ showScheduleModal: true });
  };

  handleUpdateMessage = ({ scheduledAt = null } = {}) => {
    const { message } = this.props;
    const { body } = this.state;

    const updates = {
      body,
      ...(scheduledAt && { scheduledAt: scheduledAt.toISOString() }),
      ...(message.attachments && { attachments: this.getMessageAttachments() }),
    };

    this.props.updateMessageRequest(message.id, updates);

    this.setState({
      isEditing: false,
      body: body || this.props.message.body,
      showScheduleModal: false,
    });
  };

  showDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  updateBody = (body) => {
    return this.setState({ body });
  };

  renderEditControls = () => {
    const { message, currentUser } = this.props;
    const canEdit = get(message, ["sender", "id"]) === get(currentUser, "id");
    if (this.state.isEditing) {
      return (
        <EditControls>
          <SaveButton color="inherit" onClick={this.handleUpdateMessage}>
            Save
          </SaveButton>
          <CancelButton color="inherit" onClick={this.toggleEdit}>
            Cancel
          </CancelButton>
        </EditControls>
      );
    }

    return (
      <EditControls>
        {message.body && canEdit && (
          <EditButton color="inherit" onClick={this.toggleEdit}>
            Edit
          </EditButton>
        )}
        <DeleteButton
          color="inherit"
          className="delete"
          onClick={this.showDeleteConfirmation}
        >
          Delete
        </DeleteButton>

        <TimestampButton
          color="inherit"
          className="scheduled-at"
          onClick={this.openScheduleModal}
          disabled={!canEdit}
        >
          {getMomentWithTimezone(message.timelinePosition).format(
            "MM/DD/YY h:mm A z",
          )}
        </TimestampButton>
      </EditControls>
    );
  };

  render() {
    const { appSettings, message, shouldCollapse, showModal } = this.props;

    const { showDeleteConfirmation, isEditing, body } = this.state;

    return (
      <Root>
        <MessageDeleteConfirmModal
          message={message}
          show={showDeleteConfirmation}
          closeModal={this.closeConfirmation}
          confirm={(id) => {
            this.closeConfirmation();
            this.props.deleteMessageRequest(id);
          }}
        />
        <ScheduleModal
          closeScheduler={this.closeShowScheduleModal}
          currentScheduledAt={moment(message.timelinePosition)}
          isActive={this.state.showScheduleModal}
          sendLaterHandler={(scheduledAt) => {
            return this.handleUpdateMessage({
              scheduledAt,
            });
          }}
        />

        {message.attachments.members
          ? message.attachments.members.map((attachment) => {
              const isImage = attachment.contentType.startsWith("image");
              return (
                <MessageAttachment
                  appSettings={appSettings}
                  isImage={isImage}
                  attachment={attachment}
                  className="MessageAttachment"
                  noMessageBody={!message.body}
                  showModal={showModal}
                  key={attachment.key}
                />
              );
            })
          : null}
        {message.body && (
          <MessageBubble
            message={message}
            isEditing={isEditing}
            updateBody={this.updateBody}
            body={body}
            shouldCollapse={shouldCollapse}
          />
        )}

        {this.renderEditControls()}
      </Root>
    );
  }
}
