import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import MessageQuality from "./MessageQuality";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";

import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";

function MessageBodyVariants({
  currentAccount,
  currentUser,
  editCampaign,
  errors,
  handleAddVariant,
  handleDeleteVariant,
  SavedRepliesOption,
  setFieldValue,
  setShortenedLink,
  shortenedLink,
  touched,
  values,
}) {
  const { campaignMaxMessageLength } = currentAccount.settings;
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const { messageTemplates } = values ?? [];

  const setIsSignatureActive = (index) => {
    const newSignatureValue = !messageTemplates[index].signatureActive;
    setFieldValue(
      `messageTemplates[${index}].signatureActive`,
      newSignatureValue,
    );
  };

  const renderMessageTemplates = messageTemplates.map(
    ({ body, signatureActive, id }, index) => {
      if (values.messageTemplates[index].links) {
        setShortenedLink(values.messageTemplates[index].links);
      }
      return (
        <Box key={id}>
          {index > 0 && (
            <Box mb={4}>
              <Divider />
            </Box>
          )}
          <MessageInputFormRow
            attachmentField={values.messageTemplates[index].attachments}
            attachmentFieldName={`messageTemplates[${index}].attachments`}
            currentAccount={currentAccount}
            currentUser={currentUser}
            editCampaign={editCampaign}
            errors={errors}
            fieldName={`messageTemplates[${index}].body`}
            isCampaignProMember
            isDisabled={false}
            isSignatureActive={signatureActive}
            label="Message"
            linksFieldName={`messageTemplates[${index}].links`}
            maxChars={maxCharacterLength}
            SavedRepliesOption={SavedRepliesOption}
            setFieldValue={setFieldValue}
            setShortenedLink={setShortenedLink}
            shortenedLink={shortenedLink}
            signature={currentUser.signature}
            toggleSignature={() => {
              return setIsSignatureActive(index);
            }}
            touched={touched}
            values={values}
            withSignature
          />
          <AutomaticMessageIndicator />
          <MessageQuality message={body} />
          {messageTemplates.length > 1 && (
            <Box display="flex" justifyContent="space-around" mb={2}>
              <Button
                aria-label="Delete Message"
                onClick={() => {
                  return handleDeleteVariant({ id });
                }}
              >
                Delete Message
              </Button>
            </Box>
          )}
        </Box>
      );
    },
  );
  return (
    <Box>
      {renderMessageTemplates}
      {campaignsResponsiveness ? null : (
        <Box display="flex" flexDirection="column">
          <Button
            style={{ alignSelf: "center" }}
            onClick={() => {
              return handleAddVariant({
                messageTemplates,
                campaignTitle: values?.title,
              });
            }}
            aria-label="Add Message"
            disabled={messageTemplates.length > 2}
          >
            Add Message
          </Button>
        </Box>
      )}
    </Box>
  );
}

MessageBodyVariants.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  editCampaign: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  handleAddVariant: PropTypes.func.isRequired,
  handleDeleteVariant: PropTypes.func.isRequired,
  SavedRepliesOption: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  setShortenedLink: PropTypes.func,
  shortenedLink: PropTypes.object,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default MessageBodyVariants;
