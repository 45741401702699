import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { ConnectedRouter } from "connected-react-router/immutable";
import { OidcProvider } from "redux-oidc";
import { Provider as StoreProvider } from "react-redux";
import { userManager } from "@tesseract/core";

import Routes from "@tesseract/legacy/app/features/EntryPoint/containers/App/Routes";
import AuthCallback from "@tesseract/legacy/app/features/EntryPoint/containers/AuthCallback";
import Bootstrap from "@tesseract/legacy/app/features/EntryPoint/containers/Bootstrap/Loadable";
import LoginScreen from "@tesseract/legacy/app/features/Authentication";
import ResetPassword from "@tesseract/legacy/app/features/Authentication/containers/ResetPassword";
import { store } from "@tesseract/legacy/app/store";
import ErrorBoundary from "@tesseract/legacy/app/components/ErrorBoundary";
import history from "@tesseract/legacy/app/utils/history";
import { AppProvider } from "@tesseract/legacy/app/contexts/AppContext";
import { Themed } from "@tesseract/legacy/app/layout/Themed";
import Layout from "@tesseract/legacy/app/layout";

/**
 * Module augmentation to fix the missing children prop in OidcProviderProps
 */
declare module "redux-oidc" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface OidcProviderProps<TSTate> {
    children: React.ReactNode;
  }
}

const Root = styled.div`
  -webkit-font-smoothing: antialiased;
  color: ${(props: {
    theme: {
      colors: { text: { primary: string } };
    };
  }) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;

  top: env(safe-area-inset-top);
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
`;

export default function App() {
  return (
    <ErrorBoundary>
      <StoreProvider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <ConnectedRouter history={history}>
            <Themed>
              <AppProvider>
                <Root>
                  <Switch>
                    <Route
                      path="/auth/reset_password"
                      component={ResetPassword}
                    />
                    <Route path="/login" component={LoginScreen} />
                    <Route path="/callback" component={AuthCallback} />
                    <Route
                      render={(props) => {
                        return (
                          <Bootstrap {...props} bootstrapId="/boot">
                            {(bootstrapContext) => {
                              return (
                                <Layout
                                  bootstrapContainer={
                                    bootstrapContext.bootstrapContainer
                                  }
                                  currentAccountSiteNavigation={
                                    bootstrapContext.currentAccountSiteNavigation
                                  }
                                >
                                  <Routes {...bootstrapContext} />
                                </Layout>
                              );
                            }}
                          </Bootstrap>
                        );
                      }}
                    />
                  </Switch>
                </Root>
              </AppProvider>
            </Themed>
          </ConnectedRouter>
        </OidcProvider>
      </StoreProvider>
    </ErrorBoundary>
  );
}
