import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { compose } from "redux";
import { connect } from "react-redux";

import OptOutsCollection from "./OptOutsCollection";
import {
  selectCurrentUser,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";

import breakpoints from "utils/styles/breakpoints";
import getUserRole from "utils/getUserRole";
import OptOutAnalytics from "features/AccountSettings/containers/OptOutAnalytics";
import OptOutExport from "features/AccountSettings/containers/OptOutExport";
import OptOutImport from "features/AccountSettings/containers/OptOutImport";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";

import withSidebar from "higherOrderComponents/withSidebar";

const ButtonsWrapper = styled.div`
  align-items: center;
  display: none;
  justify-content: space-between;
  padding-right: 20px;

  > button:last-child {
    margin-left: 10px;
  }

  @media (min-width: ${breakpoints.medium}px) {
    display: flex;
  }
`;

function ManageOptOuts({
  currentAccount,
  currentUser,
  history,
  toggleSidebar,
}) {
  const [optOutsCollectionId, setOptOutsCollectionId] = useState(
    currentAccount.optOuts,
  );

  return (
    <SettingsPageWrapper>
      <PageHeader title="Manage Opt Outs" toggleSidebar={toggleSidebar}>
        {getUserRole(currentUser, currentAccount) === "admin" && (
          <ButtonsWrapper>
            <OptOutImport />
            <OptOutExport />
          </ButtonsWrapper>
        )}
      </PageHeader>
      <SettingsPageContent>
        <OptOutAnalytics optOutAnalyticsId={currentAccount.optOutAnalytics} />
        <OptOutsCollection
          history={history}
          optOutsCollectionId={optOutsCollectionId}
          setOptOutsCollectionId={setOptOutsCollectionId}
        />
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

ManageOptOuts.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withSidebar)(ManageOptOuts);
