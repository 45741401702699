import { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import getMomentWithTimezone from "utils/getMomentWithTimezone";

export default class CampaignCutoffInfo extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    getCutoffError: PropTypes.func,
  };

  getCampaignCutoffTime = () => {
    const { currentAccount } = this.props;
    const accountTimezone = currentAccount.settings.timeZone.value;
    const campaignCutoffTimeMinutes =
      currentAccount.settings.campaignDeliveryCutoffTime?.value;
    return getMomentWithTimezone(null, accountTimezone)
      .startOf("day")
      .minute(campaignCutoffTimeMinutes)
      .format("hh:mm:ss a z");
  };

  render() {
    const { currentAccount, getCutoffError } = this.props;
    const cutoffTimeEnabled =
      currentAccount.settings.campaignDeliveryCutoffTimeEnabled?.value;
    const textColor = getCutoffError && getCutoffError() ? "error" : "primary";
    if (!cutoffTimeEnabled) return null;
    return (
      <Typography color={textColor}>
        Campaign cutoff time: <b>{this.getCampaignCutoffTime()}</b>
      </Typography>
    );
  }
}
