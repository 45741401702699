/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";
import queryString from "query-string";

import { Fab, useMediaQuery } from "@mui/material";

import ContactBatchActions from "./ContactBatchActions";
import { contactBatchDeleteRequest } from "./api/contactBatchDeleteRequest";
import { contactBatchPutRequest } from "./api/contactBatchPutRequest";
import { ContactCollectionDataGrid } from "./ContactCollectionDataGrid";
import BasicButton from "components/BasicButton";
import BlankState from "components/BlankState";
import breakpoints from "utils/styles/breakpoints";
import ErrorComponent from "components/ErrorComponent";
import NoContactsFoundSvg from "utils/images/no-contacts-found.svg";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import {
  subscribeToContactCreated,
  subscribeToContactUpdated,
} from "features/Contact/events";
import { AddIcon } from "icons";

export const ContactButton = styled(BasicButton)`
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const MissingName = styled.span`
  color: ${(props) => {
    return props.theme.colors.text.disabled;
  }};

  &:hover {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

function Root({
  addNotification,
  contactCollection,
  contactCollectionId,
  contactFilter,
  currentAccount,
  currentUser,
  fetchContactCollectionRequest,
  fetchContactCollectionFailure,
  fetchContactCollectionSuccess,
  history,
  location,
  searchErrorState,
  setCompose,
  setContactModal,
  ...props
}) {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const prevContactCollection = useRef(contactCollection);

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const load = useCallback(() => {
    fetchContactCollectionRequest(contactCollectionId, null, {
      errorCallback: fetchContactCollectionFailure,
      successCallback: fetchContactCollectionSuccess,
    });
  }, [
    contactCollectionId,
    fetchContactCollectionFailure,
    fetchContactCollectionRequest,
    fetchContactCollectionSuccess,
  ]);

  /**
   * Subscribe to contact updated events
   *
   * Reload the contact collection when a contact is updated
   */
  useEffect(() => {
    return subscribeToContactUpdated(load);
  }, [load]);

  /**
   * Subscribe to contact created events
   *
   * Reload the contact collection when a contact is created
   */
  useEffect(() => {
    return subscribeToContactCreated(load);
  }, [load]);

  /**
   * Listen to search query changes
   */
  useEffect(() => {
    const { selected = false } = queryString.parse(location?.search);
    setAllSelected(!!selected);
  }, [location?.search]);

  useEffect(() => {
    if (allSelected && prevContactCollection.id !== contactCollection.id) {
      const visibleIds = get(contactCollection, ["members"], []).map(
        (recipient) => {
          return recipient.id;
        },
      );

      const updatedRecords = [
        ...new Set([...selectedRecords, ...contactCollection.members]),
      ];

      setSelectedRecords(updatedRecords);
      setSelectedContacts(union(selectedContacts, visibleIds));
    }
  }, [allSelected, contactCollection]);

  const updateSearch = ({ search, type, value }) => {
    const parsedSearch = queryString.parse(search);
    const decodedQ = parsedSearch.q ? parsedSearch.q : "";
    const addMatch = value.includes(" ")
      ? `${type}:"${value}"`
      : `${type}:${value}`;
    if (!decodedQ) {
      return `q=${fixedEncodeURIComponent(addMatch)}`;
    }
    return decodedQ.includes(addMatch)
      ? `q=${fixedEncodeURIComponent(decodedQ)}`
      : `q=${fixedEncodeURIComponent(`${decodedQ} ${addMatch}`)}`;
  };

  const handleClick = ({ type = "tags", value }) => {
    return () => {
      const { search } = location;
      const nextSearch = `?${updateSearch({ search, type, value })}`;
      history.push({
        pathname: `/${currentAccount.slug}/contacts/search`,
        search: nextSearch,
      });
    };
  };

  const clearBatchActionState = () => {
    setAllSelected(false);
    setSelectedContacts([]);
    setSelectedRecords([]);
  };

  const handleSetCompose = () => {
    const { members: contacts } = contactCollection;
    const recipientIds = selectedContacts
      .map((selectedId) => {
        const contact = contacts.find((c) => {
          return c.id === selectedId;
        });
        return get(contact, ["phones", "members", 0, "id"]);
      })
      .filter(Boolean);
    setCompose({ active: true, recipientIds });
  };

  const handleSetSelected = (selected) => {
    setAllSelected(false);
    setSelectedContacts(selected);
  };

  return searchErrorState ? (
    <ErrorComponent
      title="Sorry! Search is unavailable."
      subtitle="Try again later."
    />
  ) : isEmpty(contactCollection) || contactCollection.totalItems > 0 ? (
    <>
      {!isEmpty(contactCollection) && selectedContacts.length > 0 && (
        <ContactBatchActions
          addNotification={addNotification}
          allSelected={allSelected}
          clearBatchActionState={clearBatchActionState}
          contactCollection={contactCollection}
          contactFilter={contactFilter}
          currentAccount={currentAccount}
          currentUser={currentUser}
          handleSetCompose={handleSetCompose}
          history={history}
          isMobile={mobileScreen}
          location={location}
          selectedContacts={selectedContacts}
          selectedRecords={selectedRecords}
          setAllSelected={setAllSelected}
          setSelectedContacts={handleSetSelected}
          setSelectedRecords={setSelectedRecords}
          contactBatchPutRequest={contactBatchPutRequest}
          contactBatchDeleteRequest={contactBatchDeleteRequest}
          {...props}
        />
      )}
      <>
        <ContactCollectionDataGrid
          contactCollection={contactCollection}
          currentAccount={currentAccount}
          currentUser={currentUser}
          handleClick={handleClick}
          selectedContacts={selectedContacts}
          selectedRecords={selectedRecords}
          setContactModal={setContactModal}
          setSelectedContacts={handleSetSelected}
          setSelectedRecords={setSelectedRecords}
        />

        {mobileScreen && (
          <Fab
            aria-label="Add Contact"
            color="primary"
            onClick={() => {
              return setContactModal({ active: true, form: true });
            }}
            sx={{
              position: "absolute",
              bottom: "4rem",
              right: "2rem",
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </>
    </>
  ) : (
    <BlankState
      image={<img src={NoContactsFoundSvg} alt="No contacts found" />}
      subTitle="No contacts found."
    />
  );
}

Root.propTypes = {
  addNotification: PropTypes.func.isRequired,
  contactCollection: PropTypes.object.isRequired,
  contactCollectionId: PropTypes.string.isRequired,
  contactFilter: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchContactCollectionRequest: PropTypes.func.isRequired,
  fetchContactCollectionFailure: PropTypes.func.isRequired,
  fetchContactCollectionSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  searchErrorState: PropTypes.bool,
  setCompose: PropTypes.func.isRequired,
  setContactModal: PropTypes.func.isRequired,
};

export default Root;
