import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import Root from "./components/Root";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

class CampaignRecipientCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  setCompose: composeActionGenerators.setCompose,
});

export default compose(
  withRecord({
    actions: ["fetch", "subscribe", "unsubscribe", "block", "unblock"],
    container: "CampaignRecipientCollection",
    shape: { members: [{ contactPhone: { contact: {} } }] },
    showLoader: () => {
      return false;
    },
    type: "campaignRecipientCollection",
  }),
  withConnect,
)(CampaignRecipientCollection);
