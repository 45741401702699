import { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SignatureIcon from "icons/SignatureIcon";

export default class SignatureOption extends Component {
  static propTypes = {
    isSignatureActive: PropTypes.bool.isRequired,
    toggleSignature: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const { isSignatureActive, toggleSignature } = this.props;
    return (
      <Tooltip title="Signature">
        <IconButton
          disabled={this.props.disabled === true}
          onClick={toggleSignature}
          size="large"
        >
          <SignatureIcon color={isSignatureActive ? "secondary" : "inherit"} />
        </IconButton>
      </Tooltip>
    );
  }
}
