import { Component } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import styled from "styled-components";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneLockedIcon from "@mui/icons-material/PhoneLocked";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

const Wrapper = styled.div`
  padding-right: 40px;
  position: relative;
`;

const FilterWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -7px;
`;

export default class CampaignRecipientsFailures extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      nextLocation: null,
    };
  }

  handleClick = (event) => {
    return this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    return this.setState({ anchorEl: null });
  };

  handleSelect = (failureType) => {
    return () => {
      this.handleClose();
      this.setState({ nextLocation: failureType });
    };
  };

  render() {
    const { anchorEl, nextLocation } = this.state;
    const { location, match } = this.props;
    const filterSlug = match.params.campaignFilterSlug;
    return (
      <Wrapper>
        {"Failure "}
        {!filterSlug.includes("scheduled") && (
          <FilterWrapper>
            <IconButton
              onClick={this.handleClick}
              className="muiIconButton"
              aria-label="Filter"
              size="large"
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleSelect("spam")}>
                <ListItemIcon>
                  <DeleteForeverIcon color="action" />
                </ListItemIcon>
                <ListItemText>Spam</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.handleSelect("landline")}>
                <ListItemIcon>
                  <PhoneLockedIcon color="action" />
                </ListItemIcon>
                <ListItemText>Landline</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.handleSelect("other")}>
                <ListItemIcon>
                  <SmsFailedIcon color="action" />
                </ListItemIcon>
                <ListItemText>Other</ListItemText>
              </MenuItem>
            </Menu>
            {nextLocation && (
              <Redirect
                push
                to={{
                  pathname: `${location.pathname
                    .split("/")
                    .slice(0, 5)
                    .join("/")}/failed`,
                  search: `failure_type=${nextLocation}`,
                }}
              />
            )}
          </FilterWrapper>
        )}
      </Wrapper>
    );
  }
}
