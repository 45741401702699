import PropTypes from "prop-types";
import styled from "styled-components";
import ProfanityFilter from "bad-words";
import LinearProgress from "@mui/material/LinearProgress";

import H4 from "components/H4";

const profanityFilter = new ProfanityFilter();

const getColor = (score) => {
  if (score <= 100 * (2 / 6)) return "#D13232";
  if (score <= 100 * (4 / 6)) return "#F28322";
  if (score <= 100 * (5 / 6)) return "#F2BE1D";
  return "#8CC63F";
};

const MessageQualitySection = styled.section`
  min-height: 160px;
  padding-top: 20px;

  p {
    margin: 5px 0 10px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    margin-top: 10px;
    padding: 0;
  }

  .MuiLinearProgress-bar {
    background-color: ${(props) => {
      return getColor(props.score);
    }};
  }

  .MuiLinearProgress-root {
    height: 6px;
  }
`;

const messageChecks = [
  {
    checkMessage: (message) => {
      return message.length > 320;
    },
    warning: (
      <li key="long-message">
        <span role="img" aria-label="sleeping">
          😴
        </span>
        <span>
          {" "}
          Avoid long messages. Try to keep them under 320 characters.
        </span>
      </li>
    ),
  },
  {
    checkMessage: (message) => {
      return /[A-Z]{10,}/.test(message.replace(/\W/g, ""));
    },
    warning: (
      <li key="all-caps">
        <span role="img" aria-label="shushing-face">
          🤫
        </span>
        <span> Avoid using ALL CAPS</span>
      </li>
    ),
  },
  {
    checkMessage: (message) => {
      return /[0-9]{6,}/.test(message.replace(/\W/g, ""));
    },
    warning: (
      <li key="excessive-numbers">
        <span role="img" aria-label="nerdy-face">
          🤓
        </span>
        <span> Avoid using too many numbers in a row</span>
      </li>
    ),
  },
  {
    checkMessage: (message) => {
      return (message.match(/[~@#$%^&*]/gi) || []).length > 1;
    },
    warning: (
      <li key="special-characters">
        <span role="img" aria-label="money-mouth-face">
          🤑
        </span>
        <span> Avoid using too many special characters</span>
      </li>
    ),
  },
  {
    checkMessage: (message) => {
      return profanityFilter.isProfane(message);
    },
    warning: (
      <li key="profanity">
        <span role="img" aria-label="face-with-symbols-on-mouth">
          🤬
        </span>
        <span> Avoid using profanity</span>
      </li>
    ),
  },
];

const getWarnings = (message) => {
  const warnings = [];
  messageChecks.forEach(({ checkMessage, warning }) => {
    if (checkMessage(message)) {
      warnings.push(warning);
    }
  });
  return warnings;
};

function MessageQuality({ message = "" }) {
  const containsText = message.length > 0;
  const warnings = getWarnings(message);
  const score = containsText
    ? 100 - (100 / (messageChecks.length + 1)) * warnings.length
    : 0;
  return (
    <MessageQualitySection score={score}>
      <H4>Message Quality</H4>
      <p>
        TextUs has monitored deliverability of millions of outbound messages and
        is here to help ensure yours get through. As you type your message we’ll
        let you know what, if anything, could possibly impair delivery of your
        message.
      </p>
      <LinearProgress
        classes={{
          root: "MuiLinearProgress-root",
          bar: "MuiLinearProgress-bar",
        }}
        value={score}
        variant="determinate"
      />
      <ul>
        {containsText && warnings.length === 0 ? (
          <li key="good">
            <span role="img" aria-label="thumbs-up">
              👍
            </span>
            <span> Looks good!</span>
          </li>
        ) : (
          warnings.map((warning) => {
            return warning;
          })
        )}
      </ul>
    </MessageQualitySection>
  );
}

MessageQuality.propTypes = {
  message: PropTypes.string.isRequired,
};

export default MessageQuality;
