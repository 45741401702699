import queryString from "query-string";
import moment from "moment";

export default (
  account,
  startDate = moment().startOf("day").subtract(8, "days").toISOString(),
  endDate = moment().endOf("day").subtract(1, "days").toISOString(),
) => {
  const params = {
    startDate,
    endDate,
  };

  return `${account.analytics}?${queryString.stringify(params)}`;
};
