import { alpha, createTheme } from "@mui/material";
import type { PaletteMode } from "@mui/material";
import type { ThemeOptions } from "@mui/material/styles";
import { lighten, darken, transparentize } from "polished";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import merge from "lodash/merge";
import { Colors } from "./palettes";
import { TextUsColorOptions, textUsColors } from "./palettes/colors";

// Per TextUs Design System
// https://www.figma.com/file/sm14NQu6hT0RwVvF6TArfn/TextUs-Design-System

type CSSColor = React.CSSProperties["color"];

type CustomColors = {
  backgrounds: {
    secondary: CSSColor;
  };
  badgeText: CSSColor;
  enabledBorder: CSSColor;
  outboundMessageColor: CSSColor;
  star: CSSColor;
  svgFilter: CSSColor;
  overlay: CSSColor;
};

declare module "@mui/material/styles" {
  interface Palette extends TextUsColorOptions {
    customColors: CustomColors;
    selected: Palette["primary"];
    brand: Palette;
    messages: {
      automation: CSSColor;
      outbound: CSSColor;
      inbound: CSSColor;
    };
  }

  interface TypeText {
    hyperlink: string;
  }

  interface PaletteOptions extends Partial<TextUsColorOptions> {
    customColors?: CustomColors;
    selected?: PaletteOptions["primary"];
    brand?: {
      main: CSSColor;
      secondary: CSSColor;
    };
    messages?: {
      automation: CSSColor;
      outbound: CSSColor;
      inbound: CSSColor;
    };
  }

  interface TypographyVariants {
    body1Hyperlink: React.CSSProperties;
    body2Hyperlink: React.CSSProperties;
    dataSmall: React.CSSProperties;
    dataMedium: React.CSSProperties;
    dataLarge: React.CSSProperties;
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Hyperlink?: React.CSSProperties;
    body2Hyperlink?: React.CSSProperties;
    dataSmall: React.CSSProperties;
    dataMedium: React.CSSProperties;
    dataLarge: React.CSSProperties;
    label: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body1Hyperlink: true;
    body2Hyperlink: true;
    dataSmall: true;
    dataMedium: true;
    dataLarge: true;
    label: true;
  }
}

const getLightTheme = ({
  // Provided by BE during boot as `appColors` object
  primaryColor,
  secondaryColor,
  outboundMessageColor,
}: Record<Colors, string>): ThemeOptions => {
  const {
    amber,
    blue,
    blueGrey,
    deepOrange,
    grayscale,
    green,
    lightBlue,
    lightGreen,
    orange,
    purple,
    red,
  } = textUsColors;
  const contrastText = grayscale[50];

  return {
    palette: {
      mode: "light",
      primary: {
        main: primaryColor,
        light: lighten(0.1, primaryColor),
        dark: darken(0.1, primaryColor),
        contrastText,
      },
      secondary: {
        main: secondaryColor,
        light: lighten(0.1, secondaryColor),
        dark: darken(0.1, secondaryColor),
        contrastText,
      },
      error: { main: red[800], light: red[700], dark: red[900], contrastText },
      warning: {
        main: deepOrange[800],
        light: deepOrange[700],
        dark: deepOrange[900],
        contrastText,
      },
      info: {
        main: blue[800],
        light: blue[700],
        dark: blue[900],
        contrastText,
      },
      success: {
        main: green[800],
        light: green[700],
        dark: green[900],
        contrastText,
      },
      divider: grayscale.alphas.black.A12,
      customColors: {
        backgrounds: {
          secondary: grayscale[50],
        },
        badgeText: grayscale[0],
        enabledBorder: grayscale.alphas.black.A30,
        outboundMessageColor,
        star: amber[600],
        svgFilter: `invert(20%) sepia(50%) saturate(516%) hue-rotate(179deg)
        brightness(94%) contrast(94%)`,
        overlay: transparentize(0.3, primaryColor),
      },
      background: {
        default: "#fafafa",
        paper: lighten(0.02, "#fafafa"),
      },
      text: {
        primary: grayscale[900],
        secondary: grayscale[700],
        disabled: grayscale.alphas.black.A30,
        hyperlink: lightBlue[800],
      },
      action: {
        active: grayscale[700],
        hover: grayscale.alphas.black.A8,
        selected: grayscale.alphas.black.A12,
        focus: grayscale.alphas.black.A16,
        disabled: grayscale.alphas.black.A30,
        disabledBackground: grayscale.alphas.black.A12,
      },
      messages: {
        automation: purple[50],
        outbound: outboundMessageColor,
        inbound: grayscale[100],
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            color: blue[900],
            backgroundColor: blue[50],
          },
          standardError: {
            color: red[900],
            backgroundColor: red[50],
          },
          standardSuccess: {
            color: lightGreen[900],
            backgroundColor: lightGreen[50],
          },
          standardWarning: {
            color: deepOrange[900],
            backgroundColor: orange[50],
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fill: grayscale[600],
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          backdrop: {
            // Makes backdrop transparent by default on:
            // - Select
            // - Drawers
            // - Modals
            // - More?
            backgroundColor: "transparent",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            fill: blueGrey[600],
            color: contrastText,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            color: contrastText,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: grayscale[400],
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: primaryColor,
            color: contrastText,
            fontSize: "0.8rem",
          },
        },
      },
    },
  };
};

const getDarkTheme = ({
  outboundMessageColor,
}: Record<Colors, string>): ThemeOptions => {
  const {
    amber,
    blue,
    blueGrey,
    deepOrange,
    grayscale,
    green,
    lightBlue,
    lightGreen,
    orange,
    purple,
    red,
  } = textUsColors;
  const contrastText = grayscale[900];

  return {
    palette: {
      mode: "dark",
      primary: {
        main: lightBlue[300],
        light: lightBlue[500],
        dark: lightBlue[200],
        contrastText,
      },
      secondary: {
        main: lightBlue[100],
        light: lightBlue[200],
        dark: lightBlue[50],
        contrastText,
      },
      error: { main: red[200], light: red[100], dark: red[300], contrastText },
      warning: {
        main: deepOrange[100],
        light: deepOrange[200],
        dark: deepOrange[50],
        contrastText,
      },
      info: { main: blue[100], light: blue[200], dark: blue[50], contrastText },
      success: {
        main: green[100],
        light: green[200],
        dark: green[50],
        contrastText,
      },
      customColors: {
        backgrounds: {
          secondary: grayscale[900],
        },
        badgeText: grayscale[0],
        enabledBorder: grayscale.alphas.white.A30,
        star: amber[300],
        svgFilter: `invert(94%) sepia(6%) saturate(403%) hue-rotate(181deg) brightness(87%) contrast(84%)`,
        outboundMessageColor,
        overlay: transparentize(0.3, lightBlue[300]),
      },
      background: {
        default: "#0B1119",
        paper: lighten(0.02, "#0B1119"),
      },
      divider: grayscale.alphas.white.A12,
      text: {
        primary: grayscale[50],
        secondary: grayscale[200],
        disabled: grayscale.alphas.white.A30,
        hyperlink: lightBlue[100],
      },
      action: {
        active: grayscale[200],
        hover: grayscale.alphas.white.A8,
        selected: grayscale.alphas.white.A12,
        focus: grayscale.alphas.white.A16,
        disabled: grayscale.alphas.white.A30,
        disabledBackground: grayscale.alphas.white.A12,
      },
      messages: {
        automation: purple[900],
        outbound: outboundMessageColor,
        inbound: grayscale[800],
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            color: blue[50],
            backgroundColor: blue.A30,
          },
          standardError: {
            color: red[50],
            backgroundColor: red.A30,
          },
          standardSuccess: {
            color: lightGreen[50],
            backgroundColor: green.A30,
          },
          standardWarning: {
            color: orange[50],
            backgroundColor: deepOrange.A30,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fill: grayscale[300],
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          backdrop: {
            // Makes backdrop on <Select /> components transparent
            backgroundColor: "transparent",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            fill: blueGrey[100],
            color: contrastText,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: lightBlue[300],
            color: contrastText,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: grayscale[600],
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: blueGrey[50],
            color: contrastText,
            fontSize: "0.8rem",
          },
        },
      },
    },
  };
};

export type AppColors = Record<Colors, string> & { type: PaletteMode };

/**
 * Add to commonTheme whenever possible, especially when the value will be the same for both dark mode and
 * light mode. Only add directly to lightTheme or darkTheme above when the values will be unique.
 */
export default (
  { type, ...colors }: AppColors,
  themeOverrides?: ThemeOptions,
) => {
  const { blueGrey, lightBlue, grayscale } = textUsColors;

  const commonTheme: ThemeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 905,
        lg: 1240,
        xl: 1440,
      },
    },
    palette: {
      selected: {
        main: alpha(lightBlue[400], 0.7),
        light: alpha(lightBlue[400], 0.12),
      },
      common: {
        black: grayscale[1000],
        white: grayscale[0],
      },
      ...textUsColors,
    },
    typography: {
      fontFamily: `"Helvetica Neue", Helvetica, Arial, sans-serif`,
      h1: {
        fontSize: "3rem",
        fontWeight: 700,
        lineHeight: "116.7%",
        letterSpacing: "0.15px",
      },
      h2: {
        fontSize: "2.5rem",
        fontWeight: 400,
        lineHeight: "120%",
        letterSpacing: "-0.5px",
      },
      h3: {
        fontSize: "2rem",
        fontWeight: 500,
        lineHeight: "124%",
        letterSpacing: "0.15px",
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: "134%",
        letterSpacing: "0.25px",
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 500,
        lineHeight: "138%",
        letterSpacing: "0.2px",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "178%",
        letterSpacing: "0.15px",
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "175%",
        letterSpacing: "0.15px",
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.1px",
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.15px",
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "168%",
        letterSpacing: "0.17px",
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 600,
        lineHeight: "199%",
        letterSpacing: "1px",
      },
      body1Hyperlink: {
        fontSize: "1rem",
        fontWeight: 700,
        lineHeight: "150%",
        letterSpacing: "0.15px",
      },
      body2Hyperlink: {
        fontSize: "0.875rem",
        fontWeight: 700,
        lineHeight: "168%",
        letterSpacing: "0.17px",
      },
      dataSmall: {
        fontSize: "1.5rem",
        fontWeight: 400,
        lineHeight: "134%",
        letterSpacing: "0.15px",
      },
      dataMedium: {
        fontSize: "2rem",
        fontWeight: 400,
        lineHeight: "124%",
        letterSpacing: "0.15px",
      },
      dataLarge: {
        fontSize: "3rem",
        fontWeight: 400,
        lineHeight: "116.7%",
        letterSpacing: "0.15px",
      },
      label: {
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem",
            lineHeight: 1.5,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: blueGrey.A80,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: { margin: "0.25rem" },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            borderRight: 0,
            borderLeft: 0,
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
          },
          sortIcon: {
            fontSize: "medium",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 8,
          },
          sizeSmall: {
            fontSize: "2.25rem", // 36dp
          },
          sizeMedium: {
            fontSize: "2.5rem", // 40dp
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            marginRight: "0.5rem",
            minWidth: "auto",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            padding: "0 0.5rem",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            maxWidth: "18.75rem",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: { backgroundImage: "unset" },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            padding: "1.5rem",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: { fontSize: "1.25rem" },
          fontSizeMedium: { fontSize: "1.5rem" },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: "wave",
        },
      },
    },
  };

  return createTheme(
    merge(
      commonTheme,
      (type === "dark" ? getDarkTheme : getLightTheme)(colors),
      themeOverrides,
    ),
  );
};
