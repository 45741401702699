import { FormikErrors, FormikTouched, FormikValues, useField } from "formik";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import {
  SetFieldTouchedFn,
  SetFieldValueFn,
} from "features/Sequences/components/AddStepModal/types";
import { AbsoluteTimeSelection } from "components/DropdownTimePicker/types";
import { useTimeZones } from "hooks";

const useAbsoluteTimePicker = (
  values: FormikValues,
  setFieldValue: SetFieldValueFn,
  setFieldTouched: SetFieldTouchedFn,
) => {
  const { accountTimeZone, ABSOLUTE_TIME_FORMAT } = useTimeZones();

  const [dateField] = useField("date");
  const [timeField] = useField("time");

  const handleSelectDate = (newDate: Date | null) => {
    if (newDate) {
      const convertedDate = zonedTimeToUtc(newDate, accountTimeZone);

      setFieldValue("date", convertedDate);
      setFieldTouched("date");

      setFieldValue(
        "absoluteTime",
        new Date(
          Date.parse(
            `${formatInTimeZone(
              convertedDate,
              accountTimeZone,
              ABSOLUTE_TIME_FORMAT,
            )} ${
              values.time
                ? values.time.valueWithTimezone
                : formatInTimeZone(
                    new Date(values.absoluteTime),
                    accountTimeZone,
                    "h:mm a z",
                  )
            }`,
          ),
        ).toISOString(),
      );
    }
  };

  const handleSelectTime = (newTime: AbsoluteTimeSelection | null) => {
    if (newTime) {
      setFieldValue("time", {
        valueWithTimezone: newTime.valueWithTimezone,
        label: newTime.label,
      });

      if (values.date) {
        setFieldTouched("date");
        setFieldValue(
          "absoluteTime",
          new Date(
            Date.parse(
              `${formatInTimeZone(
                values.date,
                accountTimeZone,
                ABSOLUTE_TIME_FORMAT,
              )} ${newTime.valueWithTimezone}`,
            ),
          ).toISOString(),
        );
      }
    }
  };

  // Checks if selected Time field is before current account time.
  const hasTimeError = () => {
    if (values.date && values.time) {
      const now = Date.now();

      const formattedNow = formatInTimeZone(
        now,
        accountTimeZone,
        ABSOLUTE_TIME_FORMAT,
      );

      const parsedNow = Date.parse(
        formatInTimeZone(now, accountTimeZone, ABSOLUTE_TIME_FORMAT),
      );

      const formattedTimeZone = formatInTimeZone(now, accountTimeZone, "zzz");

      const selection = Date.parse(
        `${formattedNow} ${
          values.time?.valueWithTimezone ?? `12:00 AM ${formattedTimeZone}`
        }`,
      );

      const isToday =
        formatInTimeZone(
          values.date ?? now,
          accountTimeZone,
          ABSOLUTE_TIME_FORMAT,
        ) === formattedNow;

      return isToday && selection < parsedNow;
    }

    return false;
  };

  return {
    dateField,
    timeField,
    handleSelectDate,
    handleSelectTime,
  };
};

export { useAbsoluteTimePicker };
