import { useState } from "react";
import PropTypes from "prop-types";

import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Modal from "components/Modal";
import PageLoader from "components/PageLoader";
import SavedReplies from "features/SavedReplies";
import { useCurrentAccount } from "hooks";

function SavedRepliesOption({ quickComposer, selectionHandler, disabled }) {
  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [activeSavedReply, setActiveSavedReply] = useState(undefined);

  const openHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = (event) => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    return setShowEditorModal(false);
  };

  const handleEditButtonClick = () => {
    closeHandler();
    setShowEditorModal(true);
  };

  const handleSelect = (value) => {
    selectionHandler(value);
    closeHandler();
  };

  const shouldShowModal = () => {
    return !(quickComposer || mobile);
  };

  const renderSavedReplies = (props) => {
    return (
      <SavedReplies
        closeHandler={closeHandler}
        handleCloseModal={handleCloseModal}
        handleEditButtonClick={handleEditButtonClick}
        handleSavedReplySelected={handleSelect}
        hasManageOptions={shouldShowModal()}
        quickComposer={quickComposer}
        activeSavedReply={activeSavedReply}
        setActiveSavedReply={setActiveSavedReply}
        {...props} // showManager
      />
    );
  };

  return (
    <>
      <Tooltip title="Templates" disableFocusListener>
        <IconButton
          disabled={disabled === true}
          type="button"
          aria-haspopup="true"
          onClick={openHandler}
          size="large"
        >
          <BookOutlinedIcon />
        </IconButton>
      </Tooltip>
      {shouldShowModal() && (
        <Modal transitionIn={showEditorModal}>
          {renderSavedReplies({ showManager: true })}
        </Modal>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        anchorReference={mobile ? "none" : "anchorEl"}
        onClose={closeHandler}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        slotProps={{
          paper: {
            square: mobile,
            sx: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  height: "100%",
                  marginTop: "3.5rem",
                  maxHeight: "unset",
                  maxWidth: "unset",
                  position: "relative",
                  width: "100%",
                  // I hate using !important but it's the only way I could find
                  // to override MUI's default Popover calculations.
                  top: "unset !important",
                  left: "unset !important",
                },
              };
            },
          },
        }}
      >
        <Box
          borderRadius="0.25rem"
          height={mobile ? "100%" : "25rem"}
          width={mobile ? "100%" : "25rem"}
        >
          {anchorEl ? renderSavedReplies() : <PageLoader />}
        </Box>
      </Popover>
    </>
  );
}

SavedRepliesOption.propTypes = {
  quickComposer: PropTypes.bool, // passed
  selectionHandler: PropTypes.func.isRequired, // passed
  disabled: PropTypes.bool, // passed
};

export default SavedRepliesOption;
