import { useEffect, useState } from "react";
import { compose } from "redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  Box,
  Button,
  Divider,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import {
  isAfter,
  isSameDay,
  startOfDay,
  roundToNearestMinutes,
  addMinutes,
  format,
} from "date-fns";
import CampaignForm from "../../sharedComponents/CampaignForm";
import CampaignProForm from "../../sharedComponents/CampaignProForm";
import {
  getMultipleVariantCampaignParams,
  getSingleCampaignParams,
  getRecurringCampaignParams,
} from "../../utils";
import { fetchOverages } from "../../../../api";
import { getFormattedPayload } from "../../utils/getFormattedPayload";
import CampaignRecipients from "./CampaignRecipients/CampaignRecipients";
import CampaignPreview from "./CampaignPreview";
import CampaignType from "./CampaignType";
import OverageDialog from "./OverageDialog";
import { Dialog, useDialog } from "components/Dialog";
import getUserRole from "utils/getUserRole";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import withRecord from "higherOrderComponents/withRecord";

import createUUID from "utils/uuid";
import { useCurrentAccount, useTimeZones } from "hooks";

// TODO: Remove campaignsResponsiveness flag and remove title case array

const getSteps = (campaignsResponsivenessFlag) => {
  return campaignsResponsivenessFlag
    ? [
        "Campaign type",
        "Select recipients",
        "Create campaign",
        "Review and send",
      ]
    : [
        "Campaign Type",
        "Select Recipients",
        "Create Campaign",
        "Review and Send",
      ];
};

function NewCampaign({
  contactCollection,
  contactCollectionId,
  contactFilter,
  contactFilterId,
  contactImport,
  createCampaignRequest,
  currentAccount,
  currentUser,
  deliveryStats,
  fetchGroupRequest,
  location,
  newCampaignContainer,
  recipients,
  scheduleCampaignRequest,
  toggleSidebar,
  initialActiveStep = 0,
}) {
  const { abbreviatedAccountTimeZone, accountTimeZone, currentTime } =
    useTimeZones();

  const signature = currentUser?.signature?.content || "";
  const { step, type = "single" } = queryString.parse(location?.search);
  const skipCampaignType = recipients.length > 0 || step === "2";
  const [autoAssign, setAutoAssign] = useState(
    !!getUserRole(currentUser, currentAccount),
  );
  const { isCampaignProMember } = useCurrentAccount();

  // TODO: Remove campaignsResponsiveness flag, unused ternaries, campaignsResponsiveness prop

  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  const [activeStep, setActiveStep] = useState(
    initialActiveStep || skipCampaignType ? 1 : 0,
  );
  const [campaignRecipients, setCampaignRecipients] = useState(recipients);
  const [campaignType, setCampaignType] = useState(type);
  const [exclusionTimeInput, setExclusionTimeInput] = useState(null);
  const [{ groupName, groupId }, setGroup] = useState({});
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });
  const [isSignatureActive, setIsSignatureActive] = useState(
    !!signature && signature.length > 0,
  );
  const [scheduledAt, setScheduledAt] = useState(currentTime);
  const [selectGroup, setSelectGroup] = useState(
    campaignType === "recurring" && activeStep === 1 && campaignsResponsiveness,
  );
  const [campaignValues, setCampaignValues] = useState({
    campaignEndDate: null,
    dayToSend: "",
    messageBody: "",
    messageTemplates: [
      {
        attachments: [],
        body: "",
        links: { fullLink: "", shortLink: shortenedLink.shortLink },
        signatureActive: isSignatureActive,
        id: createUUID(),
      },
    ],
    attachments: [],
    runsIndefinitely: false,
    sendFrequency: "",
    sendTime: "",
    title: "",
  });
  const [scheduleError, setScheduleError] = useState(false);
  const [remainingRecipients, setRemainingRecipients] = useState(undefined);

  const { ref, open: openDialog } = useDialog();

  const { enqueueSnackbar } = useSnackbar();

  const mobileView = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const smView = useMediaQuery((theme) => {
    return theme.breakpoints.only("sm");
  });

  const {
    attachments,
    campaignEndDate,
    dayToSend,
    messageBody,
    messageTemplates,
    runsIndefinitely,
    sendFrequency,
    sendTime,
    title,
  } = campaignValues;

  const handleDeleteVariant = ({ id }) => {
    const updatedMessageTemplates = messageTemplates.filter((template) => {
      return template.id !== id;
    });
    setCampaignValues({
      ...campaignValues,
      messageTemplates: updatedMessageTemplates,
    });
  };

  const handleAddVariant = ({ messageTemplates: templates, campaignTitle }) => {
    const { shortLink } = shortenedLink;
    const updatedMessageTemplates = [
      ...templates,
      {
        attachments: [],
        body: "",
        links: { fullLink: "", shortLink },
        signatureActive: isSignatureActive,
        id: createUUID(),
      },
    ];
    setCampaignValues({
      ...campaignValues,
      title: campaignTitle,
      messageTemplates: updatedMessageTemplates,
    });
  };

  useEffect(() => {
    if (contactCollectionId) {
      const [group] = contactCollectionId.split("/contacts");
      // DEBT: This is the withRecord call from the bottom of this file
      fetchGroupRequest(group, null, {
        successCallback: ({ name, id }) => {
          setGroup({ groupName: name, groupId: id });
        },
      });
    }
  }, [contactCollectionId, fetchGroupRequest]);

  const getParams = () => {
    const sharedParams = {
      attachments,
      autoAssign,
      messageBody,
      type: campaignType,
      title,
      signatureContent: signature,
      isSignatureActive,
      shortenedLink,
    };
    if (messageTemplates?.length) {
      return getMultipleVariantCampaignParams({
        ...sharedParams,
        campaignContactFilter: contactFilter,
        campaignRecipients,
        campaignScheduledAt: scheduledAt,
        contactCollection: contactCollection?.id,
        contactImport,
        exclusionTime: exclusionTimeInput,
        groupId,
        messageTemplates,
      });
    }
    if (campaignType === "single") {
      return getSingleCampaignParams({
        ...sharedParams,
        campaignContactFilter: contactFilter,
        campaignRecipients,
        campaignScheduledAt: scheduledAt,
        contactCollection: contactCollection?.id,
        contactImport,
        exclusionTime: exclusionTimeInput,
        groupId,
      });
    }
    return getRecurringCampaignParams({
      ...sharedParams,
      campaignEndDate,
      dayToSend,
      groupId,
      runsIndefinitely,
      sendFrequency,
      sendTime,
    });
  };

  const getOverages = async () => {
    try {
      const params = getParams();

      const formattedPayload = getFormattedPayload({
        params,
        slug: currentAccount.slug,
        contactImport,
        scheduledAt,
        campaignType,
      });

      const response = await fetchOverages(formattedPayload);
      const body = await response.json();
      return body;
    } catch (error) {
      enqueueSnackbar(`Error creating campaign: ${String(error)}`, {
        variant: "error",
      });
      return error;
    }
  };

  const steps = getSteps(campaignsResponsiveness);

  const { errorCreating, errorScheduling, isCreating, isScheduling } =
    newCampaignContainer?.substate ?? {};

  const isSending = isCreating || isScheduling;

  const dailyCampaignRecipients =
    currentAccount?.settings?.dailyCampaignRecipients?.value;

  const isLastPage = activeStep === steps.length - 1;

  const getRecipientCount = () => {
    if (!isEmpty(contactCollection)) return contactCollection?.totalItems;
    if (!isEmpty(contactFilter)) return contactFilter?.contacts?.totalItems;
    if (!isEmpty(contactImport))
      return contactImport?.contactFilter?.contacts?.totalItems;
    if (typeof campaignRecipients === "number") return campaignRecipients;
    if (campaignRecipients.length > 0) return campaignRecipients.length;
    if (selectGroup && campaignRecipients.length > 0)
      return campaignRecipients.length;
    return 0;
  };

  const getLimitError = () => {
    if (!isSameDay(currentTime, scheduledAt)) return undefined;
    const recipientCount = getRecipientCount();
    const { campaignMessagesUsed = 0, campaignMessagesScheduled = 0 } =
      deliveryStats;
    const sentAndScheduledCount =
      campaignMessagesUsed + campaignMessagesScheduled;

    return recipientCount + sentAndScheduledCount > dailyCampaignRecipients
      ? "This campaign will push you over your daily limit. Please select a different date."
      : undefined;
  };

  const getCutoffError = () => {
    const cutoffTimeEnabled =
      currentAccount.settings.campaignDeliveryCutoffTimeEnabled?.value;
    const campaignCutoffTimeMinutes =
      currentAccount?.settings?.campaignDeliveryCutoffTime?.value;

    if (!cutoffTimeEnabled || !campaignCutoffTimeMinutes) return false;
    const campaignDeliveryCutoffTime = addMinutes(
      startOfDay(scheduledAt),
      campaignCutoffTimeMinutes,
    );
    return isAfter(scheduledAt, campaignDeliveryCutoffTime);
  };

  // Do not allow user to add more variants than the number of recipients
  const additionalRecipientsRequired =
    activeStep === 2 &&
    messageTemplates.length >= getRecipientCount() &&
    getRecipientCount() < 3;

  const getIsNextDisabled = () => {
    if (activeStep === 1) {
      // processing imported contacts
      const contactImportState = contactImport?.state;
      const isProcessingContactImport =
        contactImportState && contactImportState !== "processed";
      // exceeds daily campaign allowance
      const recipientCount = getRecipientCount();
      const exceedsDailyAllowance = recipientCount > dailyCampaignRecipients;
      return (
        isProcessingContactImport || !recipientCount || exceedsDailyAllowance
      );
    }
    if (isLastPage) {
      return !!getLimitError() || getCutoffError() || scheduleError;
    }
    return false;
  };

  const getRemainingRecipients = (accountDailyLimit, overages) => {
    const remaining = accountDailyLimit - overages[0].beforeRecipientsTotal;
    setRemainingRecipients(remaining);
    return remaining;
  };

  const campaignTypeText =
    campaignType === "single" ? "One-time campaign" : "Recurring campaign";

  const headerText = activeStep >= 1 ? campaignTypeText : "New campaign";

  const handleCreate = async () => {
    const params = getParams();

    // FEATURE FLAG: Delete when overages dialog goes live
    if (currentAccount.featureFlags?.campaignDailyOverages) {
      const campaignOverages = await getOverages(params.campaignRecipients);
      const { accountDailyLimit, overages } = campaignOverages;

      if (overages.length > 0) {
        getRemainingRecipients(accountDailyLimit, overages);

        return openDialog({}, (values) => {
          return values;
        })();
      }
    }
    if (isSending) return null;
    if (scheduledAt && isAfter(scheduledAt, new Date())) {
      return scheduleCampaignRequest(currentAccount.campaigns, params);
    }
    return createCampaignRequest(currentAccount.campaigns, params);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleCreate().catch((error) => {
        return console.error(error);
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (selectGroup && activeStep <= 2) {
      setSelectGroup(false);
      setCampaignRecipients(recipients);
    }
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = (values) => {
    if (values?.sendTime) {
      const normalizedSendTime =
        campaignType === "single"
          ? `${formatInTimeZone(values.sendTime, "h:mm a", accountTimeZone)} ${abbreviatedAccountTimeZone}`
          : `${format(values.sendTime, "h:mm a")} ${abbreviatedAccountTimeZone}`;
      setCampaignValues({ ...values, sendTime: normalizedSendTime });
    } else {
      setCampaignValues(values);
    }
    handleNext();
  };

  const parseErrors = () => {
    if (!errorCreating && !errorScheduling) return undefined;
    const validationErrors =
      errorScheduling?.response?.validationErrors?.schedulingValidationErrors;
    if (errorCreating) {
      return (
        errorCreating?.response?.detail ??
        "Something went wrong! Please check your selections and try again"
      );
    }
    if (!validationErrors)
      return "Something went wrong! Please select another date";
    return get(validationErrors, [Object.keys(validationErrors), "0"]);
  };

  const campaignTypeForm = (
    <CampaignType
      campaignType={campaignType}
      recurringDisabled={contactFilterId?.includes("failed")}
      setCampaignType={setCampaignType}
      campaignsResponsiveness={campaignsResponsiveness}
    />
  );

  const recipientsForm = (
    <CampaignRecipients
      activeStep={activeStep}
      campaignType={campaignType}
      contactCollectionId={contactCollectionId}
      contactFilterId={contactFilterId}
      contactImport={contactImport}
      currentAccount={currentAccount}
      dailyCampaignRecipients={dailyCampaignRecipients}
      exclusionTimeInput={exclusionTimeInput}
      getRecipientCount={getRecipientCount}
      recipients={campaignRecipients}
      selectGroup={selectGroup}
      setGroup={setGroup}
      setCampaignRecipients={setCampaignRecipients}
      setExclusionTimeInput={setExclusionTimeInput}
      setSelectGroup={setSelectGroup}
    />
  );

  const campaignForm =
    isCampaignProMember && campaignType === "single" ? (
      <CampaignProForm
        attachments={attachments}
        currentAccount={currentAccount}
        currentUser={currentUser}
        disableNextButton={
          campaignsResponsiveness &&
          getRecipientCount() < messageTemplates.length
        }
        editCampaign={false}
        handleAddVariant={handleAddVariant}
        handleDeleteVariant={handleDeleteVariant}
        handleSubmit={handleSubmit}
        messageTemplates={messageTemplates}
        title={title}
        onFormChange={campaignsResponsiveness && setCampaignValues}
        shortenedLink={shortenedLink}
        setShortenedLink={setShortenedLink}
      />
    ) : (
      <CampaignForm
        campaignType={campaignType}
        currentAccount={currentAccount}
        currentUser={currentUser}
        groupName={groupName}
        handleSubmit={handleSubmit}
        isSignatureActive={isSignatureActive}
        shortenedLink={shortenedLink}
        setShortenedLink={setShortenedLink}
        messageBody={messageBody}
        setIsSignatureActive={setIsSignatureActive}
        timeZone={accountTimeZone}
        title={title}
      />
    );

  const campaignPreview = (
    <CampaignPreview
      attachments={attachments}
      autoAssign={autoAssign}
      campaignType={campaignType}
      campaignValues={campaignValues}
      currentAccount={currentAccount}
      currentUser={currentUser}
      dailyCampaignRecipients={dailyCampaignRecipients}
      getCutoffError={getCutoffError}
      getLimitError={getLimitError}
      getRecipientCount={getRecipientCount}
      groupName={groupName}
      isSignatureActive={isSignatureActive}
      messageBody={messageBody}
      messageTemplates={messageTemplates}
      parseErrors={parseErrors}
      scheduledAt={scheduledAt}
      setAutoAssign={setAutoAssign}
      setScheduledAt={setScheduledAt}
      setScheduleError={setScheduleError}
      scheduleError={scheduleError}
    />
  );

  const getStepContent = (stepNumber) => {
    switch (stepNumber) {
      case 0:
        return campaignTypeForm;
      case 1:
        return recipientsForm;
      case 2:
        return campaignForm;
      case 3:
        return campaignPreview;
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      color="text.primary"
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
    >
      <PageHeader
        title={campaignsResponsiveness ? headerText : "New Campaign"}
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        minHeight="0"
        overflow={campaignsResponsiveness ? "auto" : "scroll"}
        sx={{ overflowX: "hidden" }}
        p="0"
        width="100%"
      >
        {mobileView && campaignsResponsiveness ? null : (
          <Stepper
            activeStep={activeStep}
            alternativeLabel={smView && campaignsResponsiveness}
            style={{
              margin: "0 auto",
              maxWidth: campaignsResponsiveness ? "45rem" : "62.5rem",
              width: "100%",
              paddingTop: campaignsResponsiveness ? "2rem" : "1.5rem",
              paddingBottom: campaignsResponsiveness ? "2rem" : "1.5rem",
            }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {activeStep === steps.length ? (
          <div>Success & Redirect</div>
        ) : (
          <>
            {!campaignsResponsiveness && (
              <Box
                display="flex"
                flex="1 1 auto"
                flexDirection="column"
                margin="0 auto"
                maxWidth="800px"
                width="100%"
              >
                {getStepContent(activeStep)}
                <Box
                  alignItems="center"
                  display="flex"
                  flex="0 0 auto"
                  justifyContent="flex-end"
                  p="2.5em"
                >
                  {(activeStep !== 0 || selectGroup) && (
                    <Button
                      color="primary"
                      disabled={selectGroup ? false : activeStep === 0}
                      onClick={handleBack}
                      style={{ marginRight: "10px" }}
                    >
                      Back
                    </Button>
                  )}
                  <Box id="textus-NewCampaign-NextButton">
                    {activeStep !== 2 && (
                      <>
                        <Button
                          aria-label="Next Button"
                          color="primary"
                          disabled={getIsNextDisabled()}
                          onClick={handleNext}
                          variant="contained"
                        >
                          <Loader isLoading={isSending}>
                            {isLastPage ? "Send Campaign" : "Next"}
                          </Loader>
                        </Button>
                        {currentAccount.featureFlags?.campaignDailyOverages && (
                          <Dialog ref={ref} defaultCloseValue={false}>
                            {({ close }) => {
                              return (
                                <OverageDialog
                                  dailyCampaignRecipients={
                                    dailyCampaignRecipients
                                  }
                                  recipientCount={getRecipientCount()}
                                  accountSlug={currentAccount.slug}
                                  campaignType={campaignType}
                                  close={close}
                                  remainingRecipients={remainingRecipients}
                                />
                              );
                            }}
                          </Dialog>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {campaignsResponsiveness && (
              <>
                <Box
                  display="flex"
                  flex="1 1 auto"
                  flexDirection="column"
                  margin="0 auto"
                  maxWidth="800px"
                  width="100%"
                >
                  {getStepContent(activeStep)}
                </Box>
                <Divider />
                {mobileView ? (
                  <MobileStepper
                    variant="text"
                    steps={4}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={getIsNextDisabled()}
                      >
                        Next
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        Back
                      </Button>
                    }
                    sx={(theme) => {
                      return {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignSelf: "stretch",
                        padding: "0.5rem 1rem",
                        fontSize: theme.typography.body2,
                        background: theme.palette.background.paper,
                      };
                    }}
                  />
                ) : (
                  <Box
                    alignItems="center"
                    display="flex"
                    flex="0 0 auto"
                    justifyContent="space-between"
                    width="100%"
                    height="52px"
                    padding="0 16px"
                  >
                    {activeStep === 2 && (
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                      >
                        <Button
                          style={{ alignSelf: "center" }}
                          onClick={() => {
                            handleAddVariant({
                              messageTemplates,
                              campaignTitle: campaignValues.title,
                            });
                          }}
                          aria-label="Add Message"
                          disabled={
                            messageTemplates.length > 2 ||
                            additionalRecipientsRequired
                          }
                        >
                          Add Message
                        </Button>

                        {additionalRecipientsRequired && (
                          <Typography variant="caption" color="text.secondary">
                            Additional recipients are needed to include another
                            message variant.
                          </Typography>
                        )}
                      </Box>
                    )}

                    <Box display="flex" flex="1 1 0" justifyContent="flex-end">
                      {(activeStep !== 0 || selectGroup) && (
                        <Button
                          color="primary"
                          disabled={selectGroup ? false : activeStep === 0}
                          onClick={handleBack}
                          style={{ marginRight: "10px" }}
                        >
                          Back
                        </Button>
                      )}
                      <Box id="textus-NewCampaign-NextButton">
                        {activeStep !== 2 && (
                          <>
                            <Button
                              aria-label="Next Button"
                              color="primary"
                              disabled={getIsNextDisabled()}
                              onClick={handleNext}
                              variant="contained"
                            >
                              <Loader isLoading={isSending}>
                                {isLastPage ? "Send Campaign" : "Next"}
                              </Loader>
                            </Button>
                            {currentAccount.featureFlags
                              ?.campaignDailyOverages && (
                              <Dialog ref={ref} defaultCloseValue={false}>
                                {({ close }) => {
                                  return (
                                    <OverageDialog
                                      dailyCampaignRecipients={
                                        dailyCampaignRecipients
                                      }
                                      recipientCount={getRecipientCount()}
                                      accountSlug={currentAccount.slug}
                                      campaignType={campaignType}
                                      close={close}
                                      remainingRecipients={remainingRecipients}
                                    />
                                  );
                                }}
                              </Dialog>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

NewCampaign.propTypes = {
  contactCollection: PropTypes.object,
  contactCollectionId: PropTypes.string,
  contactFilter: PropTypes.object,
  contactFilterId: PropTypes.string,
  contactImport: PropTypes.object,
  createCampaignRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  deliveryStats: PropTypes.object.isRequired,
  fetchGroupRequest: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  newCampaignContainer: PropTypes.object.isRequired,
  recipients: PropTypes.array,
  scheduleCampaignRequest: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  initialActiveStep: PropTypes.number,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container:
      "ui/app/features/Campaigns/containers/SendCampaign/NewCampaign/components/group",
    shape: { contacts: {} },
    type: "group",
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
)(NewCampaign);
