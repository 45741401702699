import { Identifiable } from "../types";
import { identify } from "./identify";

const root = "/accounts/";

type LockedAtAccount = { name: string | null; id: string | null };

export namespace Account {
  export interface Model {
    id: `/accounts/${string}`;
    name: string;
    role: string;
    slug: string;
    type: string;
    phoneNumbers: string[];
  }

  export type Settings = {
    product: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    companyName: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    primaryColor: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    companyFooter: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    tertiaryColor: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    secondaryColor: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    preferredDomain: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    campaignMaxMessageLength: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    conversationMaxMessageLength: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    automationKeywordMaxKeywordLimit: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    introMessages: {
      value?: {
        message: string;
        position: "before" | "after";
        frequency: number | null;
        messageEnabled: boolean;
      };
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    maxCampaignRecipients: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    messagingManuallyDisabled?: boolean;
    dailyCampaignRecipients: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    timeZone: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    legalEntity: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    officeHours: {
      value?: {
        endDay: number;
        endTime: number;
        startDay: number;
        startTime: number;
      };
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    countryCode: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    contactImportTarget: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    conversationAutoCloseAfter: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    unassignClosedConversationsAfter: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    unassignOpenConversationsAfter: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    forwardingNumberDisabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    forwardingNumber: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    inboundMmsDisabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    outboundMmsDisabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    stripInboundLinks: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    stripOutboundLinks: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    introMessageEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    introMessage: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    autoReplyMessageEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    autoReplyMessage: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    campaignDeliveryCutoffTime: {
      value?: number;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    campaignDeliveryCutoffTimeEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    avaloId: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    conversationExportsEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    outboundMessageColor: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    accountImageUrl: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    sharedOptOutsTarget: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    appendedMessageEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    appendedMessage: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    appendedMessageSource: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    appendedMessageDefaults: {
      value?: {
        message: string;
        frequency: number;
      };
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    conversationAppendedMessages: {
      value?: {
        messageEnabled: boolean;
        appendOnEvery: boolean;
        customMessageEnabled: boolean;
        customMessage: string;
      };
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    campaignAppendedMessages: {
      value?: {
        messageEnabled: boolean;
        appendOnEvery: boolean;
        customMessageEnabled: boolean;
        customMessage: string;
      };
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    email: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    supportLink: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    resellerId: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    registeredLinks: {
      value?: string[];
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    exclusionTimeEnabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    exclusionTime: {
      value?: string;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    mmsDisabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    accountNameChangeDisabled: {
      value?: boolean;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    automatedSenderId: {
      value?: string | null;
      locked?: boolean;
      lockedAtAccount?: LockedAtAccount;
      setAtAccount: string;
      disabled: boolean;
    };
    brandStatus: string;
    tcrCampaigns: {
      state: string;
      useCase: string;
      subUseCases: string[];
    };
  };

  export type Raw = {
    id: `/accounts/${string}`;
    adminUserSchema: Record<string, unknown>;
    campaigns: `${string}/campaigns`;
    campaignFilters: `${string}/campaignFilters`;
    childrenCount: number;
    contactsEnabled: boolean;
    contactImport?: string;
    conversationImport?: string;
    familyCount: number;
    featureFlags: { [key: string]: boolean };
    integrations: string | { id: string };
    logo?: string | null;
    multiUser: boolean;
    name: string;
    optOuts: string;
    phoneNumbers: string[];
    presignContactImport?: string;
    settings: Settings;
    shortenedLinkPreviewUrl: string;
    slug: string;
    type: "partner" | "organization" | "root" | "standard";
    users: string;
    userSchema: string;
    presignAttachment?: unknown;
    sendMessages?: unknown;
    messagingRestricted?: boolean;
  };

  export type Like = Identifiable<Raw>;

  export type Identity = Model["id"];

  export type Collection = Record<Identity, Model>;
}

export function sanitizeAccountId(id: string) {
  if (id.startsWith(root)) {
    return id.split("/")[2];
  }

  return id;
}

export function identifyAccount(account: Account.Like) {
  const id = identify(account);

  return sanitizeAccountId(id);
}
