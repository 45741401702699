import PropTypes from "prop-types";
import { Component } from "react";
import get from "lodash/get";
import queryString from "query-string";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SendIcon from "@mui/icons-material/Send";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

import BatchActionToolbar from "components/BatchActionToolbar";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import CampaignIcon from "icons/CampaignIcon";
import getNestedId from "utils/getNestedId";
import { SequenceEnroller } from "features/Sequences/components/SequenceEnroller";
import { extractCampaignContactFilterSlug } from "features/Sequences/components/SequenceEnroller/utils";

const BATCH_MESSAGE_LIMIT = 10;

export default class CampaignRecipientsBatchActions extends Component {
  static propTypes = {
    allSelected: PropTypes.bool.isRequired,
    campaignRecipientCollection: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    handleSetCompose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    selectedRecords: PropTypes.array,
    setAllSelected: PropTypes.func.isRequired,
    setSelected: PropTypes.func,
    setSelectedRecords: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationModalAction: undefined,
      notificationMessage: undefined,
    };
  }

  formatRecords = (records) => {
    return records
      .filter((record) => {
        return Boolean(record.contactPhone);
      })
      .map(({ contactPhone }) => {
        return {
          name: contactPhone.contact.name,
          contactPhoneId: contactPhone.id,
          deliverabilityStatus: contactPhone.deliverabilityStatus,
          formattedPhoneNumber: contactPhone.formattedPhoneNumber,
        };
      });
  };

  getBatchActions = () => {
    const batchActions = [
      {
        title: "Send Message",
        icon: <SendIcon />,
        clickHandler: this.handleSendMessageClick,
        showExpanded: true,
      },
      {
        title: "Send Campaign",
        icon: <CampaignIcon />,
        clickHandler: this.handleSendCampaignClick,
        showExpanded: true,
      },
      {
        button: (
          <SequenceEnroller
            key="enroller"
            allSelected={this.props.allSelected}
            buttonType="icon"
            currentAccount={this.props.currentAccount}
            contactCollectionId={this.props.campaignRecipientCollection.id}
            contactFilterSlug={extractCampaignContactFilterSlug(
              this.props.campaignRecipientCollection.id,
            )}
            selected={this.props.selected}
            formattedSelectedRecords={this.formatRecords(
              this.props.selectedRecords,
            )}
            setSelected={() => {
              this.props.setAllSelected(false);
            }}
            setSelectedRecords={this.props.setSelectedRecords}
          />
        ),
        showExpanded: true,
      },
      {
        title: "Opt Out Number",
        icon: <MobileOffIcon />,
        clickHandler: this.createClickHandler("unsubscribe"),
      },
      {
        title: "Block Number",
        icon: <RemoveCircleIcon />,
        clickHandler: this.createClickHandler("block"),
      },
      {
        title: "Unblock Number",
        icon: <CheckCircleIcon />,
        clickHandler: this.createClickHandler("unblock"),
      },
    ];

    return batchActions;
  };

  getActionPastParticiple = (action) => {
    if (action.endsWith("e")) return `${action}d`;
    return `${action}ed`;
  };

  getRequestUrl = ({ action, collection, selectAll }) => {
    const actionRequestUrl = collection[`${action}Contacts`];
    const [base, query] = actionRequestUrl.split("?");
    const queryParams = queryString.stringify({
      ...queryString.parse(query),
      selectAll: selectAll ? "true" : undefined,
    });
    return `${base}${queryParams ? `?${queryParams}` : ""}`;
  };

  createClickHandler = (action, message = null) => {
    return () => {
      const { allSelected, campaignRecipientCollection, selected } = this.props;
      const requestUrl = this.getRequestUrl({
        action,
        collection: campaignRecipientCollection,
        selectAll: allSelected,
      });
      const totalCount = allSelected
        ? campaignRecipientCollection.totalItems
        : selected.length;
      this.props[`${action}CampaignRecipientCollectionRequest`](requestUrl, {
        recipients: selected,
      });
      const baseMessage = `contacts ${this.getActionPastParticiple(action)}`;
      this.setState({
        notificationMessage: `${totalCount.toLocaleString()} ${
          message || baseMessage
        }`,
      });
    };
  };

  handleNotificationClose = () => {
    this.setState({ notificationMessage: undefined });
  };

  handleSendMessageClick = () => {
    this.props.handleSetCompose();
  };

  handleSendCampaignClick = () => {
    const { allSelected, campaignRecipientCollection, history, selected } =
      this.props;
    const contactFilterId = getNestedId(
      campaignRecipientCollection,
      "sendCampaign",
    );
    const state = allSelected
      ? { contactFilterId, initialActiveStep: 1 }
      : { selectedContacts: selected };
    history.push({
      pathname: `${global.document.location.pathname
        .split("/")
        .slice(0, 2)
        .join("/")}/campaigns/new`,
      state,
    });
  };

  handleModalCancel = () => {
    this.setState({
      confirmationModalAction: undefined,
    });
  };

  handleModalConfirm = () => {
    const message = "opt-in requests sent";
    this.createClickHandler("subscribe", message)();
    this.setState({
      confirmationModalAction: undefined,
    });
  };

  render() {
    const {
      allSelected,
      campaignRecipientCollection,
      currentAccount,
      selected,
      setAllSelected,
      setSelected,
      setSelectedRecords,
    } = this.props;
    const { confirmationModalAction, notificationMessage } = this.state;
    const dailyCampaignRecipients = get(currentAccount, [
      "settings",
      "dailyCampaignRecipients",
      "value",
    ]);
    const selectAllCount = campaignRecipientCollection.totalItems;
    const selectedCount = selected.length;
    const totalSelected = allSelected ? selectAllCount : selectedCount;
    const actions = this.getBatchActions()
      .filter(({ title }) => {
        return !(
          title === "Send Message" && totalSelected > BATCH_MESSAGE_LIMIT
        );
      })
      .filter(({ title }) => {
        return !(
          title === "Send Campaign" && totalSelected > dailyCampaignRecipients
        );
      });
    return (
      <>
        <BatchActionToolbar
          actions={actions}
          allSelected={allSelected}
          contactCollection={campaignRecipientCollection}
          position={{ top: "0px", left: "64px" }}
          selectedCount={selectedCount}
          selectAllCount={selectAllCount}
          setAllSelected={setAllSelected}
          setSelectedContacts={setSelected}
          setSelectedRecords={setSelectedRecords}
          showSelectAll={selectedCount > 0}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={Boolean(notificationMessage)}
          autoHideDuration={5000}
          onClose={this.handleNotificationClose}
        >
          <SnackbarContent
            message={<span>{notificationMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleNotificationClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <ConfirmationModal
          cancellationHandler={this.handleModalCancel}
          confirmationHandler={this.handleModalConfirm}
          isOpen={Boolean(confirmationModalAction)}
          message="This will send a message to all the selected recipients that were manually unsubscribed,
          requesting them to opt back in."
          title="Send opt-in request?"
        />
      </>
    );
  }
}
