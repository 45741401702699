import get from "lodash/get";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import CampaignVariantsPreview from "../../sharedComponents/CampaignVariantsPreview";
import { OneTimeCampaignScheduler } from "../../sharedComponents/OneTimeCampaignScheduler";
import { CampaignPreviewProps } from "./types";
import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import CampaignMessage from "features/Campaigns/containers/Campaign/components/CampaignMessage";
import getUserRole from "utils/getUserRole";
import P from "components/P";

function CampaignPreview({
  autoAssign,
  campaign,
  currentAccount,
  currentUser,
  getDailyCampaignRecipients,
  getCutoffError,
  groupName,
  isSignatureActive,
  messageBody,
  messageTemplates,
  scheduledAt,
  scheduleError,
  setAutoAssign,
  setScheduledAt,
  setScheduleError,
}: CampaignPreviewProps) {
  const signatureContent = get(currentUser, ["signature", "content"], "");

  const messageAttachments = messageTemplates[0].attachments;

  const recipientCount = campaign?.deliveryStatistics?.total ?? "";

  const hasMembership = getUserRole(currentUser, currentAccount);

  const multipleVariants = messageTemplates?.length > 1;

  const getCampaignPreview = () => {
    const campaignPreview = {
      messageBody: isSignatureActive
        ? `${messageBody}\n\n${signatureContent}`
        : messageBody,
      user: currentUser,
    };

    return <CampaignMessage campaign={campaignPreview} />;
  };

  return (
    <Box
      display="flex"
      flex="flex: 1 1 auto"
      flexDirection="column"
      padding="20px"
      style={{ minHeight: 0 }}
      width="100%"
    >
      <P
        style={{
          flex: "flex: 0 0 auto",
          fontSize: "1rem",
          marginBottom: "20px",
        }}
      >
        {`Please review your campaign message and select a time to send it. Please note that your account is limited to ${getDailyCampaignRecipients()} campaign messages per day.`}
      </P>
      <Box
        aria-label="Campaign Preview"
        display="flex"
        flexDirection="column"
        alignItems="center"
        m="0 auto"
        maxWidth="600px"
        p={0}
      >
        <Box
          alignItems="flex-end"
          display="flex"
          flex="0 0 auto"
          flexDirection="column"
          padding="2.5rem"
        >
          {/* Will render only if the campaign does not contain multiple templates */}
          {!multipleVariants && !!messageAttachments?.length && (
            <AttachmentPreview attachment={messageAttachments[0]} />
          )}
          {!multipleVariants && getCampaignPreview()}

          {/* The following component will render only if the campaign contains multiple templates */}
          {multipleVariants && (
            <CampaignVariantsPreview
              currentUser={currentUser}
              messageTemplates={messageTemplates}
            />
          )}
        </Box>
        <OneTimeCampaignScheduler
          groupName={groupName}
          isEditing
          scheduledAt={scheduledAt}
          scheduleError={scheduleError}
          setScheduleError={setScheduleError}
          setScheduledAt={setScheduledAt}
        />
        <Box
          alignItems="center"
          display="flex"
          flex="0 0 auto"
          flexDirection="column"
          textAlign="center"
        >
          <Typography variant="body2">Everything looks good!</Typography>
          <Typography variant="body2" fontWeight={700} mb="10px">
            {`Ready to send to ${recipientCount} people?`}
          </Typography>
          <CampaignCutoffInfo
            currentAccount={currentAccount}
            getCutoffError={getCutoffError}
          />
          <Box my="10px">
            {hasMembership ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={autoAssign}
                    color="secondary"
                    onChange={() => {
                      return setAutoAssign(!autoAssign);
                    }}
                  />
                }
                label="Assign me to campaign conversations"
                color="secondary"
              />
            ) : (
              <FormControlLabel
                sx={{ marginTop: "10px" }}
                control={<Switch disabled />}
                label="Conversations will be assigned to account's inbox"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CampaignPreview;
