import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { utcToZonedTime } from "date-fns-tz";
import { dayToSend, sendFrequency } from "./constants/timeOptions";
import createUUID from "utils/uuid";
import FormikSelect from "components/FormikSelect";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import { useTimeZones } from "hooks";

function RecurringCampaignTimeForm({
  currentAccount,
  errors,
  groupName = "",
  handleBlur,
  setFieldValue,
  timeZone,
  touched,
  values,
}) {
  const { abbreviatedAccountTimeZone, accountTimeZone } = useTimeZones();

  const [dayToSendOptions, setDayToSendOptions] = useState([]);

  const updateDependentField = (parentField, value) => {
    return setDayToSendOptions(dayToSend[value]);
  };

  const fields = [
    {
      dependentFieldOptions: ["dayToSend"],
      parentField: true,
      items: sendFrequency,
      label: "Repeat",
      name: "sendFrequency",
      required: true,
      postScript: "on",
    },
    {
      dependentFieldOptions: [],
      items: dayToSendOptions,
      label: "Day",
      name: "dayToSend",
      required: true,
      postScript: "at",
    },
  ];

  useEffect(() => {
    if (!values.sendTime) {
      setFieldValue(
        "sendTime",
        utcToZonedTime(new Date(), timeZone || accountTimeZone),
        true,
      );
    }
  }, [values.sendTime, timeZone, setFieldValue, accountTimeZone]);

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      p="20px"
      width="100%"
    >
      <Typography
        align="center"
        component="h2"
        sx={(theme) => {
          return { fontSize: "2.5rem" };
        }}
        variant="h2"
      >
        Schedule Message
      </Typography>
      <Box alignSelf="center" my="2rem">
        <CampaignCutoffInfo currentAccount={currentAccount} />
        <Typography
          align="center"
          component="p"
          sx={(theme) => {
            return { fontSize: "1rem" };
          }}
        >
          Sending to Group: <b>{groupName}</b>
        </Typography>
      </Box>
      <Box alignSelf="center" width="100%">
        <Box display="flex">
          {fields.map(
            ({
              dependentFieldOptions,
              name,
              items,
              label,
              required,
              parentField,
              postScript,
            }) => {
              return (
                <Box key={createUUID()} display="flex">
                  <Box width="145px">
                    <FormikSelect
                      dependentFieldOptions={dependentFieldOptions}
                      handleChange={parentField ? updateDependentField : false}
                      items={items}
                      label={label}
                      name={name}
                      required={required}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Box>
                  <Box alignSelf="center" justifySelf="center" m={2}>
                    <Typography component="p" color="textSecondary">
                      {postScript}
                    </Typography>
                  </Box>
                </Box>
              );
            },
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={values.sendTime || new Date()}
              onChange={(newTimeValue) => {
                setFieldValue("sendTime", new Date(newTimeValue), true);
              }}
              slotProps={{
                textField: {
                  name: "sendTime",
                  required: true,
                  onBlur: handleBlur,
                  error: touched.sendTime && !!errors.sendTime,
                  helperText: touched.sendTime && errors?.sendTime,
                },
              }}
            />
          </LocalizationProvider>
          <Box alignSelf="center" justifySelf="center" m={2}>
            <Tooltip
              placement="right"
              title={`Time values are in ${abbreviatedAccountTimeZone}`}
            >
              <InfoIconOutlined />
            </Tooltip>
          </Box>
        </Box>
        <Box my="1rem" maxWidth="200px">
          <Typography
            component="h4"
            sx={(theme) => {
              return { fontWeight: 700 };
            }}
          >
            Campaign Ends
          </Typography>
          <Box display="flex" flexDirection="column" mt=".5rem">
            <Box>
              <Checkbox
                color="secondary"
                inputProps={{ "aria-label": "Campaign Runs Infinitely" }}
                name="runsIndefinitely"
                onBlur={handleBlur}
                onChange={() => {
                  setFieldValue(
                    "runsIndefinitely",
                    !values.runsIndefinitely,
                    true,
                  );
                }}
                sx={(theme) => {
                  return { marginLeft: "-10px" };
                }}
                touched={touched}
                checked={values.runsIndefinitely ?? false}
              />
              Never
            </Box>
            {values.runsIndefinitely ? null : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={values.runsIndefinitely}
                  disablePast
                  label="Date"
                  format="MMMM dd, yyyy"
                  disableMaskedInput
                  value={
                    (!values.runsIndefinitely && values.campaignEndDate) ?? null
                  }
                  onChange={(newEndDate) => {
                    setFieldValue("campaignEndDate", newEndDate, true);
                  }}
                  slotProps={{
                    textField: {
                      name: "campaignEndDate",
                      required: !values.runsIndefinitely,
                      onBlur: handleBlur,
                      error:
                        touched.campaignEndDate && !!errors.campaignEndDate,
                      helperText:
                        touched.campaignEndDate && errors?.campaignEndDate,
                      readOnly: true,
                      placeholder: "",
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

RecurringCampaignTimeForm.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  groupName: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
export default RecurringCampaignTimeForm;
