import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

import { utcToZonedTime } from "date-fns-tz";
import { addMinutes, isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { handleResponse } from "@tesseract/core";
import { useSnackbar } from "notistack";
import CampaignForm from "../../sharedComponents/CampaignForm";
import CampaignProForm from "../../sharedComponents/CampaignProForm";
import CampaignPreview from "./CampaignPreview";
import getUserRole from "utils/getUserRole";
import createUUID from "utils/uuid";
import { createMessageBody } from "utils/createMessageBody";
import { useCurrentAccount, useTimeZones } from "hooks";
import { fetchGroup } from "features/Groups/api";
import { createSnackbarContent } from "components/Snackbar";

import PageHeader from "components/Page/PageHeader";

const convertToMessageTemplates = (templates) => {
  return templates.map((template) => {
    return {
      ...template,
      attachments: template.attachments?.members || template.attachments,
      body: template.messageBody,
    };
  });
};

function EditCampaign({
  campaign,
  currentAccount,
  currentUser,
  deliveryStats,
  editCampaignContainer,
  updateCampaignRequest,
  toggleSidebar,
}) {
  const { isCampaignProMember } = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { accountTimeZone, currentTime } = useTimeZones();

  const nextButton = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [root, setRoot] = useState(0);

  const campaignTemplates = campaign.campaignTemplates?.members;

  const [campaignValues, setCampaignValues] = useState({
    campaignEndDate: null,
    dayToSend: "",
    messageBody: campaignTemplates[0]?.messageBody ?? campaign.messageBody,
    messageTemplates: campaignTemplates ?? [],
    attachments: campaign.attachments?.members ?? [],
    runsIndefinitely: false,
    sendFrequency: "",
    sendTime: "",
    title: campaign.title,
  });

  const { attachments, messageBody, messageTemplates, title } = campaignValues;

  const [scheduledAt, setScheduledAt] = useState(
    utcToZonedTime(campaign.scheduledAt, accountTimeZone),
  );

  const [autoAssign, setAutoAssign] = useState(
    !!getUserRole(currentUser, currentAccount),
  );
  const [isSignatureActive, setIsSignatureActive] = useState(false);

  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });

  const [scheduleError, setScheduleError] = useState(false);

  const [groupName, setGroupName] = useState("");

  const signatureContent = currentUser?.signature?.content || "";

  const messageBodyWithoutSignature = messageBody
    .replace(signatureContent, "")
    .trim();

  useEffect(() => {
    if (messageBody.includes(signatureContent) && signatureContent !== "") {
      setIsSignatureActive(true);
    }
  }, [messageBody, signatureContent]);

  useEffect(() => {
    setRoot(document.querySelector("#textus-NewCampaign-NextButton"));
  }, []);

  useEffect(() => {
    fetchGroup(campaign.group)
      .then(handleResponse)
      .then((group) => {
        return setGroupName(group.name);
      })
      .catch((error) => {
        return console.error(error);
      });
  });

  useEffect(() => {
    if (isBefore(scheduledAt, currentTime)) {
      setScheduleError(true);
    }
  }, [currentTime, scheduledAt]);

  const getCutoffError = () => {
    const campaignCutoffTimeMinutes =
      currentAccount?.settings?.campaignDeliveryCutoffTime?.value;
    if (!campaignCutoffTimeMinutes) return false;
    const campaignDeliveryCutoffTime = addMinutes(
      startOfDay(scheduledAt),
      campaignCutoffTimeMinutes,
    );
    return isAfter(scheduledAt, campaignDeliveryCutoffTime);
  };

  const steps = ["Update Campaign", "Review and Send"];

  const getDailyCampaignRecipients = () => {
    return currentAccount.settings?.dailyCampaignRecipients?.value;
  };

  const getLimitError = () => {
    const isToday = isSameDay(
      new Date(),
      utcToZonedTime(scheduledAt, accountTimeZone),
    );
    if (!isToday) return null;
    const dailyCampaignRecipients = getDailyCampaignRecipients();
    const recipientCount = campaign?.deliveryStatistics?.total ?? 0;
    const sentAndScheduledCount =
      deliveryStats.campaignMessagesUsed ??
      0 + deliveryStats.campaignMessagesScheduled ??
      0;
    return recipientCount + sentAndScheduledCount > dailyCampaignRecipients
      ? "This campaign will push you over your daily limit. Please select a different date."
      : null;
  };

  const handleDeleteVariant = ({ id }) => {
    const updatedMessageTemplates = messageTemplates?.filter((template) => {
      return template.id !== id;
    });
    setCampaignValues({
      ...campaignValues,
      messageTemplates: updatedMessageTemplates,
    });
  };

  const handleAddVariant = () => {
    const updatedMessageTemplates = messageTemplates?.concat({
      attachments: [],
      messageBody: "",
      signatureActive: isSignatureActive,
      id: createUUID(),
    });
    setCampaignValues({
      ...campaignValues,
      messageTemplates: updatedMessageTemplates,
    });
  };

  const parseErrors = () => {
    const error = editCampaignContainer?.substate?.errorUpdating;
    if (!error) return undefined;
    const validationErrors = error.response?.validationErrors;
    if (!validationErrors)
      return "Something went wrong! Please select another date";
    return validationErrors[Object.keys(validationErrors)[0]] ?? "0";
  };

  const handleSubmit = (values) => {
    // we know that campaigns pro users will have messageTemplates
    // and non pro users will not
    if (values.messageTemplates) {
      const newMessageBody = values.messageTemplates[0].body;
      const updatedMessageTemplates = values.messageTemplates?.map(
        (template) => {
          return {
            ...template,
            messageBody: template.body,
          };
        },
      );

      setCampaignValues({
        ...campaignValues,
        ...values,
        messageBody: newMessageBody,
        messageTemplates: updatedMessageTemplates,
      });
    } else {
      setCampaignValues({
        ...campaignValues,
        ...values,
      });
    }

    setActiveStep(1);
  };

  const handleUpdate = () => {
    const isSending =
      editCampaignContainer?.substate?.isCreating ||
      editCampaignContainer?.substate?.isScheduling;
    if (isSending) return null;
    const updatedMessageBody = createMessageBody(
      messageBody,
      isSignatureActive,
      signatureContent,
      shortenedLink,
    );
    const body = {
      attachments: isCampaignProMember
        ? messageTemplates[0].attachments.members
        : attachments,
      autoAssign,
      title,
      scheduledAt,

      ...(!isCampaignProMember && {
        messageBody: updatedMessageBody,
        ...(scheduledAt &&
          isAfter(scheduledAt, new Date()) && {
            scheduledAt: scheduledAt.toISOString(),
          }),
      }),

      ...(isCampaignProMember && {
        messageTemplates,
      }),
    };

    return updateCampaignRequest(campaign.edit, body, {
      errorCallback: (error) => {
        enqueueSnackbar("error", {
          content: createSnackbarContent(
            "Something went wrong. Please try again.",
          ),
        });
        return console.error(`Error scheduling campaign: ${error.detail}`);
      },
    });
  };

  const getCampaignForm = () => {
    return isCampaignProMember && campaign.type === "single" ? (
      <CampaignProForm
        attachments={attachments}
        currentAccount={currentAccount}
        currentUser={currentUser}
        editCampaign
        handleAddVariant={handleAddVariant}
        handleDeleteVariant={handleDeleteVariant}
        handleSubmit={handleSubmit}
        messageTemplates={convertToMessageTemplates(messageTemplates)}
        nextButton={root}
        setShortenedLink={setShortenedLink}
        shortenedLink={shortenedLink}
        title={title}
      />
    ) : (
      <CampaignForm
        attachments={attachments}
        campaignType={campaign.type}
        currentAccount={currentAccount}
        currentUser={currentUser}
        editCampaign
        handleSubmit={handleSubmit}
        isSignatureActive={isSignatureActive}
        messageBody={messageBodyWithoutSignature}
        nextButton={root}
        setIsSignatureActive={setIsSignatureActive}
        setShortenedLink={setShortenedLink}
        shortenedLink={shortenedLink}
        title={title}
      />
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return getCampaignForm();
      case 1:
        return (
          <CampaignPreview
            attachments={attachments}
            autoAssign={autoAssign}
            campaign={campaign}
            currentUser={currentUser}
            currentAccount={currentAccount}
            getDailyCampaignRecipients={getDailyCampaignRecipients}
            getLimitError={getLimitError}
            getCutoffError={getCutoffError}
            groupName={groupName}
            isSignatureActive={isSignatureActive}
            messageBody={messageBody}
            messageTemplates={convertToMessageTemplates(messageTemplates)}
            parseErrors={parseErrors}
            scheduledAt={scheduledAt}
            setActiveStep={setActiveStep}
            setAutoAssign={setAutoAssign}
            scheduleError={scheduleError}
            setScheduledAt={setScheduledAt}
            setScheduleError={setScheduleError}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      color="text.primary"
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
    >
      <PageHeader title="Edit Campaign" toggleSidebar={toggleSidebar} />
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        minHeight="0"
        overflow="scroll"
        p="0"
        width="100%"
      >
        <Stepper
          activeStep={activeStep}
          style={{ margin: "0 auto", maxWidth: "1000px", width: "100%" }}
        >
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          margin="0 auto"
          maxWidth="800px"
          width="100%"
        >
          {getStepContent(activeStep)}
        </Box>
        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          margin="0 auto"
          maxWidth="800px"
          width="100%"
        >
          <Box
            alignItems="center"
            display="flex"
            flex="0 0 auto"
            justifyContent="flex-end"
            p="2.5em"
          >
            <Button
              aria-label="Back Button"
              color="primary"
              disabled={!activeStep}
              onClick={() => {
                return setActiveStep(0);
              }}
              style={{ marginRight: "10px" }}
            >
              Back
            </Button>
            <Box id="textus-NewCampaign-NextButton" ref={nextButton}>
              {activeStep === 1 && (
                <Button
                  aria-label="Send Button"
                  color="primary"
                  disabled={
                    !!getLimitError() || getCutoffError() || scheduleError
                  }
                  onClick={handleUpdate}
                  variant="contained"
                >
                  Send
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

EditCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  deliveryStats: PropTypes.object.isRequired,
  editCampaignContainer: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateCampaignRequest: PropTypes.func.isRequired,
};

export default EditCampaign;
