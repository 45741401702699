import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";

import { merge } from "lodash";
import { Props } from "./types";

function SearchBox({
  onClear: handleClear,
  fullWidth = true,
  variant = "outlined",
  value,
  sx,
  InputProps,
  ...props
}: Props) {
  return (
    <TextField
      sx={
        typeof sx === "function"
          ? sx
          : merge({ ".MuiInputBase-root": { paddingRight: "2px" } }, sx)
      }
      InputProps={merge(
        {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear search term"
                data-testid="clear-search-term"
                onClick={handleClear}
                onMouseDown={handleClear}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        },
        InputProps,
      )}
      {...merge(
        {
          fullWidth,
          variant,
          value,
        },
        props,
      )}
    />
  );
}

export { SearchBox };
