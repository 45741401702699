import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Formik, Field, getIn } from "formik";
import TextField from "@mui/material/TextField";

import IntegrationForm from "./IntegrationForm";
import IntegrationSubmitSection from "./IntegrationSubmitSection";
import { GreenhouseV2ValidationSchema } from "formHelpers/validationSchemas";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

const Logo = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  background: url("${(props) => {
    return props.src;
  }}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const FormRow = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

class GreenhouseV2 extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleActive: PropTypes.func.isRequired,
  };

  handleSubmit = (values) => {
    this.props.handleSubmit({
      provider: this.props.provider,
      config: values.config,
    });
  };

  toggleActive = () => {
    this.props.toggleActive({
      provider: this.props.provider,
    });
  };

  render() {
    return (
      <>
        <ModalHeader closeModal={this.props.provider.closeModal}>
          {this.props.provider.name}
        </ModalHeader>
        <ModalContent>
          <Formik
            enableReinitialize
            initialValues={{
              config: {
                apiKey: this.props.provider?.integration?.config?.api_key ?? "",
                email: this.props.provider?.integration?.config?.email ?? "",
              },
            }}
            validationSchema={GreenhouseV2ValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, isSubmitting, isValid }) => {
              return (
                <IntegrationForm>
                  <Logo src={this.props.provider.logo} />
                  <FormRow>
                    <Field name="config.email" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.email") &&
                              Boolean(getIn(errors, "config.email"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.email")
                                ? getIn(errors, "config.email") || " "
                                : " "
                            }
                            label="Email"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name="config.apiKey" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.apiKey") &&
                              Boolean(getIn(errors, "config.apiKey"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.apiKey")
                                ? getIn(errors, "config.apiKey") || " "
                                : " "
                            }
                            label="API key"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <IntegrationSubmitSection
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    provider={this.props.provider}
                    toggleActive={this.props.toggleActive}
                  />
                </IntegrationForm>
              );
            }}
          </Formik>
        </ModalContent>
      </>
    );
  }
}

export default GreenhouseV2;
