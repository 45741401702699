import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SvgIcon from "@mui/material/SvgIcon";

import Button from "features/Authentication/components/PrimaryButton";
import ICONS from "constants/icons";

function PingOneForm({ email = "", isClockInaccurate, authToken }) {
  const emailDomain = email.split("@")[1];
  const { domain } = window.nuauth?.login_domains?.[emailDomain] ?? {};
  return (
    <Box mt={3}>
      <Divider />
      <form
        action={`/auth/ping_one?domain=${domain}&email_domain=${emailDomain}`}
        aria-label="Sign in With PingOne"
        method="post"
        name="ping_one_auth"
        style={{ marginTop: 14 }}
        target={window.top === window.self ? "_self" : "_top"}
      >
        <Button disabled={isClockInaccurate} style={{ position: "relative" }}>
          <Box
            display="flex"
            sx={{
              background: "rgba(0, 0, 0, 0.1)",
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: "60px",
              padding: " 0 5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon viewBox="0 0 80 20" width="2rem" sx={{ width: "2.5rem" }}>
              <path d={ICONS.PING_ONE_LOGO} />
            </SvgIcon>
          </Box>
          <span>Sign in with PingOne</span>
        </Button>
        <input type="hidden" name="authenticity_token" value={authToken} />
      </form>
    </Box>
  );
}

PingOneForm.propTypes = {
  authToken: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isClockInaccurate: PropTypes.bool.isRequired,
};

export default PingOneForm;
