import {
  Alert,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import CampaignVariantsPreview from "../../sharedComponents/CampaignVariantsPreview";
import { formatTimeValues } from "../../sharedComponents/constants/timeOptions";
import { OneTimeCampaignScheduler } from "../../sharedComponents/OneTimeCampaignScheduler";
import { CampaignPreviewProps } from "./types";
import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import CampaignMessage from "features/Campaigns/containers/Campaign/components/CampaignMessage";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import getUserRole from "utils/getUserRole";

function CampaignPreview({
  attachments,
  autoAssign,
  campaignType,
  campaignValues,
  currentAccount,
  currentUser,
  dailyCampaignRecipients,
  getCutoffError,
  getLimitError,
  getRecipientCount,
  groupName,
  isSignatureActive,
  messageTemplates,
  parseErrors,
  scheduledAt,
  setAutoAssign,
  setScheduledAt,
  setScheduleError,
  scheduleError,
}: CampaignPreviewProps) {
  const {
    campaignEndDate,
    dayToSend,
    messageBody,
    runsIndefinitely,
    sendFrequency,
    sendTime,
  } = campaignValues;

  const signatureContent = currentUser.signature?.content;
  const campaign = {
    messageBody: isSignatureActive
      ? `${messageBody}\n\n${signatureContent}`
      : messageBody,
    user: currentUser,
  };

  const hasMembership = getUserRole(currentUser, currentAccount);
  const recipientCount = getRecipientCount();
  const oneTimeCampaign = campaignType === "single";

  const limitError = getLimitError();
  const errorSubmitting = limitError || parseErrors();
  const multipleVariants = messageTemplates?.length > 0;

  const { formattedSendFrequency, formattedDayToSend } = formatTimeValues(
    sendFrequency,
    dayToSend,
  );
  const RecurringCampaignInfo = (
    <Box alignSelf="center">
      <Typography align="center" style={{ fontSize: "1rem" }}>
        Repeats
      </Typography>
      <Typography align="center" style={{ fontSize: "1rem" }}>
        <b>{formattedSendFrequency}</b> on <b>{formattedDayToSend}</b> at{" "}
        <b>{sendTime}</b>
      </Typography>
      {!runsIndefinitely && campaignEndDate ? (
        <Typography align="center" fontSize="1rem" fontWeight={700}>
          Ends {format(campaignEndDate, "MMMM dd, yyyy")}
        </Typography>
      ) : null}
      <Typography
        align="center"
        style={{ fontSize: "1rem", marginTop: "2rem" }}
      >
        Sending to Group: <b>{groupName}</b>
      </Typography>
    </Box>
  );

  const getCampaignDescription = () => {
    if (multipleVariants && messageTemplates?.length > 1) {
      return (
        <Box maxWidth={600} m="0 auto">
          <Typography
            component="p"
            color="textSecondary"
            style={{
              fontSize: "1rem",
              margin: "1rem",
              textAlign: "center",
            }}
          >
            Your campaign will be sent equally to your recipient list. We will
            keep track of the response rates to show which message performed the
            best.
          </Typography>
          <Typography
            component="p"
            color="textSecondary"
            style={{
              fontSize: "1rem",
              marginBottom: ".5rem",
              textAlign: "center",
            }}
          >
            Sending to {recipientCount} recipients
          </Typography>
        </Box>
      );
    }

    if (oneTimeCampaign) {
      return (
        <Box
          maxWidth={600}
          m="0 auto"
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          alignItems="center"
        >
          <Typography
            component="p"
            color="textSecondary"
            variant="body1"
            gutterBottom={false}
          >
            Review your campaign message and select a time to send.
          </Typography>
          <Typography
            component="p"
            color="textSecondary"
            variant="subtitle2"
            gutterBottom={false}
          >
            <b>Please note:</b> This messaging account is limited to{" "}
            {dailyCampaignRecipients} campaign messages per day.
          </Typography>
        </Box>
      );
    }

    return RecurringCampaignInfo;
  };

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      p="20px"
      width="100%"
    >
      <Typography
        align="center"
        component="h2"
        style={{ fontSize: "2.5rem" }}
        variant="h2"
      >
        Review and send
      </Typography>
      <Box mt="0.5rem" alignSelf="center">
        {getCampaignDescription()}
      </Box>
      <Box
        aria-label="Campaign Preview"
        display="flex"
        flexDirection="column"
        alignItems="center"
        m="0 auto"
        maxWidth="600px"
        p="0"
      >
        <Box
          alignItems="center"
          display="flex"
          flex="0 0 auto"
          flexDirection="column"
          padding="2.5em"
        >
          {attachments?.length > 0 ? (
            <AttachmentPreview attachment={attachments[0]} />
          ) : null}
          {messageBody ? <CampaignMessage campaign={campaign} /> : null}
          {multipleVariants ? (
            <CampaignVariantsPreview
              currentUser={currentUser}
              messageTemplates={messageTemplates}
            />
          ) : null}
        </Box>
        {oneTimeCampaign ? (
          <OneTimeCampaignScheduler
            groupName={groupName}
            scheduledAt={scheduledAt}
            scheduleError={scheduleError}
            setScheduleError={setScheduleError}
            setScheduledAt={setScheduledAt}
          />
        ) : null}
        <Box
          alignItems="center"
          display="flex"
          flex="0 0 auto"
          flexDirection="column"
          pb="2.5rem"
          textAlign="center"
        >
          <Typography variant="body2">Everything looks good!</Typography>
          <Typography
            variant="body2"
            fontWeight={700}
          >{`Ready to send to ${recipientCount} people?`}</Typography>
          <CampaignCutoffInfo
            getCutoffError={getCutoffError}
            currentAccount={currentAccount}
          />
          <FormControlLabel
            control={
              <Switch
                checked={autoAssign}
                onChange={() => {
                  return setAutoAssign(!autoAssign);
                }}
                disabled={!hasMembership}
                color="secondary"
              />
            }
            label={
              hasMembership
                ? "Assign me to campaign conversations"
                : "Conversations will be assigned to this account's inbox"
            }
            style={{ marginTop: "10px" }}
          />
          {errorSubmitting ? (
            <Box mt={2}>
              <Alert severity="error">{errorSubmitting}</Alert>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

export default CampaignPreview;
