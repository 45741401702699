import { useState } from "react";
import type { ComponentProps } from "react";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { compose } from "redux";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";

import { useLocation } from "react-router";
import { Account, User } from "@tesseract/core";
import { Location } from "history";
import withRecord from "higherOrderComponents/withRecord";
import { getAccountUnansweredCount } from "features/MainNavigation/utils";
import globalNavigationRoutes from "constants/globalNavigationRoutes";
import MuiInlineBadge from "components/MuiInlineBadge";
import getPathnameArray from "utils/getPathnameArray";

const getNewPathname = ({
  account,
  location,
}: {
  account: Account.Raw;
  location: Location<unknown>;
}) => {
  const [, accountSlug, feature] = getPathnameArray(location.pathname);
  const onGlobalNavigation = globalNavigationRoutes.includes(accountSlug);
  if (onGlobalNavigation) return `/${account.slug}`;
  if (feature) return `/${account.slug}/${feature}`;
  return `/${account.slug}`;
};

type ComposedProps = {
  updateUserRequest: (
    id: string,
    data: {
      defaultMessagingAccount: string;
    },
    options: {
      successCallback: () => void;
      errorCallback: () => void;
    },
  ) => void;
};

type Props = {
  accountNavbarElement: {
    id: string;
    account: Account.Raw;
    displayPhoneNumber: string;
  };
  currentUser: User.Raw;
  setCurrentAccount: (id: string, options: { pathname: string }) => void;
  setPopperAnchorEl: (anchorEl: HTMLElement | null) => void;
};

function AccountNavOption({
  accountNavbarElement,
  currentUser,
  updateUserRequest,
  setCurrentAccount,
  setPopperAnchorEl,
}: Props & ComposedProps) {
  const [showBadge, setShowBadge] = useState(true);
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const defaultAccountId = currentUser.settings?.defaultMessagingAccount;
  const memberships = get(currentUser, ["memberships", "members"], []);
  const navAccount = accountNavbarElement.account;
  const navMembership = memberships.find((membership) => {
    return get(membership, ["account", "id"]) === navAccount.id;
  });
  const membershipRole = get(navMembership, ["role"]);

  const handleClick = () => {
    setPopperAnchorEl(null);
    setCurrentAccount(navAccount.id, {
      pathname: getNewPathname({ account: navAccount, location }),
    });
  };

  const isDefaultAccount = navAccount.slug === defaultAccountId;
  const hideHomeIcon = navAccount.type !== "standard";

  const handleSetDefault: ComponentProps<typeof IconButton>["onClick"] = (
    event,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const successCallback = () => {
      enqueueSnackbar("Default messaging account saved successfully.");
    };

    const errorCallback = () => {
      enqueueSnackbar(
        "Error saving default messaging account. Please try again.",
        {
          variant: "error",
        },
      );
    };

    if (!isDefaultAccount) {
      updateUserRequest(
        currentUser.id,
        {
          defaultMessagingAccount: navAccount.slug,
        },
        { successCallback, errorCallback },
      );
    }
  };

  return (
    <ListItem
      button
      aria-label={`Open ${navAccount.name}`}
      onClick={handleClick}
      onMouseEnter={() => {
        return setShowBadge(false);
      }}
      onMouseLeave={() => {
        return setShowBadge(true);
      }}
    >
      <Box display="flex" width="100%" height="100%" alignItems="center">
        <Box flex="0 0 34px" display="flex" justifyContent="center">
          {showBadge || hideHomeIcon ? (
            <MuiInlineBadge
              badgeContent={getAccountUnansweredCount(accountNavbarElement)}
              color="error"
              inlineStyles={{ margin: 0 }}
              letterColor="customColors.badgeText"
            />
          ) : (
            <Tooltip
              title={isDefaultAccount ? "Default account" : "Set as default"}
              disableFocusListener
            >
              <IconButton
                aria-label={`Set ${navAccount.name} as default account`}
                onClick={handleSetDefault}
                size="large"
              >
                <HomeIcon color={isDefaultAccount ? "primary" : "inherit"} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box flex="1 1 auto" minWidth={0} py={0.5} px={2} whiteSpace="nowrap">
          <Box
            color="primary.main"
            component="h3"
            fontSize="fontSize"
            fontWeight="fontWeightBold"
            m={0}
            whiteSpace="normal"
          >
            {navAccount.name}
          </Box>
          <Box
            color="text.secondary"
            component="h3"
            fontSize="12px"
            fontWeight="fontWeightMedium"
            m={0}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {accountNavbarElement.displayPhoneNumber}
          </Box>
        </Box>
        <Box flex="0 0 auto">
          <Chip
            icon={
              membershipRole === "admin" ? (
                <SupervisorAccountIcon />
              ) : (
                <PersonIcon />
              )
            }
            label={capitalize(membershipRole)}
          />
        </Box>
      </Box>
    </ListItem>
  );
}

export default compose(
  withRecord({
    actions: ["update"],
    container: "features/Profile",
    type: "user",
  }),
)(AccountNavOption) as unknown as (p: Props) => JSX.Element;
