import { Component } from "react";
import Root from "features/Campaigns/containers/CampaignAnalytics/components/Root";
import withRecord from "higherOrderComponents/withRecord";

class CampaignAnalytics extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "Campaigns/containers/CampaignAnalytics",
  shape: {},
  type: "campaignAnalytics",
})(CampaignAnalytics);
