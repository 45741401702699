import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

function Tooltip10dlcNotice({ children, text, visibility }) {
  const StyledTooltip = withStyles({
    tooltip: {
      textAlign: "center",
      visibility: `${visibility}`,
      whiteSpace: "pre-line",
    },
  })(Tooltip);
  return <StyledTooltip title={text}>{children}</StyledTooltip>;
}

Tooltip10dlcNotice.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string.isRequired,
  visibility: PropTypes.string,
};

export default Tooltip10dlcNotice;
