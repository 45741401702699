import { Component, createRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import get from "lodash/get";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import UserFormTooltipContent from "./UserFormTooltipContent";
import Avatar from "components/Avatar";
import H3 from "components/H3";
import InfoTooltip from "components/InfoTooltip";
import Loader from "components/Loader";
import AccountTreeSelector from "features/AccountTreeSelector";

import { UserValidationSchema } from "formHelpers/validationSchemas";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.popperAnchorRef = createRef({});
    const selectedAccounts = get(
      props,
      ["user", "memberships", "members"],
      [],
    ).reduce((prev, { account }) => {
      return {
        ...prev,
        [account.id]: account,
      };
    }, {});
    this.state = {
      activeAccountSelect: null,
      selectedAccounts,
      popperOpen: false,
    };
  }

  getBlankMembership = () => {
    return {
      id: `new-membership`,
      account: "",
      role: "user",
    };
  };

  setActiveAccountSelect = (popperAnchorEl, activeAccountSelect) => {
    this.setState({ activeAccountSelect });
  };

  render() {
    const { handleClose, handleFormSubmit, user } = this.props;
    const { selectedAccounts } = this.state;
    const initialValues = {
      email: get(user, "email", ""),
      name: get(user, "name", ""),
      memberships: get(
        user,
        ["memberships", "members"],
        [this.getBlankMembership()],
      ).map((member) => {
        return {
          id: member.id,
          role: member.role,
          account: get(member, ["account", "id"], ""),
        };
      }),
    };

    const { popperOpen } = this.state;

    return (
      <Formik
        enableReinitialize
        validateOnMount={UserValidationSchema.isValidSync(initialValues)}
        validationSchema={UserValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          return handleFormSubmit(user, values, actions);
        }}
      >
        {({
          errors = {},
          touched = {},
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
          values,
        }) => {
          return (
            <Form style={{ height: "100%" }}>
              <Box
                display="flex"
                height={{ xs: "auto", sm: "100%" }}
                flexDirection="column"
                px={{ xs: 3, sm: 5 }}
                pt={0}
                pb={4}
              >
                <Box
                  paddingTop="20px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  height="90px"
                >
                  <b>IMPORTANT</b>
                  <Typography color="textPrimary" variant="body1">
                    Creating a new user will only add them into an existing
                    message account.
                  </Typography>
                </Box>
                <Box flex="0 0 auto" mt={4} mb={2}>
                  <H3>Basic Info</H3>
                </Box>

                <Box
                  alignItems="center"
                  display="flex"
                  flex="0 0 auto"
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  {get(user, ["name"]) && (
                    <Box
                      flex="0 0 auto"
                      mr={5}
                      display={{ xs: "none", sm: "block" }}
                    >
                      <Avatar subject={user} size={88} />
                    </Box>
                  )}
                  <Box flex="1 1 auto" width={{ xs: "100%", sm: "auto" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field type="text" name="name">
                          {({ field }) => {
                            return (
                              <TextField
                                {...field}
                                autoFocus
                                variant="outlined"
                                error={touched.name && Boolean(errors.name)}
                                fullWidth
                                helperText={
                                  touched.name ? errors.name || " " : " "
                                }
                                inputProps={{ "data-lpignore": true }}
                                label="Name"
                                type="text"
                                id="UserManagement-UserForm-name"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field type="email" name="email">
                          {({ field }) => {
                            return (
                              <TextField
                                {...field}
                                autoFocus
                                variant="outlined"
                                error={touched.name && Boolean(errors.name)}
                                fullWidth
                                helperText={
                                  touched.name ? errors.name || " " : " "
                                }
                                inputProps={{ "data-lpignore": true }}
                                label="Email"
                                type="email"
                                id="UserManagement-UserForm-email"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  alignItems="center"
                  display="flex"
                  flex="0 0 auto"
                  mt={4}
                  mb={2}
                >
                  <Box flex="0 0 auto">
                    <H3>{`Account Memberships (${values.memberships.length})`}</H3>
                  </Box>
                  <Box
                    color="text.secondary"
                    flex="0 0 auto"
                    lineHeight="100%"
                    ml={1}
                  >
                    <InfoTooltip title={<UserFormTooltipContent />}>
                      <InfoIcon
                        color="inherit"
                        fontSize="small"
                        style={{ cursor: "help" }}
                      />
                    </InfoTooltip>
                  </Box>
                </Box>

                <Box flex={{ xs: "0 0 auto", sm: "1 1 auto" }} overflow="none">
                  <FieldArray name="memberships">
                    {(arrayHelpers) => {
                      return (
                        <Box mt={1}>
                          <Popper
                            anchorEl={this.popperAnchorRef.current}
                            disablePortal
                            open={popperOpen}
                            transition
                            placement="bottom-start"
                            style={{
                              zIndex: 99,
                              top: "432px",
                              left: "40px",
                            }}
                          >
                            <Paper elevation={8}>
                              <ClickAwayListener
                                onClickAway={() => {
                                  return this.setState({
                                    popperOpen: false,
                                    activeAccountSelect: null,
                                  });
                                }}
                              >
                                <Box width="320px">
                                  <AccountTreeSelector
                                    accountTreeCollectionId={`/accounts/tree?${queryString.stringify(
                                      {
                                        role: "admin",
                                      },
                                    )}`}
                                    handleClick={(ev, accountTree) => {
                                      ev.preventDefault();
                                      setFieldValue(
                                        this.state.activeAccountSelect,
                                        accountTree.id,
                                      );
                                      setFieldTouched(
                                        this.state.activeAccountSelect,
                                        true,
                                      );
                                      this.setState((prevState) => {
                                        return {
                                          popperOpen: false,
                                          activeAccountSelect: null,
                                          selectedAccounts: {
                                            ...prevState.selectedAccounts,
                                            [accountTree.id]: accountTree,
                                          },
                                        };
                                      });
                                    }}
                                    selected={[]}
                                  />
                                </Box>
                              </ClickAwayListener>
                            </Paper>
                          </Popper>
                          {values.memberships.map((membership, index) => {
                            return (
                              <Box
                                key={membership.id}
                                display="flex"
                                mb={{ xs: 4, sm: 2 }}
                                alignItems={{ xs: "flex-end", sm: "center" }}
                              >
                                <Box flex="1 1 auto">
                                  <Grid
                                    key={membership.id}
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        type="text"
                                        name={`memberships.${index}.account`}
                                      >
                                        {({ field }) => {
                                          return (
                                            <FormControl
                                              fullWidth
                                              variant="outlined"
                                              ref={this.popperAnchorRef}
                                              onClick={() => {
                                                return this.setState({
                                                  popperOpen: true,
                                                });
                                              }}
                                            >
                                              <InputLabel htmlFor={field.name}>
                                                Account
                                              </InputLabel>
                                              <OutlinedInput
                                                aria-label="Account Select"
                                                error={
                                                  getIn(
                                                    touched,
                                                    `memberships.${index}.account`,
                                                  ) &&
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `memberships.${index}.account`,
                                                    ),
                                                  )
                                                }
                                                id={field.name}
                                                label="Account"
                                                data-lpignore
                                                readOnly
                                                value={get(
                                                  selectedAccounts[field.value],
                                                  ["name"],
                                                  "",
                                                )}
                                                onClick={({
                                                  currentTarget,
                                                }) => {
                                                  this.setActiveAccountSelect(
                                                    currentTarget,
                                                    field.name,
                                                  );
                                                }}
                                              />
                                              <FormHelperText error>
                                                {getIn(
                                                  touched,
                                                  `memberships.${index}.account`,
                                                )
                                                  ? getIn(
                                                      errors,
                                                      `memberships.${index}.account`,
                                                      " ",
                                                    )
                                                  : " "}
                                              </FormHelperText>
                                            </FormControl>
                                          );
                                        }}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        type="select"
                                        name={`memberships.${index}.role`}
                                      >
                                        {({ field }) => {
                                          return (
                                            <TextField
                                              {...field}
                                              select
                                              variant="outlined"
                                              error={
                                                getIn(
                                                  touched,
                                                  `memberships.${index}.role`,
                                                ) &&
                                                Boolean(
                                                  getIn(
                                                    errors,
                                                    `memberships.${index}.role`,
                                                  ),
                                                )
                                              }
                                              fullWidth
                                              label="Role"
                                              id={`UserManagement-memberships-${index}-role`}
                                              helperText={
                                                getIn(
                                                  touched,
                                                  `memberships.${index}.role`,
                                                )
                                                  ? getIn(
                                                      errors,
                                                      `memberships.${index}.role`,
                                                      " ",
                                                    )
                                                  : " "
                                              }
                                            >
                                              <MenuItem value="admin">
                                                Admin
                                              </MenuItem>
                                              <MenuItem value="user">
                                                User
                                              </MenuItem>
                                            </TextField>
                                          );
                                        }}
                                      </Field>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box
                                  flex="0 0 80px"
                                  display="flex"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  mb="22px"
                                >
                                  {values.memberships.length > 1 && (
                                    <Tooltip title="Remove Membership">
                                      <div>
                                        <IconButton
                                          type="button"
                                          onClick={() => {
                                            return arrayHelpers.remove(index);
                                          }}
                                          size="large"
                                        >
                                          <RemoveCircleOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </div>
                                    </Tooltip>
                                  )}

                                  {index === values.memberships.length - 1 &&
                                    !values.memberships.filter((m) => {
                                      return !m.account;
                                    }).length && (
                                      <Tooltip title="Add Membership">
                                        <IconButton
                                          type="button"
                                          onClick={() => {
                                            return arrayHelpers.push(
                                              this.getBlankMembership(),
                                            );
                                          }}
                                          size="large"
                                        >
                                          <AddCircleOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    }}
                  </FieldArray>
                </Box>

                <Box flex="0 0 auto" mt="40px" textAlign="right">
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="Save"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    <Loader isLoading={isSubmitting || false}>Save</Loader>
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

UserForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default UserForm;
