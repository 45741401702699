import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { parseISO } from "date-fns";
import { dateOptions } from "../constants/DateOptions";
import { useFilters } from "../hooks/useFilters";
import { CurrentUser } from "../models/AnalyticsModels";

type AnalyticsFiltersProps = {
  currentUser: CurrentUser;
};

const formatForDatePicker = (startDateParam: string) => {
  return parseISO(`${startDateParam}T00:00:00`);
};

function AnalyticsFilters({ currentUser }: AnalyticsFiltersProps) {
  const {
    account,
    accountList,
    handleSelectTimePeriod,
    handleUpdateAccount,
    handleUpdateEndDate,
    handleUpdateStartDate,
    reportDates,
    timePeriod,
  } = useFilters({ currentUser });

  const { reportStartDate, reportEndDate } = reportDates;

  return (
    <Box
      alignItems="center"
      aria-label="Analytics Filters"
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      flex="0 0 auto"
      justifyContent="flex-start"
    >
      {accountList.length && !!account && (
        <Box
          width={{ xs: "100%", sm: "200px" }}
          mr={{ xs: 0, sm: 2 }}
          mb={{ xs: 2, sm: 0 }}
        >
          <TextField
            fullWidth
            data-product-tour="AnalyticsReports-AccountDropdown"
            id="textUs-analyticsDateSelector-Account"
            label="Account"
            onChange={({ target }) => {
              return handleUpdateAccount(target);
            }}
            select
            value={account.id}
            variant="outlined"
          >
            {accountList.map(({ id, name }: { id: string; name: string }) => {
              return (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          width={{ xs: "100%", sm: "200px" }}
          mr={{ xs: 0, sm: 2 }}
          mb={{ xs: 2, sm: 0 }}
        >
          <TextField
            value={timePeriod}
            onChange={handleSelectTimePeriod}
            variant="outlined"
            fullWidth
            select
            label="Time Period"
            id="TextUs-analyticsDateSelector-TimePeriod"
          >
            <MenuItem value="custom">Custom Range</MenuItem>
            {dateOptions.map(({ name, value }) => {
              return (
                <MenuItem value={value} key={value}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        <Box
          width={{ xs: "100%", sm: "200px" }}
          mr={{ xs: 0, sm: 2 }}
          mb={{ xs: 2, sm: 0 }}
        >
          <DatePicker
            disabled={timePeriod !== "custom"}
            disableFuture
            label="Start Date"
            renderInput={(props) => {
              return <TextField {...props} />;
            }}
            onChange={handleUpdateStartDate}
            value={formatForDatePicker(reportStartDate)}
            timezone="UTC"
          />
        </Box>
        <Box
          width={{ xs: "100%", sm: "200px" }}
          mr={{ xs: 0, sm: 2 }}
          mb={{ xs: 2, sm: 0 }}
        >
          <DatePicker
            disabled={timePeriod !== "custom"}
            disableFuture
            label="End Date"
            renderInput={(props) => {
              return <TextField {...props} />;
            }}
            onChange={handleUpdateEndDate}
            value={formatForDatePicker(reportEndDate)}
            minDate={formatForDatePicker(reportStartDate)}
            timezone="UTC"
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
}

export default AnalyticsFilters;
