import { Autocomplete, TextField } from "@mui/material";
import {
  format,
  utcToZonedTime,
  formatInTimeZone,
  zonedTimeToUtc,
} from "date-fns-tz";
import { startOfDay, addMinutes, getHours, getMinutes } from "date-fns";
import { AbsoluteTimeSelection, DropdownTimePickerProps } from "./types";
import { useTimeZones } from "hooks";

function DropdownTimePicker({
  useDefaultValue,
  displayNow,
  error,
  field,
  fromSequence,
  minDate,
  selectedDate,
  timeZone,
  value,
  handleSelect,
}: DropdownTimePickerProps) {
  const { accountTimeZone, browserTimeZone, ABSOLUTE_TIME_FORMAT, TIMESTAMP } =
    useTimeZones();

  /**
   * Returns a formatted string label based on the provided value.
   * It also converts "am" or "pm" in the raw value to uppercase.
   *
   * @param {string} rawValue - The raw string to be formatted into a label.
   * @param {string | undefined} tz - The timezone string, if any.
   *
   */
  const getLabel = (rawValue: string, tz?: string) => {
    const formattedLabel = tz
      ? rawValue
      : rawValue.replace(/[a-zA-Z]+$/, "").trim();

    return formattedLabel.replace(/\b(am|pm)\b/, (match) => {
      return match.toUpperCase();
    });
  };

  /**
   * Generates a list of time options throughout a day at 15-minute intervals in the specified timezone.
   * Optionally adds a "Now" option if displayNow is passed as prop.
   *
   * @param {string} [tz] - The IANA timezone identifier (e.g., "America/Denver"). If omitted, the system timezone is used.
   */
  const getTimeOptions = (tz?: string) => {
    const now = tz ? utcToZonedTime(new Date(), tz) : new Date();

    return [...Array(96)].reduce((accumulator, _, index) => {
      const interval = index * 15;
      const minutes = getHours(now) * 60 + getMinutes(now);
      const addNow =
        displayNow && minutes > interval && minutes <= interval + 15;
      const midnight = tz
        ? zonedTimeToUtc(startOfDay(new Date()), tz).toISOString()
        : startOfDay(new Date()).toISOString();

      const prettyLabel = tz
        ? formatInTimeZone(
            addMinutes(new Date(midnight), interval),
            tz,
            TIMESTAMP,
          )
        : format(addMinutes(new Date(midnight), interval), TIMESTAMP);

      // Handles an edge case where AK and HI abbreviations provided by date-fns-tz are not recognized by the Date constructor.
      const valueWithTimezone = prettyLabel.replace(
        /(HST|AKDT|HDT|AKST)/g,
        (abbreviatedTimeZone) => {
          switch (abbreviatedTimeZone) {
            case "HST":
              return "GMT-10";
            case "AKDT":
              return "GMT-8";
            case "HDT":
            case "AKST":
              return "GMT-9";
            default:
              return abbreviatedTimeZone;
          }
        },
      );

      return [
        ...accumulator,
        {
          label: getLabel(prettyLabel, tz),
          valueWithTimezone,
        },
        ...(addNow
          ? [
              {
                label: "Now",
                value: formatInTimeZone(now, accountTimeZone, TIMESTAMP),
              },
            ]
          : []),
      ];
    }, []);
  };

  const options = getTimeOptions(timeZone);

  const filteredOptions = options.filter((option: AbsoluteTimeSelection) => {
    // Current user time in milliseconds.
    const now = Date.now();

    const formattedNow = timeZone
      ? formatInTimeZone(now, timeZone, ABSOLUTE_TIME_FORMAT)
      : format(now, ABSOLUTE_TIME_FORMAT);

    // Time represented by each option, in milliseconds.
    const parsedOption = Date.parse(
      `${formattedNow} ${option.valueWithTimezone}`,
    );

    const isToday =
      formatInTimeZone(
        selectedDate || new Date(),
        timeZone || browserTimeZone,
        ABSOLUTE_TIME_FORMAT,
      ) === formattedNow;

    // 600_000 = 10 minutes in milliseconds.
    const minutesOffset = fromSequence ? 600_000 : 0;

    // Screens out times in the past or within 10 minutes of now.
    // Includes "Now" option but only if displayNow is true (Campaigns).
    let showOption =
      (isToday && parsedOption - minutesOffset > now) ||
      (displayNow && option.label === "Now");

    // Screens out times that occur before previous step.
    if (minDate && selectedDate) {
      // Get the previous date in milliseconds.
      const minDateMilliseconds = minDate.getTime();

      // Get current selection date in milliseconds.
      const formattedSelectedDate = timeZone
        ? formatInTimeZone(selectedDate, timeZone, ABSOLUTE_TIME_FORMAT)
        : format(selectedDate, ABSOLUTE_TIME_FORMAT);

      const optionMilliseconds = Date.parse(
        `${formattedSelectedDate} ${option.valueWithTimezone}`,
      );

      // Only show times that occur after min date.
      showOption = showOption || minDateMilliseconds < optionMilliseconds;
    }

    return isToday || (minDate && selectedDate)
      ? showOption
      : option.label !== "Now" || displayNow;
  });

  return (
    <Autocomplete
      id="TextUs-TimePicker-time"
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      options={filteredOptions}
      defaultValue={displayNow || useDefaultValue ? value : null}
      getOptionLabel={(option: AbsoluteTimeSelection) => {
        return option.label;
      }}
      isOptionEqualToValue={(option, selected) => {
        return option.valueWithTimezone === selected.valueWithTimezone;
      }}
      sx={{ minWidth: "10rem" }}
      renderInput={(props) => {
        return (
          <TextField
            {...props}
            {...field}
            error={Boolean(error)}
            helperText={error ?? ""}
            label="Time"
            value={value}
            variant="outlined"
            sx={{
              width: "200px",
            }}
          />
        );
      }}
    />
  );
}

export { DropdownTimePicker };
