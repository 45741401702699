import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

const InfoTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      boxShadow: theme.shadows[8],
      maxWidth: "none",
      padding: 0,
    },
  };
})(Tooltip);

export default InfoTooltip;
