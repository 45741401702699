/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useEffect, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { addMonths } from "date-fns";
import { AbsoluteTimePickerProps } from "../types";
import { DropdownTimePicker } from "components/DropdownTimePicker";
import { useAbsoluteTimePicker } from "features/Sequences/hooks/useAbsoluteTimePicker";
import { useTimeZones } from "hooks";

function AbsoluteTimePicker({
  errors,
  touched,
  minDate,
  values,

  setFieldValue,
  setFieldTouched,
}: AbsoluteTimePickerProps) {
  const { dateField, timeField, handleSelectDate, handleSelectTime } =
    useAbsoluteTimePicker(values, setFieldValue, setFieldTouched);

  const { accountTimeZone } = useTimeZones();
  const [loaded, setLoaded] = useState<any>(false);
  const [timeValue, setTimeValue] = useState<any>(null);

  useEffect(() => {
    // Call these methods on init to update the pickers if we have a value (edit mode).
    if (values.absoluteTime) {
      handleSelectDate(new Date(values.absoluteTime));

      const timezoneValue = new Date(values.absoluteTime);

      const formattedTimezone = formatInTimeZone(
        timezoneValue,
        accountTimeZone,
        "h:mm a z",
      );
      const value = {
        label: formattedTimezone,
        valueWithTimezone: formattedTimezone,
      };

      setTimeValue(value);
      handleSelectTime(value);
    }

    setLoaded(true);
  }, []);

  // Ensures pickers are updated before loading Automcomplete component.
  // Otherwise, an error gets thrown related to trying to update an uncontrolled component.
  if (!loaded) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
      marginTop={2}
      marginBottom={2}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disablePast
          format="EEEE, LLL do, yyyy"
          minDate={minDate}
          label="Date"
          maxDate={addMonths(new Date(), 18)}
          onChange={handleSelectDate}
          slotProps={{
            textField: {
              ...dateField,
              variant: "outlined",
              sx: { width: "340px" },
              error: touched.date && Boolean(errors.date),
              helperText:
                touched.date && Boolean(errors.date) ? errors.date : "",
            },
          }}
          timezone={accountTimeZone}
          value={values.date}
        />
      </LocalizationProvider>

      <DropdownTimePicker
        error={touched.time ? errors.time : ""}
        field={timeField}
        fromSequence
        handleSelect={handleSelectTime}
        minDate={minDate}
        selectedDate={values.date}
        timeZone={accountTimeZone}
        value={timeValue}
        useDefaultValue
      />
    </Box>
  );
}
export default AbsoluteTimePicker;
