/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Box } from "@mui/material";
import { ContactsDataGrid } from "../ContactsDataGrid";
import { SequencesContactFilters } from "../ContactsDataGrid/components/SequencesContactFilters";
import { useSequencesContactFilters } from "../ContactsDataGrid/hooks/useSequencesContactFilters";
import { SequencesContactsTabProps } from "./types";
import { SequencesContactsSideNav } from "./components/SequencesContactsSideNav";
import { useDebouncedSearch } from "hooks";
import { useSequenceRecipients } from "features/Sequences/hooks/useSequences/useSequenceRecipients";
import { sortStringArray } from "utils/sortStringArray";
import isPossiblePhoneNumber from "utils/isPossiblePhoneNumber";

function SequencesContactsTab({
  disableAddContacts,
  individualSequence,
  isLoadingNewRecipient,
  rowState,
  refreshPage,
  showSidebar,
  toggleEnrollDrawer,
}: SequencesContactsTabProps) {
  const { searchTerm, debouncedSearchTerm, handleClear, handleSearch } =
    useDebouncedSearch();

  const {
    isLoadingAllRecipients,
    pagination,
    fetchNextPage,
    getSequenceRecipients,
  } = useSequenceRecipients(rowState, individualSequence.id);

  const {
    checkedSteps,
    checkedEnrollers,
    checkedStepsDisplayString,
    checkedEnrollersDisplayString,
    sequenceState,
    reason,
    respondedFalse,
    respondedTrue,
    handleCheckStep,
    handleCheckEnroller,
    handleFilterClick,
    handleResponseClick,
    handleStoppedClick,
  } = useSequencesContactFilters();

  const isEmpty = rowState.rows.length === 0;

  const isSearchFilterActive =
    Boolean(debouncedSearchTerm) ||
    Boolean(sequenceState) ||
    Boolean(reason) ||
    checkedSteps.length > 0 ||
    checkedEnrollers.length > 0;

  const isPhoneSearch = isPossiblePhoneNumber(debouncedSearchTerm);

  useEffect(() => {
    getSequenceRecipients(individualSequence.id, undefined, {
      name: isPhoneSearch ? "" : debouncedSearchTerm,
      phoneNumber: isPhoneSearch ? debouncedSearchTerm : "",
      steps: sortStringArray(checkedSteps),
      enrollers: checkedEnrollers,
      sequenceState,
      reason,
      respondedFalse,
      respondedTrue,
    });
  }, [
    checkedEnrollers,
    checkedSteps,
    debouncedSearchTerm,
    sequenceState,
    reason,
    respondedFalse,
    respondedTrue,
  ]);

  return (
    <Box display="flex" flexDirection="row" height="100%" width="100%">
      <SequencesContactsSideNav
        sequence={individualSequence}
        handleFilterClick={handleFilterClick}
        handleResponseClick={handleResponseClick}
        handleStoppedClick={handleStoppedClick}
      />
      <Box
        display="flex"
        flexDirection="column"
        height={isEmpty ? "3.5rem" : "100%"}
        width="100%"
      >
        <SequencesContactFilters
          checkedSteps={checkedSteps}
          checkedEnrollers={checkedEnrollers}
          checkedStepsDisplayString={checkedStepsDisplayString}
          checkedEnrollersDisplayString={checkedEnrollersDisplayString}
          searchTerm={searchTerm}
          sequence={individualSequence}
          handleCheckEnroller={handleCheckEnroller}
          handleCheckStep={handleCheckStep}
          handleClear={handleClear}
          handleSearch={handleSearch}
        />
        <ContactsDataGrid
          disableAddContacts={disableAddContacts}
          isLoadingAllRecipients={isLoadingAllRecipients}
          isLoadingNewRecipient={isLoadingNewRecipient}
          isSearchFilterActive={isSearchFilterActive}
          pagination={pagination}
          rowState={rowState}
          sequence={individualSequence}
          fetchNextPage={fetchNextPage}
          refreshPage={refreshPage}
          showSidebar={showSidebar}
          toggleEnrollDrawer={toggleEnrollDrawer}
        />
      </Box>
    </Box>
  );
}
export { SequencesContactsTab };
