import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import startCase from "lodash/startCase";

import { compactInteger } from "humanize-plus";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";

import breakpoints from "utils/styles/breakpoints";
import H4 from "components/H4";

const METRICS = ["campaignsSent", "recipients", "replyRate", "failureRate"];

const Wrapper = styled.section`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  flex: 0 0 auto;
`;

const NoStats = styled.div`
  padding: 20px;
  text-align: center;
  width: 100%;
`;

const MetricWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    flex-flow: row;
  }
`;

const Metric = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column nowrap;
  flex: 1 1 120px;
  padding: 15px;
  text-align: center;

  ${H4} {
    font-weight: 300;
    white-space: nowrap;
  }
`;

const Stat = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-size: 66px;
  font-weight: 700;
  margin: 0;
`;

const Operator = styled.span`
  font-size: 33px;
  margin-left: 10px;
`;

export default class Root extends Component {
  static propTypes = {
    campaignAnalytics: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAnalytics: true,
    };
  }

  toggleAnalytics = () => {
    this.setState((prevState) => {
      return {
        showAnalytics: !prevState.showAnalytics,
      };
    });
  };

  render() {
    const { showAnalytics } = this.state;
    const { campaignAnalytics } = this.props;
    const showMetrics = Boolean(
      METRICS.find((metric) => {
        return campaignAnalytics[metric];
      }),
    );
    return (
      <Wrapper>
        <ExpansionPanel
          square
          expanded={showAnalytics}
          onChange={this.toggleAnalytics}
          style={{ borderBottom: "none" }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            All Campaign Stats over the Last Week
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {showMetrics ? (
              <MetricWrapper>
                {METRICS.map((metric) => {
                  return (
                    <Metric key={metric}>
                      <H4>{startCase(metric)}</H4>
                      <Stat>
                        <span>
                          {compactInteger(
                            /rate/i.test(metric)
                              ? campaignAnalytics[metric] * 100
                              : campaignAnalytics[metric],
                            1,
                          )}
                        </span>
                        {/rate/i.test(metric) && <Operator>%</Operator>}
                      </Stat>
                    </Metric>
                  );
                })}
              </MetricWrapper>
            ) : (
              <NoStats>
                <H4>You have no campaign activity in the past week</H4>
              </NoStats>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Wrapper>
    );
  }
}
