import { FC } from "react";

import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

import {
  discardedKeywordsColumnHeaders,
  keywordsColumnHeaders,
} from "../constants/KeywordConstants";
import {
  ColumnHeader,
  Keyword,
  KeywordTableHeaderProps,
} from "../models/AutomationModels";
import { StyledHeaderTableCell } from "./styles";

const KeywordTableHeader: FC<KeywordTableHeaderProps> = ({
  discardedKeywords = false,
  order,
  orderBy,
  onRequestSort,
}): JSX.Element => {
  const createSortHandler = (property: keyof Keyword) => {
    return (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  };

  const columnHeaders = discardedKeywords
    ? discardedKeywordsColumnHeaders
    : keywordsColumnHeaders;

  return (
    <TableHead>
      <TableRow style={{ height: 28 }}>
        {columnHeaders.map((cell: ColumnHeader) => {
          return (
            <StyledHeaderTableCell
              key={cell.id}
              align={cell.numeric ? "right" : "left"}
              sortDirection={orderBy === cell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : "asc"}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden as SxProps<Theme>}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledHeaderTableCell>
          );
        })}
        <StyledHeaderTableCell align="center">Manage</StyledHeaderTableCell>
      </TableRow>
    </TableHead>
  );
};

export default KeywordTableHeader;
