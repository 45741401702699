import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import PreviousImportsSection from "../../../../Contacts/containers/ContactFilterCollection/components/PreviousImportsSection";
import { ArrowBackIcon } from "icons";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";

const Wrapper = styled.div`
  background: #f7fbff;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: scroll;
`;

class Root extends Component {
  static propTypes = {
    contactImportCollection: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
  };

  render() {
    const { contactImportCollection, currentAccount } = this.props;

    return (
      <Wrapper>
        <PageSidebarHeader>
          <NavLink
            to={`/${currentAccount.slug}/contacts/all`}
            component="div"
            style={{
              width: "100%",
            }}
          >
            <PageSidebarHeaderButton
              icon={
                <ArrowBackIcon
                  aria-label="Back to contacts button"
                  fontSize="small"
                />
              }
              text="Back to contacts"
            />
          </NavLink>
        </PageSidebarHeader>

        {/* Previous imports */}
        <PreviousImportsSection
          isOpen
          imports={contactImportCollection.members}
          currentAccount={currentAccount}
        />
      </Wrapper>
    );
  }
}

export default Root;
