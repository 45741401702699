import BaseSwitch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ComponentProps, ReactElement, useCallback } from "react";
import { SwitchProps } from "@material-ui/core";
import type { Option, Props, SupportedValueTypes } from "./types";
import { useField } from "components/hooks/useField";

/**
 * <Switch /> element
 *
 * @param {Props}
 * @returns {ReactElement}
 * @example
 * ```jsx
 * <Switch name="name" options={[false, 1]} defaultValue={1} />
 * ```
 */
export function Switch<
  // eslint-disable-next-line no-use-before-define
  Options extends Array<Option<ValueOfOptions>>,
  ValueOfOptions extends SupportedValueTypes,
>({
  name,
  options: [on, off],
  defaultValue,
  onChange,
  ...props
}: Props<Options, ValueOfOptions>): ReactElement {
  const [field, setValue] = useField<ValueOfOptions | undefined>(name, {
    defaultValue,
  });

  const handleOnChange = useCallback<NonNullable<SwitchProps["onChange"]>>(
    (event, value) => {
      const { checked } = event.target;

      setValue(checked ? on.value : off?.value);

      onChange?.(event, value);
    },
    [setValue, on.value, off?.value, onChange],
  );

  return (
    <FormControlLabel
      control={
        <BaseSwitch
          {...{
            ...field,
            ...props,
            onChange: handleOnChange,
            checked: field.value === on.value,
          }}
        />
      }
      label={
        field.value !== on.value && off !== undefined ? off?.label : on.label
      }
    />
  );
}
