import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
  Alert,
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import {
  messageSettings,
  customShadow,
  messagePositions,
  stickyFooterHeight,
  introSectionCopy,
  appendedSectionCopy,
} from "./constants";
import { IntroAndAppendedFormValues, IntroAndAppendedProps } from "./types";
import { IntroAndAppendedSection } from "./components/IntroAndAppendedSection";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import { StickyFooter } from "components/StickyFooter";
import { IntroAndAppendedValidationSchema } from "formHelpers/validationSchemas";
import { patchSettings } from "features/AccountSettings/api";
import { createSnackbarContent } from "components/Snackbar";
import { useAccount } from "features/Accounts/hooks";
import { produceAccountUpdate } from "features/Accounts/events/accountUpdated";
import { useCurrentUser } from "hooks";
import getUserRole from "utils/getUserRole";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import { LeavePageWarning } from "components/LeavePageWarning";

const StyledForm = styled(Form)({
  height: "100%",
  overflowY: "scroll",
});

function IntroAndAppended({
  isNavbarOpen,
  toggleNavbar,
}: IntroAndAppendedProps) {
  const theme = useTheme();
  const slug = window.location.pathname.split("/")[1];
  const { account } = useAccount(`/accounts/${slug}`);
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();

  const {
    appendedMessageDefaults,
    campaignAppendedMessages,
    conversationAppendedMessages,
    introMessages,
  } = account?.settings ?? {};

  const userRole = getUserRole(user, account);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";

  const showAlert = isAdmin && (account?.childrenCount ?? 0 > 1);

  const handleApplyChanges = async (formValues: IntroAndAppendedFormValues) => {
    if (!account) {
      return undefined;
    }
    try {
      const response = await patchSettings(account, formValues);

      if (response.ok) {
        const newAccountValues = await response.json();
        produceAccountUpdate(newAccountValues);
        enqueueSnackbar("Settings saved.", {
          content: createSnackbarContent("info"),
        });
      }
    } catch {
      enqueueSnackbar("Something went wrong. Please try again.", {
        content: createSnackbarContent("error"),
      });
    }
    return undefined;
  };

  const initialValues = {
    appendedMessageDefaults: {
      value: {
        message:
          appendedMessageDefaults?.value?.message ??
          messageSettings.appendedMessageDefaults.message,
        frequency:
          appendedMessageDefaults?.value?.frequency ??
          messageSettings.appendedMessageDefaults.frequency,
      },
      active: Boolean(appendedMessageDefaults?.value?.message) ?? false,
      locked: appendedMessageDefaults?.locked ?? false,
    },
    introMessages: {
      value: {
        messageEnabled: introMessages?.value?.messageEnabled ?? false,
        position: introMessages?.value?.position ?? messagePositions.before,
        frequency: introMessages?.value?.frequency ?? null,
        message: introMessages?.value?.message ?? "",
      },
      locked: introMessages?.locked ?? false,
    },
    campaignAppendedMessages: {
      value: {
        messageEnabled:
          campaignAppendedMessages?.value?.messageEnabled ?? false,
        appendOnEvery: campaignAppendedMessages?.value?.appendOnEvery ?? false,
        customMessageEnabled:
          campaignAppendedMessages?.value?.customMessageEnabled ?? false,
        customMessage: campaignAppendedMessages?.value?.customMessage ?? "",
      },
      locked: campaignAppendedMessages?.locked ?? false,
    },
    conversationAppendedMessages: {
      value: {
        messageEnabled:
          conversationAppendedMessages?.value?.messageEnabled ?? false,
        appendOnEvery:
          conversationAppendedMessages?.value?.appendOnEvery ?? false,
        customMessageEnabled:
          conversationAppendedMessages?.value?.customMessageEnabled ?? false,
        customMessage: conversationAppendedMessages?.value?.customMessage ?? "",
      },
      locked: conversationAppendedMessages?.locked ?? false,
    },
  };

  const handleBlankDefaultMessage = (
    values: IntroAndAppendedFormValues,
    setFieldValue: FormikHelpers<IntroAndAppendedFormValues>["setFieldValue"],
  ) => {
    // Replaces validation for message field in DefaultsCard
    // If a user clears the input and clicks Apply Changes,
    // the field reverts to the previous value
    if (values.appendedMessageDefaults.value.message === "") {
      setFieldValue(
        messageSettings.appendedMessageDefaults.fields.message,
        initialValues.appendedMessageDefaults.value.message,
      );
    }
  };

  return (
    <SettingsPageWrapper aria-label="Intro and Appended Message Form">
      <PageHeader title="Intro & Appended" toggleSidebar={toggleNavbar} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        isInitialValid
        onSubmit={handleApplyChanges}
        validationSchema={IntroAndAppendedValidationSchema}
        validateOnMount={IntroAndAppendedValidationSchema}
      >
        {({
          dirty,
          values,
          isSubmitting,
          isValid,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => {
          return (
            // @ts-expect-error: repo-wide type issue with Formik <Form> component
            <StyledForm>
              <Box height="100%">
                <SettingsPageContent
                  overflow={false}
                  aria-label="Intro and Appended Message Content"
                >
                  <Box display="flex" flexDirection="column" gap={2}>
                    {showAlert && account ? (
                      <Alert severity="warning" sx={{ marginBottom: "1rem" }}>
                        Modifying this may overwrite changes made on{" "}
                        <strong>
                          {account.childrenCount} branch and messaging accounts.
                        </strong>
                      </Alert>
                    ) : null}
                    <IntroAndAppendedSection
                      account={account}
                      copy={introSectionCopy}
                    />
                    <Divider />
                    <IntroAndAppendedSection
                      account={account}
                      copy={appendedSectionCopy}
                    />
                  </Box>
                </SettingsPageContent>
                {isUser ? null : (
                  <StickyFooter
                    display="flex"
                    flexDirection={mobileScreen ? "column" : "row"}
                    minHeight={
                      mobileScreen
                        ? stickyFooterHeight.mobile
                        : stickyFooterHeight.desktop
                    }
                    boxShadow={isNavbarOpen ? customShadow : theme.shadows[4]}
                    padding="1rem"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        resetForm();
                        enqueueSnackbar("Settings reverted.", {
                          content: createSnackbarContent("info"),
                        });
                      }}
                      disabled={!dirty || isSubmitting || isUser}
                      data-testid="revert-message-settings-button"
                    >
                      Revert Changes
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!dirty || !isValid || isSubmitting || isUser}
                      data-testid="apply-message-settings-button"
                      onClick={() => {
                        handleBlankDefaultMessage(values, setFieldValue);
                        handleSubmit();
                      }}
                    >
                      Apply Changes
                    </Button>
                  </StickyFooter>
                )}
              </Box>
              <LeavePageWarning showWarning={dirty && !isSubmitting} />
            </StyledForm>
          );
        }}
      </Formik>
    </SettingsPageWrapper>
  );
}

export { IntroAndAppended };
