import { useSelector } from "react-redux";
import { utcToZonedTime } from "date-fns-tz";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

export const useTimeZones = () => {
  const { settings } = useSelector((store) => {
    return selectCurrentAccount(store);
  });

  const ABSOLUTE_TIME_FORMAT = "dd LLL yyyy";
  const TIMESTAMP = "h:mm a zzz";

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const accountTimeZone: string = settings.timeZone?.value ?? browserTimeZone;

  // Takes in "America/Denver" and returns "MDT" or "MST"
  const getTimeZoneAbbreviation = (tz: string) => {
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: tz,
      hour12: false,
      timeZoneName: "short",
    });
    const parts = formatter.formatToParts(new Date());
    const timeZonePart = parts.find((part) => {
      return part.type === "timeZoneName";
    });
    return timeZonePart ? timeZonePart.value : "";
  };

  const abbreviatedAccountTimeZone = getTimeZoneAbbreviation(accountTimeZone);
  const abbreviatedBrowserTimeZone = getTimeZoneAbbreviation(browserTimeZone);

  const currentTime = utcToZonedTime(new Date(), accountTimeZone);

  return {
    abbreviatedAccountTimeZone,
    abbreviatedBrowserTimeZone,
    accountTimeZone,
    browserTimeZone,
    currentTime,
    ABSOLUTE_TIME_FORMAT,
    TIMESTAMP,
    getTimeZoneAbbreviation,
  };
};
