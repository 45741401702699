import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
} from "@mui/icons-material";
import {
  ClickAwayListener,
  Box,
  Button,
  Divider,
  Grow,
  List,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Account, User, identifyAccount } from "@tesseract/core";
import styled from "styled-components";
import withSizes from "react-sizes";
import { isEqual } from "lodash";
import AccountNavOption from "./AccountNavOption";
import { CurrentAccount } from "./CurrentAccount";
import { displayPhoneNumber } from "./utils";
import { getAccountUnansweredCount } from "features/MainNavigation/utils";
import MuiInlineBadge from "components/MuiInlineBadge";
import H5 from "components/H5";
import H4 from "components/H4";

import breakpoints from "utils/styles/breakpoints";

export const MultiAccountRoot = styled(Button)`
  flex: 1 1 auto;
  min-width: 0;

  span {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;

    ${H4} {
      text-transform: none;
      color: ${(props) => {
        return props.theme.colors.common.white;
      }};
    }

    ${H5} {
      color: ${(props) => {
        return props.theme.colors.grey["400"];
      }};
    }
  }
`;

const byAccount = (account: Account.Like) => {
  return (member: { id: string; account: Account.Raw }) => {
    return identifyAccount(member.account.id) !== identifyAccount(account);
  };
};

type Props = {
  accountNavbar: {
    members: {
      id: string;
      account: Account.Raw;
    }[];
  };
  currentAccount: Account.Raw;
  currentUser: User.Raw;
  onAccountChanged: ((account: Account.Like) => void) | undefined;
  viewAllAccountsLink?: boolean;
  viewingGlobalNavigationRoute: boolean | undefined;
};

type ComposedProps = { bottomNav: boolean };

export const MultiAccountMenu = withSizes<ComposedProps, Props & ComposedProps>(
  ({ width }) => {
    return {
      bottomNav: width < breakpoints.medium,
    } satisfies ComposedProps;
  },
)(
  ({
    accountNavbar,
    bottomNav,
    currentAccount,
    currentUser,
    onAccountChanged,
    viewAllAccountsLink,
    viewingGlobalNavigationRoute,
  }: Props & ComposedProps) => {
    const [popperOpen, setPopperOpen] = useState(false);
    const popperAnchorRef = useRef<HTMLButtonElement>(null);

    const handleClose = () => {
      return setPopperOpen(false);
    };
    const handleClick = () => {
      return setPopperOpen(!popperOpen);
    };

    const membershipAccounts = get(currentUser, ["memberships", "members"], []);

    const currentMembership = membershipAccounts.find((m) => {
      return m.account.id === currentAccount?.id;
    });

    const currentAccountNavbarElement = accountNavbar.members.find(
      ({ account }) => {
        if (currentMembership && currentMembership.account) {
          return isEqual(account, currentMembership.account);
        }
        return false;
      },
    );

    return (
      <>
        <MultiAccountRoot
          aria-label="Open Account Menu"
          onClick={handleClick}
          ref={popperAnchorRef}
        >
          {viewingGlobalNavigationRoute ? (
            <Box
              display="flex"
              color="common.white"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box marginRight="7px">
                <KeyboardArrowDownIcon />
              </Box>
              <Typography
                color="inherit"
                variant="body1"
                sx={{ textTransform: "none", fontWeight: 700 }}
              >
                Select an Account
              </Typography>
            </Box>
          ) : (
            <CurrentAccount currentAccount={currentAccount} />
          )}
        </MultiAccountRoot>
        <Popper
          open={popperOpen}
          anchorEl={popperAnchorRef.current}
          placement={bottomNav ? "top-end" : "bottom-end"}
          modifiers={[
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                padding: 8,
              },
            },
          ]}
          transition
          aria-label="Account Menu"
        >
          {({ TransitionProps }) => {
            return (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: bottomNav ? "bottom right" : "100% 0 0",
                }}
                timeout={350}
              >
                <Box width="320px" mb={{ xs: 1, sm: 0 }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={8}>
                      {!viewingGlobalNavigationRoute && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            height="100%"
                            py={3}
                            px={2}
                            textAlign="center"
                            width="100%"
                          >
                            <Box
                              flex="0 0 34px"
                              display="flex"
                              justifyContent="center"
                            >
                              {currentAccountNavbarElement && (
                                <MuiInlineBadge
                                  badgeContent={getAccountUnansweredCount(
                                    currentAccountNavbarElement,
                                  )}
                                  letterColor="customColors.badgeText"
                                  color="error"
                                  inlineStyles={{ margin: 0 }}
                                />
                              )}
                            </Box>
                            <Box
                              flex="1 1 auto"
                              minWidth={0}
                              py={0.5}
                              pr={4}
                              whiteSpace="nowrap"
                            >
                              <Box
                                color="text.secondary"
                                component="h5"
                                fontSize="12px"
                                fontStyle="italic"
                                fontWeight="fontWeightRegular"
                                m={0}
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {currentMembership
                                  ? `Logged in as ${
                                      get(currentMembership, ["role"]) ===
                                      "admin"
                                        ? `an ${currentMembership.role}`
                                        : `a ${currentMembership.role}`
                                    }`
                                  : "Viewing"}
                              </Box>
                              <Box
                                color="primary.main"
                                component="h3"
                                fontSize={{ xs: "16px", sm: "20px" }}
                                fontWeight="fontWeightBold"
                                m={0}
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {currentAccount?.name}
                              </Box>
                              <Box
                                color="text.main"
                                component="h4"
                                fontSize={{ xs: "14px", sm: "16px" }}
                                fontWeight="fontWeightRegular"
                                m={0}
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {currentAccount?.phoneNumbers
                                  ? displayPhoneNumber(
                                      currentAccount.phoneNumbers,
                                    )
                                  : ""}
                              </Box>
                            </Box>
                          </Box>
                          <Divider />
                        </>
                      )}

                      <List
                        component="nav"
                        aria-label="membership accounts"
                        disablePadding
                        style={{ maxHeight: "50vh", overflow: "scroll" }}
                      >
                        {accountNavbar.members
                          .filter(viewingGlobalNavigationRoute ?  () => true : byAccount(currentAccount.id))
                          .map((accountNavbarElement) => {
                            return (
                              <AccountNavOption
                                key={accountNavbarElement.id}
                                accountNavbarElement={accountNavbarElement}
                                currentUser={currentUser}
                                setCurrentAccount={onAccountChanged}
                                setPopperAnchorEl={handleClose}
                              />
                            );
                          })}
                      </List>

                      {viewAllAccountsLink !== false && (
                        <>
                          <Divider />
                          <Button
                            fullWidth
                            component={Link}
                            to="/accounts"
                            color="primary"
                            onClick={handleClose}
                          >
                            <Box
                              alignItems="center"
                              display="flex"
                              p={1}
                              style={{ textTransform: "none" }}
                            >
                              <SupervisedUserCircleIcon fontSize="large" />
                              <Box component="span" ml={2} fontSize="fontSize">
                                View Other Accounts
                              </Box>
                            </Box>
                          </Button>
                        </>
                      )}
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Grow>
            );
          }}
        </Popper>
      </>
    );
  },
) as (p: Props) => JSX.Element; // todo: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/51460
