import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { ContactNoteValidationSchema } from "formHelpers/validationSchemas";

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled.div`
  font-size: 0.8rem;
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }
`;

const FormRow = styled.div`
  align-items: center;
  display: flex;
`;

const SubmitRow = styled.div`
  margin-top: 10px;
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

export default class NoteForm extends Component {
  static propTypes = {
    cancelEdit: PropTypes.func,
    contactNoteCollectionId: PropTypes.string.isRequired,
    fetchContactNoteCollectionRequest: PropTypes.func.isRequired,
    note: PropTypes.object,
    requestUrl: PropTypes.string.isRequired,
    submitHandler: PropTypes.func.isRequired,
  };

  handleSubmit = (values, actions) => {
    const {
      cancelEdit,
      contactNoteCollectionId,
      fetchContactNoteCollectionRequest,
      requestUrl,
      submitHandler,
    } = this.props;
    submitHandler(requestUrl, values, {
      successCallback: () => {
        fetchContactNoteCollectionRequest(contactNoteCollectionId, null, {
          successCallback: () => {
            actions.setSubmitting(false);
            cancelEdit();
          },
        });
      },
      errorCallback: (errors) => {
        const validationErrors = get(
          errors,
          ["validationErrors"],
          "Something went wrong!",
        );
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
      },
    });
  };

  render() {
    const { cancelEdit, note = {} } = this.props;
    const initialValues = {
      content: note.content || "",
    };
    return (
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          isInitialValid={ContactNoteValidationSchema.isValidSync(
            initialValues,
          )}
          validationSchema={ContactNoteValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {({ errors, touched, isSubmitting, isValid }) => {
            return (
              <Form>
                <FormRow>
                  <Field type="text" name="content">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          error={touched.content && Boolean(errors.content)}
                          fullWidth
                          helperText={
                            touched.content ? errors.content || " " : " "
                          }
                          inputProps={{ "data-lpignore": true }}
                          label="Content"
                          multiline
                          placeholder="They're great!"
                          type="text"
                          variant="standard"
                        />
                      );
                    }}
                  </Field>
                </FormRow>
                <SubmitRow>
                  {cancelEdit && (
                    <CancelButton
                      color="primary"
                      onClick={cancelEdit}
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </CancelButton>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </SubmitRow>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    );
  }
}
