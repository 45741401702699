import BasicSettings from "../components/BasicSettings";
import OfficeHours from "../components/OfficeHours";
import Integrations from "../containers/Integrations";

import is from "utils/is";
import { SettingsSection } from "models/SettingsSection";
import { Account } from "models/Account";

export const getAccountSettings = ({
  isAdmin,
  account,
}: {
  isAdmin: boolean;
  account: Account;
}): SettingsSection => {
  return {
    title: "Account Settings",
    path: "account",
    settings: [
      {
        title: "Account Details",
        to: `/account-details`,
        OptionComponent: BasicSettings,
      },
      {
        title: "Office Hours",
        to: `/office-hours`,
        OptionComponent: OfficeHours,
      },
      isAdmin &&
      ((account.type !== "partner" && account.childrenCount > 0) ||
        account.type === "organization")
        ? {
            title: "Integrations",
            to: `/integrations`,
            // This component is causing a type error above
            OptionComponent: Integrations,
          }
        : null,
    ].filter(is.truthy), // filters out the nulls
  };
};
