import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCurrentAccount } from "hooks";

const Item = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "1 0 33.333%",
  alignItems: "center",
  padding: "8px",

  "& p": {
    textAlign: "center",
    margin: "auto 0 0",
  },

  "& span": {
    marginTop: "auto",
  },
});

const Root = styled("div")({
  height: "auto",
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
  overflow: "hidden",
  fontSize: "15px",
});

const Information = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    fontWeight: 700,
  };
});

const Label = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  };
});

export default function ConversationStats(props) {
  const screens = {
    mdAndDown: useMediaQuery((theme) => {
      return theme.breakpoints.down("md");
    }),
    mobile: useMediaQuery((theme) => {
      return theme.breakpoints.only("sm");
    }),
  };

  const { conversationStats } = props;

  const account = useCurrentAccount();
  const { inboxContactRedesign } = account.featureFlags;

  const { engagement, conversationStartedAt, outboundDelivered } =
    conversationStats;
  const age = moment(conversationStartedAt).fromNow(true);

  return (
    <Root>
      <Tooltip title={`You’ve been texting this person for ${age}`}>
        <Item className="age">
          <Information variant="body1" isMedium={screens.mdAndDown}>
            {" "}
            {age}
          </Information>
          <Label variant="caption" isMedium={screens.mdAndDown}>
            Age
          </Label>
        </Item>
      </Tooltip>

      <Tooltip title="The ratio of messages sent to messages received">
        <Item>
          {engagement ? (
            <Information variant="body1">{engagement}</Information>
          ) : (
            <Information variant="body1">- </Information>
          )}
          <Label variant="caption">Engagement</Label>
        </Item>
      </Tooltip>

      <Tooltip title="Messages that  were successfully delivered">
        <Item>
          <Information variant="body1" isMedium={screens.mdAndDown}>
            {outboundDelivered !== null ? `${outboundDelivered * 100} %` : "NA"}
          </Information>
          <Label variant="caption" isMedium={screens.mdAndDown}>
            Delivery rate
          </Label>
        </Item>
      </Tooltip>
    </Root>
  );
}

ConversationStats.propTypes = {
  conversationStats: PropTypes.object.isRequired,
};
