import { Box, Typography } from "@mui/material";
import { CampaignVariantsPreviewProps } from "./types";
import CampaignMessage from "features/Campaigns/containers/Campaign/components/CampaignMessage";
import { campaignVariantMap } from "features/Campaigns/constants";

function CampaignVariantsPreview({
  messageTemplates,
  currentUser,
}: CampaignVariantsPreviewProps) {
  const signatureContent = currentUser?.signature?.content ?? "";

  return (
    <Box>
      {messageTemplates.map(
        ({ attachments, body, signatureActive, id }, index) => {
          const campaignVariant = {
            messageBody: signatureActive
              ? `${body}\n\n${signatureContent}`
              : body,
            user: currentUser,
          };
          return (
            <Box mb={4} key={id}>
              <Typography style={{ fontWeight: 600, marginBottom: 4 }}>
                Message {campaignVariantMap[index + 1]}
              </Typography>
              <Box>
                {body && (
                  <CampaignMessage
                    attachment={attachments[0]}
                    campaign={campaignVariant}
                  />
                )}
              </Box>
            </Box>
          );
        },
      )}
    </Box>
  );
}

export default CampaignVariantsPreview;
