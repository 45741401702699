import moment from "moment-timezone";
import { formatValues } from "../../SendCampaign/sharedComponents/constants/timeOptions";

export const createTimeStamp = ({
  currentAccount,
  schedule,
  scheduledAt,
  sendVerbiage,
  singleCampaign,
}) => {
  const date = new Date(scheduledAt);
  const monthName = date.toLocaleString("default", {
    month: "long",
  });
  const [, day, year] = date.toLocaleDateString("en-US").split("/");
  const time = date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
  const clientTz = moment.tz(moment.tz.guess()).format("z");
  const accountTz =
    moment().tz(currentAccount?.settings?.timeZone?.value)?.zoneAbbr() ||
    clientTz;
  const { formattedFrequency, formattedSendDate, formattedTime } =
    formatValues(schedule);
  const scheduleString = `Sends ${formattedFrequency} on ${formattedSendDate} at ${formattedTime}`;
  return singleCampaign
    ? `${sendVerbiage} ${monthName} ${day}, ${year} at ${time} ${clientTz}`
    : `${scheduleString} ${accountTz}`;
};
