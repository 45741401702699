import { Component } from "react";
import PropTypes from "prop-types";
import ReportIcon from "@mui/icons-material/Report";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import Tooltip from "@mui/material/Tooltip";

export default class SubscriptionStatusPill extends Component {
  static propTypes = {
    isBlocked: PropTypes.bool,
    isOptedOut: PropTypes.bool,
  };

  getStatus() {
    const { isBlocked, isOptedOut } = this.props;
    if (isBlocked)
      return { icon: <ReportIcon color="error" />, title: "blocked" };
    if (isOptedOut)
      return {
        icon: <SpeakerNotesOffIcon color="error" />,
        title: "opted out",
      };
    return null;
  }

  render() {
    const status = this.getStatus();
    if (!status) return null;
    return (
      <Tooltip title={`This number is ${status.title}`}>{status.icon}</Tooltip>
    );
  }
}
