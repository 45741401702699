/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Skeleton,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { BaseDrawer } from "..";
import { getFromInbox } from "../../SequenceEnroller/utils";
import { ConfirmEnrollmentDrawerProps } from "./types";
import { SequencePreview } from "./components/SequencePreview";
import { getSequenceStepDelay } from "features/Sequences/screens/IndividualSequenceOverview/utils";
import { useIndividualSequence } from "features/Sequences/hooks/useIndividualSequence";
import DeliverabilityStatus from "components/DeliverabilityStatus";
import { useTimeZones } from "hooks";

export function ConfirmEnrollmentDrawer({
  allContactsCount,
  allSelected,
  contactCollectionId,
  contactFilterSlug,
  currentAccount,
  displayedContacts,
  isEmpty,
  isLinkingKeyword,
  isLoading,
  isOpen,
  selectedContactPhoneIds,
  sequence,
  sequenceStepOne,
  fetchNextPage,
  handleBack,
  handleClose,
  handleConfirm,
}: ConfirmEnrollmentDrawerProps) {
  const fromInbox = getFromInbox(contactCollectionId);

  // ==== HOOKS ==== //
  const { accountTimeZone } = useTimeZones();

  const { addRecipients, setIndividualSequence, setSelectedEnrollees } =
    useIndividualSequence(currentAccount, sequence.id);

  const [alert, setAlert] = useState("");
  const [delayText, setDelayText] = useState("");

  useEffect(() => {
    if (sequenceStepOne) {
      const stepDelay = `${
        sequenceStepOne.absoluteTime ? "on" : "in"
      } ${getSequenceStepDelay(sequenceStepOne, accountTimeZone)}`;

      setDelayText(
        `New subscribers to this keyword will receive
        Step #1 of this sequence ${stepDelay} after 
        subscribing to this keyword.`,
      );

      const alertText = isLinkingKeyword
        ? `Step #1 will be sent ${stepDelay} to existing contacts.
        Contacts are enrolled after receiving the keyword auto-response.`
        : `Step #1 will be sent ${stepDelay}.`;

      setAlert(alertText);
    }
  }, [sequenceStepOne]);

  useEffect(() => {
    if (allSelected && contactFilterSlug && !fromInbox) {
      setSelectedEnrollees(contactFilterSlug);
    } else {
      setSelectedEnrollees(selectedContactPhoneIds);
    }
  }, [allSelected, selectedContactPhoneIds]);

  useEffect(() => {
    setIndividualSequence(sequence);
  }, [sequence]);

  const renderLoadingBar = () => {
    return (
      <Box
        padding={1}
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Box>
    );
  };

  const handleEnroll = () => {
    if (!isLinkingKeyword) {
      addRecipients();
    }
    handleConfirm();
  };

  // ==== RENDER ==== //
  return (
    <BaseDrawer
      title="Confirm enrollment"
      isOpen={isOpen}
      confirmText={`Confirm (${
        allSelected && !fromInbox
          ? allContactsCount
          : selectedContactPhoneIds.length
      })`}
      handleClose={handleClose}
      handleBack={handleBack}
      handleConfirm={handleEnroll}
    >
      {isEmpty ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          height="100%"
          width="100%"
        >
          <SequencePreview sequence={sequence} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <Typography variant="body1" color="textSecondary">
              No current subscribers.
            </Typography>
            <Typography
              align="center"
              margin="1rem"
              variant="body1"
              color="textPrimary"
            >
              {delayText}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          className="search-results-wrapper"
          sx={{
            flex: "1 1 auto",
            overflowY: "scroll",
          }}
          id="scrollableList" // for <InfiniteScroll>
        >
          <InfiniteScroll
            dataLength={displayedContacts.length}
            hasMore={allContactsCount > displayedContacts.length}
            loader={isLoading ? renderLoadingBar() : null}
            next={() => {
              fetchNextPage();
            }}
            scrollableTarget="scrollableList"
          >
            <List
              sx={{
                flex: "1 1 auto",
                padding: 0,
                overflowY: "scroll",
              }}
            >
              <SequencePreview sequence={sequence} />
              {displayedContacts.map(
                ({
                  name,
                  contactPhoneId,
                  deliverabilityStatus,
                  formattedPhoneNumber,
                }) => {
                  return (
                    <ListItem key={contactPhoneId}>
                      <ListItemIcon>
                        <DeliverabilityStatus
                          deliverabilityStatus={deliverabilityStatus}
                        />
                      </ListItemIcon>

                      <ListItemText primary={name} />
                      <ListItemText
                        primary={formattedPhoneNumber}
                        sx={{
                          textAlign: "right",
                        }}
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </InfiniteScroll>
        </Box>
      )}

      {sequenceStepOne && !isEmpty && (
        <Alert
          severity="info"
          sx={{
            margin: "16px",
          }}
        >
          {alert}
        </Alert>
      )}
    </BaseDrawer>
  );
}
