import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import withRecord from "higherOrderComponents/withRecord";
import Table from "components/Table";
import { NoResultsScreen } from "components/Search/NoResultsScreen";

import getMomentWithTimezone from "utils/getMomentWithTimezone";

function CampaignCell({ name, id }) {
  return (
    <Box display="flex" alignItems="center" py="4px">
      <FormControlLabel
        value={`${name}-${id}`}
        control={<Radio color="secondary" />}
        label={name}
      />
    </Box>
  );
}

CampaignCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const collaspedColumns = [
  {
    title: "Group",
    getTableCellContent: CampaignCell,
  },
];

const columns = [
  {
    title: "Group",
    getTableCellContent: CampaignCell,
  },
  {
    title: "No. of Contacts",
    align: "right",
    getTableCellContent: (group) => {
      return get(group, ["contacts", "totalItems"]);
    },
  },
  {
    title: "Created At",
    align: "right",
    getTableCellContent: (group) => {
      return getMomentWithTimezone(get(group, ["createdAt"])).format(
        "L hh:mm a z",
      );
    },
  },
];

function GroupTable({
  groupCollection,
  groupFilter,
  setGroup,
  setGroupRecipients,
  campaignsResponsiveness,
}) {
  const [value, setValue] = React.useState("");
  const handleChange = ({ target }) => {
    const id = target.value.split("-").pop();
    const selectedGroup = groupCollection.members.find((group) => {
      return group.id === id;
    });
    const { contacts, name } = selectedGroup;
    setGroupRecipients(contacts.totalItems);
    setValue(target.value);
    setGroup({ groupName: name, groupId: id });
  };

  return (
    <RadioGroup
      aria-label="Group"
      name="group"
      value={value}
      onChange={handleChange}
    >
      <Table
        collection={groupCollection}
        collapsedColumns={collaspedColumns}
        columns={columns}
        dataTag={`GroupCollection-${groupFilter.slug}`}
        notExpandable
        blankState={
          campaignsResponsiveness ? (
            <NoResultsScreen source="groups" active />
          ) : null
        }
      />
    </RadioGroup>
  );
}

GroupTable.propTypes = {
  groupCollection: PropTypes.object.isRequired,
  groupFilter: PropTypes.object.isRequired,
  setGroup: PropTypes.func.isRequired,
  setGroupRecipients: PropTypes.func.isRequired,
  campaignsResponsiveness: PropTypes.bool,
};

export default withRecord({
  actions: ["fetch"],
  container:
    "features/Campaigns/containers/SendCampaign/NewCampaign/components/GroupTable",
  shape: { members: [{ account: {}, user: {}, contacts: { members: [] } }] },
  type: "groupCollection",
})(GroupTable);
