import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Root from "./components/Root";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

class ContactNotes extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/ContactSidebar/containers/ContactNoteCollection",
    shape: { members: [{ user: {} }] },
    type: "contactNoteCollection",
  }),
  withRecord({
    actions: ["create", "update", "delete"],
    container: "features/ContactSidebar/containers/ContactNote",
    type: "contactNote",
  }),
  withConnect,
)(ContactNotes);
