/* eslint-disable @typescript-eslint/no-floating-promises */
import PropTypes from "prop-types";
import { Formik, Field, Form, useFormikContext } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import MessageBodyVariants from "./MessageBodyVariants";
import { CampaignsProValidationSchema } from "formHelpers/validationSchemas";
import Loader from "components/Loader";
import Portal from "components/Portal";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";

function CampaignProForm({
  attachments,
  currentAccount,
  currentUser,
  disableNextButton,
  editCampaign,
  handleAddVariant,
  handleDeleteVariant,
  handleSubmit,
  messageTemplates,
  onFormChange,
  setShortenedLink,
  shortenedLink,
  title,
}) {
  const { campaignMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const initialValues = { attachments, messageTemplates, title };
  const root = document.getElementById("textus-NewCampaign-NextButton");

  function AutoUpdateParent() {
    const { values } = useFormikContext();

    useEffect(() => {
      // TODO: Remove this line after campaignsResponsive is live
      if (!onFormChange) return;

      onFormChange(values);
    }, [values]);

    return null;
  }

  const characterCountExceeded = ({ messageTemplates: templates }) => {
    return templates.find(({ body, signatureActive }) => {
      const signature = currentUser?.signature?.content ?? "";
      const totalLength = signatureActive
        ? signature.length + body.length
        : body.length;
      return totalLength > maxCharacterLength;
    });
  };

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      p="1.5rem"
      width="100%"
    >
      <Box display="flex" justifyContent="space-around" mb={2}>
        <Chip label="Campaigns Pro" color="primary" />
      </Box>
      <Typography
        align="center"
        component="h2"
        style={{ fontSize: "2.5rem" }}
        variant="h2"
      >
        Create Message
      </Typography>
      <Box m="1rem 0 2rem">
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          style={{ fontSize: "1rem" }}
        >
          Please enter an easy to identify title and the message for your
          campaign.
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          style={{ fontSize: "1rem" }}
        >
          You can create up to 3 messages that will be evenly sent to your
          recipient list.
        </Typography>
      </Box>
      <Box m="1rem auto" maxWidth="600px" p="0">
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={CampaignsProValidationSchema(maxCharacterLength)}
            validateOnMount={CampaignsProValidationSchema(maxCharacterLength)}
          >
            {({
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <Form id="textus-CampaignProForm">
                  <Field name="title" type="text">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          error={touched.title && !!errors.title}
                          fullWidth
                          helperText={touched.title && errors.title}
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          id="textus-Campaigns-title"
                          placeholder="Best Campaign Ever"
                          type="text"
                          variant="outlined"
                          style={{
                            marginBottom:
                              touched.title && errors.title ? "0" : "1.4rem",
                          }}
                        />
                      );
                    }}
                  </Field>
                  <MessageBodyVariants
                    currentAccount={currentAccount}
                    currentUser={currentUser}
                    editCampaign={editCampaign}
                    errors={errors}
                    handleAddVariant={handleAddVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    SavedRepliesOption={SavedRepliesOption}
                    setFieldValue={setFieldValue}
                    setShortenedLink={setShortenedLink}
                    shortenedLink={shortenedLink}
                    touched={touched}
                    values={values}
                  />
                  {root && (
                    <Portal root={root}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          characterCountExceeded(values) ||
                          disableNextButton
                        }
                        form="textus-CampaignProForm"
                        type="submit"
                        variant="contained"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  <AutoUpdateParent />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

CampaignProForm.propTypes = {
  attachments: PropTypes.array,
  currentAccount: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  disableNextButton: PropTypes.bool,
  editCampaign: PropTypes.bool,
  handleAddVariant: PropTypes.func.isRequired,
  handleDeleteVariant: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  messageTemplates: PropTypes.array,
  onFormChange: PropTypes.func,
  nextButton: PropTypes.any,
  setShortenedLink: PropTypes.func,
  shortenedLink: PropTypes.object,
  title: PropTypes.string,
};

export default CampaignProForm;
