import { createRef, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  SelectionState,
} from "draft-js";
import VariablePopover from "./VariablePopover";
import VariableSpan from "./VariableSpan";
import Signature from "components/Signature";
import ShortenedLinkModal from "components/MessageInputOptions/ShortenedLinkOption/ShortenedLinkModal";
import { swapLinksInBody } from "components/MessageInputOptions/ShortenedLinkOption/utils/swapLinksInBody";

const Wrapper = styled.div`
  width: 100%;
`;

// Does props.minHeight even exist? Leaving it in place for now just in case
// TODO: investigate when replacing styled-components with MUI
const EditorWrapper = styled.div`
  width: 100%;
  min-height: ${(props) => {
    return props.minHeight ?? "100px";
  }};
  padding: ${(props) => {
    return props.minHeight ? "0" : "0.75em";
  }};
  padding-left: 0.75em;

  .public-DraftEditorPlaceholder-inner {
    color: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
    pointer-events: none;
    position: absolute;
  }
`;

const SignatureWrapper = styled.div`
  width: 100%;
  max-height: 0;
  opacity: 0;
  transition: all linear 0.3s;
  visibility: hidden;

  &.isActive {
    max-height: 83px;
    opacity: 1;
    visibility: visible;
  }
`;

const getEntityStrategy = (type) => {
  return (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).getType() === type;
    }, callback);
  };
};

const cmdEnterKeybindingFn = (event) => {
  if (event.keyCode === 13 && KeyBindingUtil.hasCommandModifier(event)) {
    return "cmd-enter";
  }
  return getDefaultKeyBinding(event);
};

export default class DraftMessage extends Component {
  static propTypes = {
    conversationId: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleCmdEnter: PropTypes.func,
    isSignatureActive: PropTypes.bool,
    itemToAdd: PropTypes.object,
    minHeight: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    setShortenedLink: PropTypes.func,
    shortenedLink: PropTypes.object,
    signature: PropTypes.object,
    value: PropTypes.string,
    withSignature: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.editor = createRef();
    this.state = {
      anchorEl: null,
      popoverProps: {},
      editorState: this.getInitialEditorStateFromValue(props.value),
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.focus();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState(
        {
          editorState: this.getInitialEditorStateFromValue(this.props.value),
        },
        () => {
          return this.focus();
        },
      );
    }
    if (
      prevProps.conversationId &&
      prevProps.conversationId !== this.props.conversationId
    ) {
      this.focus();
    }
    if (!prevProps.itemToAdd && Boolean(this.props.itemToAdd)) {
      this.updateEditorWithInsert();
    } else if (
      prevProps.value !== "" &&
      this.state.editorState.getCurrentContent().getPlainText() !== "" &&
      this.props.value === ""
    ) {
      this.setState(
        {
          editorState: this.getInitialEditorStateFromValue(this.props.value),
        },
        () => {
          return this.focus();
        },
      );
    }
  };

  onChange = (editorState) => {
    this.setState({ editorState }, () => {
      this.props.handleChange(
        this.state.editorState.getCurrentContent().getPlainText(),
      );
    });
  };

  getContentStateFromValue = (value) => {
    let shortenedLinkContentBlock;
    let contentState = ContentState.createFromText(value ?? "");
    const contentBlocks = contentState.getBlocksAsArray();
    const { setShortenedLink, shortenedLink } = this.props;
    // iterate over the content blocks
    contentBlocks.forEach((contentBlock) => {
      const { key, text } = contentBlock;
      // create selection state for the content block
      const selectionState = SelectionState.createEmpty(key);

      // get the variable locations for the content block
      const entityRegEx = new RegExp(
        `({{[^}]*}})|(${shortenedLink?.shortLink})`,
        "gi",
      );
      const entityMatches = text.match(entityRegEx) || [];
      // eslint-disable-next-line unicorn/no-array-reduce
      const entityLocations = entityMatches.reduce((prev, match) => {
        const fromIndex = prev.length > 0 ? prev.at(-1).endOf : 0;
        const startOf = text.indexOf(match, fromIndex);
        return [...prev, { match, startOf, endOf: startOf + match.length }];
      }, []);
      // iterate over the variables to add them as entities
      entityLocations.forEach(({ match, startOf, endOf }) => {
        /* 
        extract full link from content to set shortened link state 
        and reassign `match` to enable short link modal 
        */
        if (match.includes("shortlink")) {
          shortenedLinkContentBlock = match;
          const extractedFullLink = match
            .match(/(?:"[^"]*"|^[^"]*$)/)[0]
            .replaceAll('"', "");
          setShortenedLink({ ...shortenedLink, fullLink: extractedFullLink });
          // eslint-disable-next-line no-param-reassign
          match = shortenedLink.shortLink;
        }
        // highlight entity
        const entitySelectionState = selectionState.merge({
          anchorOffset: startOf,
          focusOffset: endOf,
        });
        contentState = contentState.createEntity("VARIABLE", "IMMUTABLE", {
          content: match,
          disabled: this.props.disabled,
          handleCloseVariablePopover: this.handleCloseVariablePopover,
          handleDelete: this.handleEntityDelete,
          handleEntityUpdate: this.handleEntityUpdate,
          handleOpenVariablePopover: this.handleOpenVariablePopover,
          toggleShortLinkModal: this.toggleShortLinkModal,
          initialSelection: entitySelectionState,
          isShortLink: match.includes(this.props.shortenedLink?.shortLink),
          shortenedLink: this.props.shortenedLink,
          setShortenedLink: this.props.setShortenedLink,
        });
        const entityKey = contentState.getLastCreatedEntityKey();
        // update the editor with that entity applied to the content
        contentState = Modifier.applyEntity(
          contentState,
          entitySelectionState,
          entityKey,
        );
      });
    });
    return { contentState, shortenedLinkContentBlock };
  };

  getInitialEditorStateFromValue = (value) => {
    const { shortenedLink } = this.props;
    const { contentState, shortenedLinkContentBlock } =
      this.getContentStateFromValue(value);
    const updatedContentState = shortenedLinkContentBlock
      ? this.getContentStateFromValue(
          swapLinksInBody(
            shortenedLinkContentBlock,
            shortenedLink.shortLink,
            value,
          ),
        ).contentState
      : contentState;
    const decorator = new CompositeDecorator([
      {
        strategy: getEntityStrategy("VARIABLE"),
        component: VariableSpan,
      },
    ]);
    return EditorState.createWithContent(updatedContentState, decorator);
  };

  handleOpenVariablePopover = (event, popoverProps) => {
    this.setState({ anchorEl: event.currentTarget, popoverProps });
  };

  handleCloseVariablePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleEntityDelete = (selection) => {
    // get current content state
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();

    // remove entity
    const contentStateWithoutEntity = Modifier.applyEntity(
      contentState,
      selection,
      null,
    );

    // remove text for the entity
    const contentStateWithoutText = Modifier.removeRange(
      contentStateWithoutEntity,
      selection,
      null,
    );
    const updatedEditorState = EditorState.push(
      editorState,
      contentStateWithoutText,
      "remove-entity",
    );

    // force the caret position to be at the beginning of where the entity was
    const finalEditorState = EditorState.forceSelection(
      updatedEditorState,
      updatedEditorState
        .getSelection()
        .merge({ anchorOffset: selection.get("anchorOffset") }),
    );
    this.focus();

    // trigger state change
    this.onChange(finalEditorState);
  };

  handleEntityUpdate = (selection, entityKey) => {
    return (revisedText) => {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const updatedContentState = Modifier.replaceText(
        contentState,
        selection,
        revisedText,
        null,
        entityKey,
      );
      const updatedEditorState = EditorState.push(
        editorState,
        updatedContentState,
        "modified-entity",
      );
      this.onChange(updatedEditorState);
    };
  };

  handleKeyCommand = (command) => {
    const { handleCmdEnter } = this.props;
    if (Boolean(handleCmdEnter) && command === "cmd-enter") {
      handleCmdEnter();
      return "handled";
    }
    return "not-handled";
  };

  toggleShortLinkModal = () => {
    return this.setState((prevState) => {
      return {
        ...prevState,
        modalOpen: !prevState.modalOpen,
      };
    });
  };

  focus = () => {
    return this.editor.current?.focus();
  };

  insertSavedReply = (savedReplyContent) => {
    const { shortenedLink } = this.props;
    const { contentState, shortenedLinkContentBlock } =
      this.getContentStateFromValue(savedReplyContent);
    const { editorState } = this.state;
    const updatedContentState = shortenedLinkContentBlock
      ? this.getContentStateFromValue(
          swapLinksInBody(
            shortenedLinkContentBlock,
            shortenedLink.shortLink,
            savedReplyContent,
          ),
        ).contentState
      : contentState;
    const updatedEditorState = EditorState.push(
      editorState,
      updatedContentState,
      "insert-saved-reply",
    );
    const lastBlock = contentState.getLastBlock();
    const selectionState = SelectionState.createEmpty(lastBlock.getKey()).merge(
      {
        anchorOffset: lastBlock.getLength(),
        focusOffset: lastBlock.getLength(),
      },
    );
    this.setState(
      {
        editorState: EditorState.forceSelection(
          updatedEditorState,
          selectionState,
        ),
      },
      () => {
        this.focus();
        this.props.handleChange(
          this.state.editorState.getCurrentContent().getPlainText(),
        );
      },
    );
  };

  updateEditorWithInsert = () => {
    const { itemToAdd } = this.props;
    const { item, type } = itemToAdd;

    if (type === "savedReply") {
      this.insertSavedReply(item);
      return;
    }

    // get current editor state
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // update editor with inserted item as plain text
    const contentStateWithText = Modifier.replaceText(
      contentState,
      selectionState,
      item,
    );
    const updatedEditorState = EditorState.push(
      editorState,
      contentStateWithText,
      "inserted-characters",
    );

    // get update editor state
    const updatedContentState = updatedEditorState.getCurrentContent();
    const updatedSelectionState = updatedEditorState.getSelection();

    // highlight the inserted content
    const addedSelection = updatedSelectionState.merge({
      anchorOffset: selectionState.getStartOffset(),
      focusOffset: selectionState.getStartOffset() + item.length,
    });

    let finalEditorState = updatedEditorState;
    if (type === "templateVariable" || type === "shortenedLink") {
      // create an entity out of the inserted content
      const contentStateWithEntity = updatedContentState.createEntity(
        "VARIABLE",
        "IMMUTABLE",
        {
          content: item,
          handleCloseVariablePopover: this.handleCloseVariablePopover,
          handleDelete: this.handleEntityDelete,
          handleEntityUpdate: this.handleEntityUpdate,
          handleOpenVariablePopover: this.handleOpenVariablePopover,
          toggleShortLinkModal: this.toggleShortLinkModal,
          shortenedLink: this.props.shortenedLink,
          setShortenedLink: this.props.setShortenedLink,
          isShortLink: item.includes(this.props.shortenedLink?.shortLink),
        },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      // update the editor with that entity applied to the content
      const contentStateWithAppliedEntity = Modifier.applyEntity(
        contentStateWithEntity,
        addedSelection,
        entityKey,
      );
      const editorStateWithEntity = EditorState.push(
        updatedEditorState,
        contentStateWithAppliedEntity,
        "create-entity",
      );
      finalEditorState = editorStateWithEntity;
    }

    // modify the selection to put the cursor after the inserted item
    const finalSelection = addedSelection.merge({
      anchorOffset: addedSelection.getEndOffset(),
    });

    // update the component's state with final editor state
    // and propogate the change to the form
    this.setState(
      {
        editorState: EditorState.forceSelection(
          finalEditorState,
          finalSelection,
        ),
      },
      () => {
        this.focus();
        this.props.handleChange(
          this.state.editorState.getCurrentContent().getPlainText(),
        );
      },
    );
  };

  // The popover needs to be outside of the Editor to avoid focus/blur collisions
  render() {
    const { modalOpen } = this.state;
    const {
      isSignatureActive,
      minHeight,
      name,
      onBlur,
      onFocus,
      placeholder,
      setShortenedLink,
      shortenedLink,
      signature,
      withSignature,
      disabled,
    } = this.props;

    return (
      <>
        <ShortenedLinkModal
          handleToggle={this.toggleShortLinkModal}
          shortenedLink={shortenedLink}
          open={modalOpen}
          setShortenedLink={setShortenedLink}
        />
        <VariablePopover
          anchorEl={this.state.anchorEl}
          {...this.state.popoverProps}
        />
        <Wrapper>
          <EditorWrapper onClick={this.focus} minHeight={minHeight}>
            <Editor
              onFocus={onFocus}
              onBlur={(event) => {
                /* eslint-disable no-param-reassign */
                event.target.name = name;
                onBlur(event);
              }}
              readOnly={disabled}
              ref={this.editor}
              editorState={this.state.editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={cmdEnterKeybindingFn}
              onChange={this.onChange}
              placeholder={placeholder}
              spellCheck
              style={{ minHeight: "100px" }}
              stripPastedStyles
              ariaLabel={placeholder}
            />
          </EditorWrapper>
          {withSignature && (
            <SignatureWrapper className={isSignatureActive && "isActive"}>
              <Signature
                maxCharCount={120}
                signature={signature}
                updatedSignatureContent={() => {
                  //
                }}
              />
            </SignatureWrapper>
          )}
        </Wrapper>
      </>
    );
  }
}
