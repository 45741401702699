import { compose } from "redux";
import { Box, Button } from "@mui/material";
import { CampaignOverviewCollection } from "../CampaignOverviewCollection";
import { CampaignCollection } from "../CampaignCollection";
import { CampaignFilterProps } from "./types";
import withRecord from "higherOrderComponents/withRecord";
import { campaignFilterSlugs } from "features/Campaigns/constants";
import CampaignAnalytics from "features/Campaigns/containers/CampaignAnalytics";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import { useCampaignExport } from "features/Campaigns/hooks";

function CampaignFilter({
  campaignFilter,
  currentAccount,
  toggleSidebar,
}: CampaignFilterProps) {
  // ==== FIELDS ==== //
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;
  const baseId = getNestedId(campaignFilter, "campaigns");
  const campaignCollectionId = getPaginatedId(baseId);
  const { slug, title } = campaignFilter;

  // ==== HOOKS ==== //
  const { isLoading, handleExport } = useCampaignExport(campaignFilter);

  // ==== RENDER ==== //
  return (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
      margin="0 auto"
      position="relative"
      color="text.primary"
    >
      <PageHeader title={title} toggleSidebar={toggleSidebar}>
        <Box flex="0 0 auto" pr="10px">
          <Button
            data-test="ExportCampaignFilterButton"
            color="primary"
            onClick={handleExport}
            variant="outlined"
          >
            <Loader isLoading={isLoading}>Export Data</Loader>
          </Button>
        </Box>
      </PageHeader>
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        position="relative"
        sx={{ overflowX: "auto" }}
      >
        {campaignsResponsiveness ? null : (
          <CampaignAnalytics
            campaignAnalyticsId={`${currentAccount.campaigns}/analytics`}
          />
        )}
        {campaignsResponsiveness ? (
          <Box aria-label={title}>
            <CampaignOverviewCollection
              campaignFilter={campaignFilter}
              ariaLabel="ariaLabel"
              campaignCollectionId={`${currentAccount.campaignFilters}/${slug}/campaigns`}
              currentAccount={currentAccount}
              displayCampaignType
              emptyState="No campaigns scheduled"
              header={title}
              showAvatar={slug !== campaignFilterSlugs.myScheduled}
              key={slug}
              sendVerbiage="Sending"
            />
          </Box>
        ) : (
          <CampaignCollection
            campaignCollectionId={campaignCollectionId}
            currentAccount={currentAccount}
          />
        )}
      </Box>
    </Box>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "Campaigns/containers/CampaignFilter",
    shape: { campaigns: {} },
    type: "campaignFilter",
  }),
)(CampaignFilter);
