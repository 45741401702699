import { Breadcrumbs, Typography, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { BreadcrumbLinksProps } from "./types";

const StyledReturnLink = styled(NavLink)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textDecoration: "none",
    ":hover": { textDecoration: "underline" },
    "&.active": {
      color: theme.palette.primary.main,
    },
  };
});

function BreadcrumbLinks({ returnPath, sequenceName }: BreadcrumbLinksProps) {
  return (
    <Breadcrumbs aria-label="Sequences breadcrumbs">
      <StyledReturnLink to={returnPath}>Sequences</StyledReturnLink>
      <Typography
        fontSize="1rem"
        lineHeight="1.5rem"
        color="primary"
        fontWeight={800}
      >
        {sequenceName}
      </Typography>
    </Breadcrumbs>
  );
}

export { BreadcrumbLinks };
