import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Box, Button, Chip, Grid, IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/VpnKey";
import Webhook from "./Webhook";
import { Providers } from "./constants";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import PageHeader from "components/Page/PageHeader";
import Modal from "components/Modal";
import H4 from "components/H4";
import breakpoints from "utils/styles/breakpoints";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

const WebhookRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  &:first-of-type {
    margin-top: 20px;
  }

  &:not(:first-of-type) {
    border-top: solid 1px
      ${(props) => {
        return props.theme.colors.divider;
      }};
  }
`;

const WebhookDescription = styled.p``;

const WebhookStatus = styled(Switch)`
  margin-left: -12px;
`;

const WebhookDetails = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin: 0 20px;

  @media (min-width: ${breakpoints.medium}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const WebhookURL = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`;

const WebhookSecret = styled.p`
  white-space: nowrap;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  background: ${(props) => {
    return props.theme.colors.divider;
  }};
  border-radius: 1em;

  svg {
    margin-right: 10px;
  }

  @media (min-width: ${breakpoints.medium}px) {
    margin-left: 20px;
  }
`;

const AddWebhookButton = styled(Button)`
  margin-top: 20px !important;
  float: right;
`;

class Root extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
    addNotification: PropTypes.func.isRequired,
    createIntegrationRequest: PropTypes.func.isRequired,
    deleteIntegrationRequest: PropTypes.func.isRequired,
    fetchIntegrationCollectionRequest: PropTypes.func.isRequired,
    integrationCollection: PropTypes.object.isRequired,
    integrationCollectionId: PropTypes.string.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    updateIntegrationRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showModal: {},
    };
    this.providers = Providers.filter((provider) => {
      if (provider.id === "greenhouse") {
        return this.props.account.featureFlags.greenhouseV3;
      }

      return true;
    }).map((provider) => {
      return {
        ...provider,
        openModal: this.toggleProviderModal(provider.id, true),
        closeModal: this.toggleProviderModal(provider.id, false),
      };
    });
    this.providers.forEach((provider) => {
      this.state.showModal[provider.id] = false;
    });
  }

  errorCallback = ({ action = "saving" }) => {
    this.props.addNotification({
      message: `There was an error ${action} your integration`,
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
        variant: "error",
      },
    });
  };

  successCallback = ({ action = "saved" }) => {
    this.props.fetchIntegrationCollectionRequest(
      this.props.integrationCollectionId,
    );
    this.props.addNotification({
      message: `Your integration ${action} successfully!`,
      options: {
        action: (key) => {
          return <SnackbarCloseButton snackbarKey={key} />;
        },
      },
    });
  };

  toggleProviderModal = (providerId, value) => {
    return () => {
      this.setState((prevState) => {
        return {
          ...prevState,
          showModal: {
            ...prevState.showModal,
            [providerId]: value,
          },
        };
      });
    };
  };

  handleSubmit = ({ provider, config, settings }, shouldClose = true) => {
    if (shouldClose) {
      provider.closeModal();
    }

    const callbacks = {
      successCallback: this.successCallback,
      errorCallback: this.errorCallback,
    };

    return provider.integration
      ? this.props.updateIntegrationRequest(
          provider.integration.id,
          {
            config,
            settings,
          },
          callbacks,
        )
      : this.props.createIntegrationRequest(
          this.props.integrationCollectionId,
          {
            provider: provider.id,
            active: true,
            config,
            settings,
          },
          callbacks,
        );
  };

  toggleActive = ({ provider }) => {
    if (!provider.integration) {
      provider.openModal();
      return false;
    }
    provider.closeModal();
    /*
      Greenhouse has it's own flow here becuase we wnt to enable contact schemas when the integration is enabled, 
      and disable them when the integration is disabled. 
      This is the only integration where this happens, given the conditional flow here.
    */
    if (provider.id === "greenhouse") {
      const payloadData = provider.integration.settings.contact_schemas_enabled
        ? {
            active: !provider.integration.active,
            settings: {
              contact_schemas_enabled:
                !provider.integration.settings.contact_schemas_enabled,
            },
          }
        : {
            active: !provider.integration.active,
            settings: {
              contact_schemas_enabled: true,
            },
          };
      return this.props.updateIntegrationRequest(
        provider.integration.id,
        payloadData,
        {
          successCallback: this.successCallback,
          errorCallback: this.errorCallback,
        },
      );
    }
    return this.props.updateIntegrationRequest(
      provider.integration.id,
      {
        active: !provider.integration.active,
      },
      {
        successCallback: this.successCallback,
        errorCallback: this.errorCallback,
      },
    );
  };

  getWebhookProviders = () => {
    const providers = this.props.integrationCollection.members
      .filter((integration) => {
        return integration.provider === "webhook";
      })
      .map((integration) => {
        return {
          id: integration.id,
          name: "Webhook",
          integration,
          openModal: this.toggleProviderModal(integration.id, true),
          closeModal: this.toggleProviderModal(integration.id, false),
        };
      });
    providers.push({
      id: "webhook",
      name: "Webhook",
      component: Webhook,
      openModal: this.toggleProviderModal("webhook", true),
      closeModal: this.toggleProviderModal("webhook", false),
    });
    return providers;
  };

  deleteIntegration = (provider) => {
    return () => {
      // eslint-disable-next-line no-alert
      if (!window.confirm("This webhook will be permanently deleted.")) return;
      this.props.deleteIntegrationRequest(
        provider.integration.id,
        {},
        {
          successCallback: () => {
            this.successCallback({ action: "deleted" });
          },
          errorCallback: () => {
            this.errorCallback({ action: "deleting" });
          },
        },
      );
    };
  };

  render() {
    const {
      account,
      appSettings,
      deleteIntegrationRequest,
      fetchIntegrationCollectionRequest,
      integrationCollection,
      toggleSidebar,
    } = this.props;
    const webhookProviders = this.getWebhookProviders();
    return (
      <SettingsPageWrapper>
        <PageHeader title="Integrations" toggleSidebar={toggleSidebar} />
        <Grid
          sx={{
            overflowY: "auto",
          }}
        >
          <Grid
            sx={(theme) => {
              return {
                display: "grid",
                gap: 2,
                gridAutoRows: "1fr",
                justifyContent: "center",
                margin: "auto",
                maxWidth: "100%",
                padding: 2,
                [theme.breakpoints.up("sm")]: {
                  gridTemplateColumns: "repeat(auto-fill, 232px)",
                },
                [theme.breakpoints.up("md")]: {
                  gridTemplateColumns: "repeat(auto-fill, 256px)",
                },
                [theme.breakpoints.up("lg")]: {
                  paddingY: 4,
                  maxWidth: "1040px",
                },
                [theme.breakpoints.up("xl")]: {
                  maxWidth: "1420px",
                  gridTemplateColumns: "repeat(auto-fill, 322px)",
                },
              };
            }}
          >
            {this.providers.map((provider) => {
              const ProviderComponent = provider.component;
              // eslint-disable-next-line no-param-reassign
              provider.integration = integrationCollection.members.find(
                (integration) => {
                  return integration.provider === provider.id;
                },
              );
              return (
                <Fragment key={provider.id}>
                  <Box
                    component="button"
                    aria-label={`Open ${provider.name}`}
                    onClick={provider.openModal}
                    sx={{
                      marginTop: "auto",
                      width: "100%",
                      height: "100%",
                      minHeight: "132px",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      position: "relative",
                      padding: 2,
                    }}
                  >
                    <Grid
                      display="flex"
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingRight: "58px",
                      }}
                    >
                      {provider.developmentStage && (
                        <Chip
                          size="small"
                          color="secondary"
                          variant="outlined"
                          label={provider.developmentStage}
                          sx={{
                            textTransform: "uppercase",
                          }}
                        />
                      )}
                      <Box
                        height="108"
                        width="500"
                        component="img"
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={provider.logo}
                        alt={provider.name}
                      />
                    </Grid>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        display: "grid",
                        placeContent: "center",
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Switch
                        checked={get(provider.integration, "active", false)}
                        onClick={() => {
                          this.toggleActive({ provider });
                        }}
                        inputProps={{
                          "aria-label": `toggle ${provider.name}`,
                        }}
                        color="secondary"
                      />
                    </Box>
                  </Box>
                  <Modal
                    transitionIn={this.state.showModal[provider.id]}
                    closeModal={provider.closeModal}
                  >
                    <ProviderComponent
                      account={account}
                      appSettings={appSettings}
                      deleteIntegrationRequest={deleteIntegrationRequest}
                      fetchIntegrationCollectionRequest={
                        fetchIntegrationCollectionRequest
                      }
                      handleSubmit={this.handleSubmit}
                      provider={provider}
                      toggleActive={this.toggleActive}
                    />
                  </Modal>
                </Fragment>
              );
            })}
          </Grid>
          <Grid
            sx={{
              maxWidth: "1040px",
            }}
          >
            <H4>Webhooks</H4>
            {webhookProviders.length === 1 && (
              <WebhookDescription>
                Webhooks allow you to collect information about events in near
                real-time. Simply add a URL below to receive inbound and
                outbound messages as they are sent.
              </WebhookDescription>
            )}
            {webhookProviders.map((provider) => {
              return (
                <Fragment key={provider.id}>
                  {provider.integration && (
                    <WebhookRow>
                      <WebhookStatus
                        checked={get(provider.integration, "active", false)}
                        onClick={(event) => {
                          this.toggleActive({ provider });
                          event.stopPropagation();
                        }}
                      />
                      <WebhookDetails>
                        <WebhookURL>
                          {provider.integration.config.baseUrl}
                        </WebhookURL>
                        <WebhookSecret>
                          <KeyIcon fontSize="small" />
                          {provider.integration.config.webhookSecret}
                        </WebhookSecret>
                      </WebhookDetails>
                      <IconButton onClick={provider.openModal} size="large">
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={this.deleteIntegration(provider)}
                        size="large"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </WebhookRow>
                  )}
                  <Modal
                    transitionIn={this.state.showModal[provider.id]}
                    closeModal={provider.closeModal}
                  >
                    <Webhook
                      account={account}
                      deleteIntegrationRequest={deleteIntegrationRequest}
                      fetchIntegrationCollectionRequest={
                        fetchIntegrationCollectionRequest
                      }
                      handleSubmit={this.handleSubmit}
                      provider={provider}
                      toggleActive={this.toggleActive}
                    />
                  </Modal>
                </Fragment>
              );
            })}
            <AddWebhookButton
              variant="contained"
              color="primary"
              onClick={this.toggleProviderModal("webhook", true)}
            >
              Add webhook
            </AddWebhookButton>
          </Grid>
        </Grid>
      </SettingsPageWrapper>
    );
  }
}

export default Root;
