import { createRef, Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import debounce from "lodash/debounce";
import difference from "lodash/difference";
import get from "lodash/get";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";
import orderBy from "lodash/orderBy";

import { rgba } from "polished";
import classNames from "classnames";
import queryString from "query-string";

import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Logo from "components/Logo";
import BlankState from "components/BlankState";
import PageLoader from "components/PageLoader";
import { ROWS_PER_PAGE } from "constants/defaults";

const TableSection = styled.div`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
`;

const HorizontalScrollIndicator = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  pointer-events: none;
  background: linear-gradient(
    to right,
    ${(props) => {
      return rgba(props.theme.colors.background.paper, 0);
    }},
    ${(props) => {
      return props.theme.colors.background.paper;
    }}
  );
  z-index: 10;

  svg {
    animation: bounce 10s infinite;
  }

  @keyframes bounce {
    0%,
    10%,
    20%,
    30% {
      transform: translateX(0%);
    }

    5%,
    15%,
    25% {
      transform: translateX(-80%);
    }

    100% {
      transform: translateX(0%);
    }
  }
`;

class Root extends Component {
  debouncedUpdateDivider = debounce(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    (event) => {
      const { showColumnDivider } = this.state;
      const { scrollLeft } = event.target;
      if (showColumnDivider && scrollLeft === 0) {
        this.setState({ showColumnDivider: false });
      }
      if (!showColumnDivider && scrollLeft > 0) {
        this.setState({ showColumnDivider: true });
      }
    },
    100,
    { leading: true },
  );

  static propTypes = {
    blankState: PropTypes.any,
    classes: PropTypes.object.isRequired,
    collapsedColumns: PropTypes.array,
    collection: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    fromContactSearch: PropTypes.bool,
    handleChangePage: PropTypes.func,
    history: PropTypes.object.isRequired,
    horizontalScrollIndicator: PropTypes.bool,
    orderBy: PropTypes.string,
    paginationStyles: PropTypes.object,
    page: PropTypes.number,
    selected: PropTypes.array,
    selectedRecords: PropTypes.array,
    setSelected: PropTypes.func,
    setSelectedRecords: PropTypes.func,
    showExpandButton: PropTypes.bool,
    notExpandable: PropTypes.bool,
    withBatchActions: PropTypes.bool,
    withoutPagination: PropTypes.bool,
    resetToTop: PropTypes.bool,
    theme: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.Wrapper = createRef();
    this.Table = createRef();

    this.state = {
      expanded: !props.showExpandButton,
      showColumnDivider: false,
      showHorizontalScrollIndicator: false,
    };
  }

  componentDidMount() {
    if (this.Wrapper && this.props.horizontalScrollIndicator) {
      const { scrollWidth, offsetWidth } = this.Wrapper.current;

      if (offsetWidth < scrollWidth) {
        this.setState({ showHorizontalScrollIndicator: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { showExpandButton } = this.props;
    if (prevProps.showExpandButton !== showExpandButton) {
      this.setState({ expanded: !showExpandButton });
    }
  }

  getAddedCellStyle = (column) => {
    const { showExpandButton, withBatchActions } = this.props;
    if (!showExpandButton || !column.primary) return {};
    return withBatchActions
      ? { left: "64px", minWidth: "120px", width: "120px" }
      : { left: "0px", minWidth: "200px", width: "200px" };
  };

  convertIdToUrl = ({ id, page }) => {
    const { history } = this.props;
    const { search } = history.location;
    const searchParams = queryString.parse(search);
    const {
      query: { cursor },
    } = queryString.parseUrl(id);
    const queryParams = {
      ...searchParams,
      cursor,
      page,
    };
    return {
      ...history.location,
      search: `?${queryString.stringify(queryParams)}`,
    };
  };

  handleChangePage = (event, page) => {
    const { collection, handleChangePage, history, resetToTop } = this.props;

    if (handleChangePage) {
      return handleChangePage(event, page);
    }

    let { page: currentPage = "0" } = queryString.parse(
      history.location.search,
    );

    if (resetToTop) {
      // Scroll to top of table after page change.
      this.Table.current.scrollIntoView();
    }

    currentPage = Number.parseInt(currentPage, 10);
    if (currentPage > page) {
      return history.goBack();
    }

    const nextId = get(collection, ["view", "next"]);
    if (nextId) {
      return history.push(this.convertIdToUrl({ id: nextId, page }));
    }

    return null;
  };

  handleToggle = (record) => {
    return () => {
      const { selected, selectedRecords, setSelected, setSelectedRecords } =
        this.props;

      const nextSelected = selected.includes(record.id)
        ? selected.filter((id) => {
            return id !== record.id;
          })
        : [...selected, record.id];

      if (selectedRecords && setSelectedRecords) {
        const isSelected =
          selectedRecords?.some((selectedRecord) => {
            return selectedRecord.id === record.id;
          }) ?? false;

        const nextSelectedWithData = isSelected
          ? selectedRecords.filter((selectedRecord) => {
              return selectedRecord.id !== record.id;
            })
          : [...selectedRecords, record];

        setSelectedRecords(nextSelectedWithData);
      }

      setSelected(nextSelected);
    };
  };

  handleScroll = (event) => {
    event.persist();
    this.debouncedUpdateDivider(event);

    if (this.Wrapper && this.Wrapper.current) {
      const { scrollLeft } = this.Wrapper.current;

      if (scrollLeft > 100 && this.state.showHorizontalScrollIndicator) {
        this.setState({ showHorizontalScrollIndicator: false });
      }

      if (scrollLeft < 100 && !this.state.showHorizontalScrollIndicator) {
        this.setState({ showHorizontalScrollIndicator: true });
      }
    }
  };

  getToggleAllVisibleSelection = () => {
    const {
      selected: currentlySelectedIds,
      collection: { members = [] },
    } = this.props;

    const visibleIds = members.map((record) => {
      return record.id;
    });

    // if no selections, select all current visible ids
    const noCurrentlySelectedIds = currentlySelectedIds.length === 0;
    if (noCurrentlySelectedIds) return visibleIds;

    const visibleIdsCurrentlySelected = intersection(
      currentlySelectedIds,
      visibleIds,
    );

    const areAllVisibleIdsCurrentlySelected =
      visibleIdsCurrentlySelected.length === visibleIds.length;

    if (areAllVisibleIdsCurrentlySelected) {
      return difference(currentlySelectedIds, visibleIds);
    }

    return union(currentlySelectedIds, visibleIds);
  };

  getToggleAllVisibleSelectedRecords = () => {
    const {
      selectedRecords,
      collection: { members = [] },
    } = this.props;

    const noneSelected = selectedRecords.length === 0;
    if (noneSelected) {
      return members;
    }

    const selectedVisibleRecords = selectedRecords.filter((record) => {
      return members.find((member) => {
        return member.id === record.id;
      });
    });

    const allRecordsSelected = selectedVisibleRecords.length === members.length;
    if (allRecordsSelected) {
      return selectedRecords.filter((record) => {
        return !members.some((member) => {
          return member.id === record.id;
        });
      });
    }

    return [...new Set([...selectedRecords, ...members])];
  };

  handleMemberSort = (members) => {
    if (this.props.fromContactSearch) {
      return members;
    }
    return this.props.orderBy
      ? orderBy(members, this.props.orderBy, "asc")
      : members.sort((a, b) => {
          return a.firstName?.localeCompare(b.firstName);
        });
  };

  handleToggleAllVisible = () => {
    const { setSelected, setSelectedRecords } = this.props;
    const updatedSelection = this.getToggleAllVisibleSelection();
    setSelected(updatedSelection);

    if (setSelectedRecords) {
      const updatedRecordSelection = this.getToggleAllVisibleSelectedRecords();
      setSelectedRecords(updatedRecordSelection);
    }
  };

  handleToggleExpand = () => {
    this.setState((prevState) => {
      return { expanded: !prevState.expanded };
    });
  };

  getPage = () => {
    const { currentPage } = this.props;
    if (currentPage) return currentPage;
    const { page = "0" } = queryString.parse(document.location.search);
    return Number.parseInt(page, 10);
  };

  render() {
    const {
      blankState,
      classes,
      collapsedColumns,
      collection,
      columns,
      selected,
      showExpandButton,
      notExpandable,
      theme,
      paginationStyles = {},
      page,
      withBatchActions,
      withoutPagination,
    } = this.props;
    const { expanded, showColumnDivider } = this.state;
    const { members: collectionMembers = [] } = collection;
    const collectionMemberIds = collectionMembers.map((record) => {
      return record.id;
    });
    const selectedIntersection = intersection(selected, collectionMemberIds);
    const visibleColumns =
      !expanded && collapsedColumns ? collapsedColumns : columns;
    const rowsPerPage = ROWS_PER_PAGE;
    const currentPage = page || this.getPage();
    const count = get(this.props, ["collection", "totalItems"], 0);
    const orderedMembers = this.handleMemberSort(collectionMembers);
    return (
      <>
        <TableSection onScroll={this.handleScroll} ref={this.Wrapper}>
          {this.state.showHorizontalScrollIndicator &&
            this.props.horizontalScrollIndicator && (
              <HorizontalScrollIndicator>
                <ChevronRightIcon style={{ fontSize: 26 }} />
              </HorizontalScrollIndicator>
            )}
          <Table
            ref={this.Table}
            style={{
              minWidth: expanded
                ? `${
                    visibleColumns.length * 120 + (withBatchActions ? 64 : 0)
                  }px`
                : "100%",
            }}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {withBatchActions && (
                  <TableCell
                    padding="checkbox"
                    className={classNames(classes.head, classes.headCheckbox)}
                    style={
                      selected.length > 0
                        ? {
                            backgroundColor:
                              theme.colors.message.outboundBackground,
                          }
                        : {}
                    }
                  >
                    <Checkbox
                      color="secondary"
                      indeterminate={
                        selected.length > 0 &&
                        selectedIntersection.length < collectionMemberIds.length
                      }
                      checked={
                        selected.length > 0 &&
                        selectedIntersection.length ===
                          collectionMemberIds.length
                      }
                      onChange={this.handleToggleAllVisible}
                      inputProps={{ "aria-label": "Select all visible" }}
                    />
                  </TableCell>
                )}
                {visibleColumns.map((column) => {
                  return (
                    <TableCell
                      key={column.title}
                      className={classNames(classes.head, {
                        [classes.headPrimary]: Boolean(column.primary),
                        [classes.headWithDivider]:
                          Boolean(column.primary) && showColumnDivider,
                      })}
                      align={column.align}
                      style={this.getAddedCellStyle(column)}
                    >
                      {column.titleContent || column.title}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderedMembers.map((record) => {
                return (
                  <TableRow
                    key={record.id}
                    aria-label="Table Row"
                    data-testid="table-row"
                  >
                    {withBatchActions && (
                      <TableCell
                        padding="checkbox"
                        className={(classes.cell, classes.cellCheckbox)}
                      >
                        <Checkbox
                          color="secondary"
                          checked={selected.includes(record.id)}
                          onClick={this.handleToggle(record)}
                          inputProps={{
                            "aria-label": "Select item",
                            "data-testid": "select-item",
                          }}
                        />
                      </TableCell>
                    )}
                    {visibleColumns.map((column) => {
                      return (
                        <TableCell
                          aria-label="Table Cell Selection"
                          data-testid="table-cell-selection"
                          key={`${record.id}-${column.title}`}
                          className={classNames(classes.cell, {
                            [classes.cellNowrap]: Boolean(column.nowrap),
                            [classes.cellPrimary]: Boolean(column.primary),
                            [classes.cellWithDivider]:
                              Boolean(column.primary) && showColumnDivider,
                          })}
                          align={column.align}
                          style={this.getAddedCellStyle(column)}
                        >
                          {column.getTableCellContent(record)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableSection>
        {isEmpty(collection) && collectionMembers.length === 0 && (
          <PageLoader />
        )}
        {!isEmpty(collection) &&
          collectionMembers.length === 0 &&
          (blankState || (
            <BlankState
              image={<Logo color="disabled" />}
              title="No records to display"
            />
          ))}
        {!withoutPagination && Boolean(count) && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            slotProps={{
              previousButton: {
                "aria-label": "Previous Page",
              },
              nextButton: {
                "aria-label": "Next Page",
              },
            }}
            onPageChange={this.handleChangePage}
            style={{ flexShrink: 0, width: "100%", ...paginationStyles }}
          />
        )}
        {showExpandButton && !notExpandable && collapsedColumns && (
          <Fab
            color="primary"
            aria-label="Show More"
            className={classes.fab}
            size="small"
            style={{
              position: "absolute",
              right: "1rem",
            }}
            onClick={this.handleToggleExpand}
          >
            {expanded ? (
              <UnfoldLessIcon className={classes.icon} />
            ) : (
              <UnfoldMoreIcon className={classes.icon} />
            )}
          </Fab>
        )}
      </>
    );
  }
}

export default withTheme(Root);
