import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { darken, setSaturation } from "polished";
import stringToColor from "string-to-color";
import MuiAvatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Loader from "components/Loader";
import userAvatarUrl from "utils/userAvatarUrl";

export default class Avatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    size: PropTypes.number,
    subject: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    tooltip: PropTypes.string,
    fontSize: PropTypes.string,
    sx: PropTypes.object,
  };

  static defaultProps = {
    size: 33,
    fontSize: "1rem",
  };

  isType = (string) => {
    const type = get(this.props.subject, ["@type"], "");
    return type.includes(string);
  };

  isUser = () => {
    return this.isType("User");
  };

  isAccount = () => {
    return this.isType("Account");
  };

  textContent = () => {
    const { subject } = this.props;
    if (this.isUser()) {
      const names = subject.name.split(" ");
      return `${names[0].charAt(0)}${names.at(-1).charAt(0)}`;
    }
    if (this.isAccount()) {
      return subject.name.charAt(0);
    }
    return subject.charAt(0);
  };

  renderAvatar = () => {
    const { isLoading, subject, sx } = this.props;
    if (this.isUser() && subject.avatar) {
      return (
        <MuiAvatar
          className={this.props.className}
          style={{
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
          }}
          src={isLoading ? "" : userAvatarUrl(subject, this.props.size)}
          alt="user avatar"
          sx={sx}
        >
          <Loader isLoading={isLoading} />
        </MuiAvatar>
      );
    }
    const baseColor = stringToColor(subject.name || subject);
    const startColor = setSaturation(0.5, baseColor);
    const endColor = darken(0.1, startColor);
    return (
      <MuiAvatar
        className={this.props.className}
        style={{
          background: `linear-gradient(-135deg, ${startColor}, ${endColor})`,
          width: `${this.props.size}px`,
          height: `${this.props.size}px`,
          fontSize: this.props.fontSize,
          textTransform: "uppercase",
        }}
        sx={sx}
      >
        <Loader isLoading={isLoading}>{this.textContent()}</Loader>
      </MuiAvatar>
    );
  };

  render() {
    const { tooltip } = this.props;
    if (tooltip) {
      return <Tooltip title={tooltip}>{this.renderAvatar()}</Tooltip>;
    }
    return this.renderAvatar();
  }
}
