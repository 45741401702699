import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import HelpIcon from "@mui/icons-material/Help";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ChangeEvent, MouseEvent, useState } from "react";
import withPopover from "higherOrderComponents/withPopover";
import { useCurrentAccount } from "hooks";
import { InfoOutlinedIcon } from "icons";

import { timeOptions } from "features/AccountSettings/components/FilterContactSettings";

// TODO: Remove all terneraries related to campaignsResponsiveness

type StyledProps = {
  campaignsResponsiveness: boolean;
  mobileView: boolean;
};

type RecipientExclusionSectionProps = {
  campaignsResponsiveness: boolean;
  handleExclusionTimeChange: (timeInput: number) => void;
  anchorEl: Element | null;
  closeHandler: () => void;
  exclusionTimeInput: number;
  openHandler: (event: MouseEvent<HTMLElement>) => void;
};

const Wrapper = styled(Box)(({
  campaignsResponsiveness,
  mobileView,
}: StyledProps) => {
  return {
    fontSize: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    flexDirection: mobileView ? "column" : "row",
    alignItems: mobileView ? "flex-start" : "center",
    justifyContent: campaignsResponsiveness ? "space-between" : "flex-start",
    paddingInline: "1rem",
  };
});

const Options = styled(Box)(({
  mobileView,
  campaignsResponsiveness,
}: StyledProps) => {
  return {
    position: "relative",
    width: mobileView && campaignsResponsiveness ? "100%" : "16rem",
  };
});

const Helper = styled(Box)({
  position: "absolute",
  right: "-1.25rem",
  top: "0.75rem",
});

const PopoverContent = styled(Box)(({ theme }) => {
  return {
    borderRadius: "0.25rem",
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    padding: "1rem 1.25rem",
    maxWidth: "18.75rem",
  };
});

function RecipientsExclusionSection(props: RecipientExclusionSectionProps) {
  const [isMobileTooltipOpen, setIsMobileTooltipOpen] = useState(false);

  const account = useCurrentAccount();
  // gets the exclusion time set by the organization admin
  // since only org admins can adjust this setting in campaign settings
  const { exclusionTime, exclusionTimeEnabled } = account?.settings;

  const handleExclusionTime = (event: ChangeEvent<HTMLInputElement>) => {
    return props.handleExclusionTimeChange(
      Number.parseInt(event.target.value, 10),
    );
  };

  const mobileView = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const toggleMobileTooltip = () => {
    setIsMobileTooltipOpen(!isMobileTooltipOpen);
  };

  const {
    anchorEl,
    campaignsResponsiveness,
    closeHandler,
    exclusionTimeInput,
    openHandler,
  } = props;

  const getWidth = () => {
    let width = "14rem";

    if (!campaignsResponsiveness) {
      width = "15.625rem";
    }
    if (campaignsResponsiveness && mobileView) {
      width = "auto";
    }
    return width;
  };

  const handleMobileTooltipClose = () => {
    closeHandler();
    toggleMobileTooltip();
  };

  const handleMobileTooltipOpen = (event: MouseEvent<HTMLElement>) => {
    openHandler(event);
    toggleMobileTooltip();
  };

  return (
    <Wrapper
      campaignsResponsiveness={campaignsResponsiveness}
      mobileView={mobileView}
    >
      {campaignsResponsiveness ? (
        <Typography
          variant="body2"
          fontSize="0.875rem"
          paddingTop="0.5rem"
          paddingBottom="0.5rem"
          paddingRight={mobileView ? "0rem" : "0.5rem"}
        >
          Filter out contacts that already received a campaign in the last:
        </Typography>
      ) : (
        <div style={{ paddingRight: "0.625rem" }}>
          Filter out contacts that already received a campaign in the last:
        </div>
      )}
      <Options
        mobileView={mobileView}
        campaignsResponsiveness={campaignsResponsiveness}
      >
        <Box display="flex" marginRight="2.5rem" width={getWidth()}>
          <FormControl fullWidth>
            <TextField
              label={
                campaignsResponsiveness
                  ? "Time since"
                  : "Time since previous campaign"
              }
              onChange={handleExclusionTime}
              variant="outlined"
              select
              SelectProps={{ native: true }}
              value={exclusionTimeInput || ""}
              data-testid="exclusion-time-selector"
              sx={{
                width: "100%",
              }}
            >
              {timeOptions.map((option) => {
                return (
                  <option
                    key={option.label}
                    value={option.value}
                    disabled={
                      exclusionTimeEnabled?.value &&
                      option.value > exclusionTime?.value
                    }
                  >
                    {option.label}
                  </option>
                );
              })}
            </TextField>
          </FormControl>
          <Helper
            onMouseEnter={
              campaignsResponsiveness
                ? mobileView
                  ? undefined
                  : openHandler
                : openHandler
            }
            onMouseLeave={
              mobileView && campaignsResponsiveness ? undefined : closeHandler
            }
            onClick={
              isMobileTooltipOpen
                ? handleMobileTooltipClose
                : handleMobileTooltipOpen
            }
            aria-label="help-popover"
          >
            {campaignsResponsiveness ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="2.875rem"
                width={mobileView ? "4.75rem" : "2.875rem"}
                paddingBottom="0.625rem"
              >
                <InfoOutlinedIcon
                  sx={(muiTheme) => {
                    return {
                      color: muiTheme.palette.text.secondary,
                    };
                  }}
                />
              </Box>
            ) : (
              <HelpIcon fontSize="medium" color="action" width="2.875rem" />
            )}
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={closeHandler}
              style={{ pointerEvents: "none" }}
              open={Boolean(anchorEl)}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <PopoverContent>
                Allows you to avoid sending your campaign message to a phone
                number if it has received another campaign message from anyone
                on your account within the last hour, day, etc.
              </PopoverContent>
            </Popover>
          </Helper>
        </Box>
      </Options>
    </Wrapper>
  );
}

RecipientsExclusionSection.propTypes = {
  anchorEl: PropTypes.any, // withPopover
  campaignsResponsiveness: PropTypes.bool, // passed
  closeHandler: PropTypes.func.isRequired, // withPopover
  exclusionTimeInput: PropTypes.any,
  handleExclusionTimeChange: PropTypes.func.isRequired,
  openHandler: PropTypes.func.isRequired, // withPopover
};

export default withPopover(RecipientsExclusionSection);
