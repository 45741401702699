/* eslint-disable unicorn/no-zero-fractions */
import { Account, Contact, User } from "@tesseract/core";
import faker from "faker";

const collection = (overrides: any = {}, { pagination = false } = {}) => {
  const { id = "/", members = [] } = overrides;
  const totalItems = members.length;
  return {
    "@context": "/contexts/hydra:Collection.jsonld",
    "@type": "hydra:Collection",
    id,
    members,
    totalItems,
    ...(pagination && {
      view: {
        "@context": "/contexts/hydra:PartialCollectionView.jsonld",
        "@type": "hydra:PartialCollectionView",
        id,
        first: id,
        next: null,
      },
    }),
    ...overrides,
  };
};

export const accountAggregateStats = (overrides: any = {}) => {
  return {
    "@type": "AccountAggregateStats",
    "@context": "/contexts/AccountAggregateStats.jsonld",
    totalAccounts: 1,
    id: "/accounts/analytics",
    activeConversations: 1,
    responseRate: 1,
    totalSent: 1,
    totalReceived: 1,
    totalCampaignsSent: 1,
    ...overrides,
  };
};

export const accountAnalytic = (overrides: any = {}) => {
  return {
    "@context": "/contexts/AccountAnalytics.jsonld",
    "@type": "AccountAnalytics",
    id: "/accounts/textus/analytics?startDate=2019-05-02T06:00:00.000Z&endDate=2019-05-09T05:59:59.999Z",
    account: "/accounts/textus",
    activeConversations: 0,
    engagement: 0,
    inboundLagTime: null,
    messagesPerConversation: 0,
    optOutCount: 0,
    outboundLagTime: null,
    responseRate: 0.0,
    totalDelivered: 0,
    totalFailed: 0,
    totalReceived: 0,
    totalSent: 0,
    totalSpam: 0,
    ...overrides,
  };
};

export const accountAnalyticCollection = (overrides: any = {}) => {
  return collection({ id: "/account_analytics?accounts=[]", ...overrides });
};

export const userAnalytic = (overrides: any = {}) => {
  return {
    "@type": "UserAnalytics",
    "@context": "/contexts/UserAnalytics.jsonld",
    id: "/users/1/analytics?account_slug=my_account",
    optOutCount: 0,
    engagement: null,
    messagesPerConversation: 0,
    activeConversations: 0,
    inboundLagTime: null,
    outboundLagTime: null,
    responseRate: 0.0,
    totalSent: 0,
    totalDelivered: 0,
    totalReceived: 0,
    totalSpam: 0,
    totalFailed: 0,
    user: "/users/1",
    ...overrides,
  };
};

export const contactNote = (overrides: any = {}) => {
  return {
    "@context": "/contexts/ContactNote.jsonld",
    "@type": "ContactNote",
    id: "/contact_notes/1",
    contact: "/contacts/1",
    content: faker.lorem.sentence(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    user: "/users/1",
    ...overrides,
  };
};

export const contactNoteCollection = (overrides: any = {}) => {
  return collection({ id: "/contacts/1/notes", ...overrides });
};

export const contactPhone = (overrides: any = {}) => {
  const phoneNumber = `+${faker.phone.phoneNumberFormat(2).replaceAll("-", "")}`;
  return {
    "@context": "/contexts/ContactPhone.jsonld",
    "@type": "ContactPhone",
    id: "/contact_phones/1",
    contact: "/contacts/1",
    deliverabilityStatus: "deliverable",
    extension: null,
    formattedPhoneNumber: phoneNumber,
    phoneNumber,
    type: "Mobile",
    ...overrides,
  };
};

export const contactPhoneCollection = (overrides: any = {}) => {
  return collection({ id: "/contacts/1/phones", ...overrides });
};

export const contact = (overrides: Partial<Contact.Raw> = {}): Contact.Raw => {
  const id = overrides.id || "/contacts/1";

  const name = faker.name.findName();

  const [firstName, lastName] = name.split(" ");

  const createdAt = new Date(
    faker.date.between("2019-01-01", "2021-01-01"),
  ).toISOString();

  return {
    id,
    conversations: `${id}/conversations`,
    data: {
      business: "McDonalds",
      tags: ["cashier", "burger flipper", "fry cook"],
    },
    lastName,
    createdAt,
    firstName,
    name,
    notes: `${id}/notes`,
    phones: contactPhoneCollection({
      members: [contactPhone()],
    }),
    ...overrides,
  };
};

export const contactCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/textus/contacts",
      ...overrides,
    },
    { pagination: true },
  );
};

export const contactFilter = (overrides: any = {}) => {
  const id = overrides.id || "/textus/contact_filters/all";
  return {
    "@context": "/contexts/ContactFilter.jsonld",
    "@type": "ContactFilter",
    id,
    blockContacts: `${id}/contacts/block`,
    contacts: `${id}/contacts`,
    path: `${id}`,
    slug: "all",
    subscribeContacts: `${id}/contacts/subscribe`,
    title: "All Contacts",
    unblockContacts: `${id}/contacts/block`,
    unsubscribeContacts: `${id}/contacts/subscribe`,
    ...overrides,
  };
};

export const contactFilterCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/contact_filters", ...overrides });
};

export const account = (overrides: Partial<Account.Raw> = {}): Account.Raw => {
  const id = (overrides.id ?? "/accounts/textus") satisfies Account.Identity;
  const slug = overrides.slug || id.split("/")[2];
  return {
    "@context": "/contexts/Account.jsonld",
    "@type": "Account",
    id,
    slug,
    adminUserSchema: {},
    analytics: `/${slug}/analytics`,
    campaignFilters: `/${slug}/campaign_filters`,
    campaigns: `/${slug}/campaigns`,
    children: `${id}/children`,
    childrenCount: 0,
    contactFilters: `/${slug}/contact_filters`,
    contactImport: `/${slug}/contacts/import`,
    contactPhones: `/${slug}/contact_phones`,
    contacts: `/${slug}/contacts`,
    contactsEnabled: true,
    conversationFilters: `/${slug}/conversation_filters`,
    conversations: `/${slug}/conversations`,
    exportOptOuts: `/${slug}/opt_outs/export`,
    familyCount: 1,
    featureFlags: {},
    groupFilters: `/${slug}/group_filters`,
    importOptOuts: `/${slug}/opt_outs/import`,
    inbox: `/${slug}/inbox`,
    integrations: `/${slug}/integrations`,
    logo: null,
    multiUser: false,
    name: "TextUs",
    navbar: `/${slug}/navbar`,
    optOuts: `/${slug}/opt_outs`,
    optOutAnalytics: `/${slug}/opt_outs/analytics`,
    phoneNumbers: ["+15555555555"],
    presignAttachment: `/${slug}/attachments/sign`,
    presignContactImport: `/${slug}/contact_imports/generate_signed_url`,
    presignOptOutImport: `/${slug}/opt_out_imports/generate_signed_url`,
    presignConversationImport: `/${slug}/conversation_imports/generate_signed_url`,
    sendMessages: `/${slug}/messages`,
    settings: {
      campaignMaxMessageLength: {
        disabled: false,
        locked: false,
        setAtAccount: `/accounts/${slug}`,
        value: 480,
        lockedAtAccount: {
          id: null,
          name: null,
        },
      },
      conversationMaxMessageLength: { value: 640 },
      timeZone: { value: "US/Mountain" },
      registeredLinks: {
        disabled: false,
        locked: false,
        setAtAccount: `/accounts/${slug}`,
        value: "myshortlink",
        lockedAtAccount: {
          id: null,
          name: null,
        },
      },
      dailyCampaignRecipients: {
        disabled: false,
        locked: false,
        setAtAccount: `/accounts/${slug}`,
        value: 100,
        lockedAtAccount: {
          id: null,
          name: null,
        },
      },
    },
    shortenedLinkDomain: "textus.textus-stg.app",
    shortenedLinkPreviewUrl: "https://textus.textus-stg.app/#",
    signedAvatarUrl: `/${slug}/users/signed_avatar_url`,
    signedWhitelabelUrl: `/${slug}/users/signed_whitelabel_url`,
    type: "organization",
    users: `/${slug}/users`,
    userSchema: `/${slug}/user_schema`,
    ...overrides,
  };
};

export const accountCollection = (overrides: any = {}) => {
  return collection({ id: "/accounts", ...overrides });
};

export const accountChildrenCollection = (overrides: any = {}) => {
  return collection({ id: "/accounts/textus/children", ...overrides });
};

export const membership = (overrides: any = {}) => {
  const user = overrides.user || "/users/1";
  const id = overrides.id || `${user}/memberships/1`;
  return {
    "@context": "/contexts/Membership.jsonld",
    "@type": "Membership",
    id,
    role: "admin",
    user,
    account: "/accounts/textus",
    ...overrides,
  };
};

export const membershipCollection = (overrides: any = {}) => {
  return collection({ id: "/users/1/memberships", ...overrides });
};

export const signature = (overrides: any = {}) => {
  return {
    "@context": "/contexts/Signature.jsonld",
    "@type": "Signature",
    id: "/users/1/signature",
    content: faker.lorem.sentence(),
    ...overrides,
  };
};

export const user = (overrides: Partial<User.Raw> = {}): User.Raw => {
  const id = overrides.id || "/users/1";
  const name = "Mock User";
  const [firstName, lastName] = name.split(" ");
  return {
    "@context": "/contexts/User.jsonld",
    "@type": "User",
    id,
    automatedActor: false,
    avatar: null,
    discarded: false,
    email: faker.internet.email(),
    featureFlags: {},
    firstName,
    gettingStartedElements: "/getting_started",
    hasSubAccounts: false,
    lastName,
    memberships: `${id}/memberships`,
    name,
    savedReplies: `${id}/saved_replies`,
    settings: {
      defaultMessagingAccount: undefined,
    },
    signature: `${id}/signature`,
    topLevelAccounts: [],
    uiConfig: {},
    ...overrides,
  };
};

export const userCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/users", ...overrides });
};

export const gettingStartedElementsCollection = (overrides: any = {}) => {
  return collection({ id: "/getting_started", ...overrides });
};

export const gettingStartedElement = (overrides: any = {}) => {
  return {
    "@context": "/contexts/GettingStartedElement.jsonld",
    "@type": "GettingStartedElement",
    id: "/getting_started/1",
    elementType: "set_your_avatar",
    record: null,
    ...overrides,
  };
};

export const badge = (overrides: any = {}) => {
  return {
    "@context": "/contexts/Badge.jsonld",
    "@type": "Badge",
    id: `/navbar/textus/badges/1`,
    value: 0,
    ...overrides,
  };
};

export const badgeCollection = (overrides: any = {}) => {
  return collection({ id: "/navbar/textus/badges", ...overrides });
};

export const siteNavigationElement = (overrides: any = {}) => {
  return {
    "@context": "/contexts/SiteNavigationElement.jsonld",
    "@type": "SiteNavigationElement",
    id: `/navbar/textus/site_nav/inbox`,
    badge: 0,
    badgeRecord: "/navbar/textus/badges/1",
    icon: "inbox",
    index: 1,
    name: "inbox",
    title: "Inbox",
    uiLink: `/textus/inbox`,
    ...overrides,
  };
};

export const siteNavigation = (overrides: any = {}) => {
  return collection({ id: "/navbar/textus/site_nav", ...overrides });
};

export const accountNavbar = (overrides: any = {}) => {
  return collection({ id: "/navbar", ...overrides });
};

export const accountNavbarElement = (overrides: any = {}) => {
  return {
    "@context": "/contexts/AccountNavigationElement.jsonld",
    "@type": "AccountNavbarElement",
    id: "/navbar/textus",
    account: "/accounts/textus",
    badge: 0,
    badges: "/navbar/textus/badges",
    index: 1,
    siteNavigation: "/navbar/textus/site_nav",
    title: "TextUs",
    uiLink: "/textus",
    ...overrides,
  };
};

export const accountTreeCollection = (overrides: any = {}) => {
  return collection({ id: "/accounts/tree", ...overrides });
};

export const accountTree = (overrides: any = {}) => {
  return {
    "@context": "/contexts/AccountTree.jsonld",
    "@type": "AccountTree",
    id: "/accounts/textus",
    name: "TextUs",
    slug: "textus",
    users: "/textus/users",
    children: accountTreeCollection(),
    ...overrides,
  };
};

export const bootstrap = (overrides: any = {}) => {
  return {
    "@context": "/contexts/Bootstrap.jsonld",
    "@type": "Bootstrap",
    id: "/boot",
    appSettings: {},
    currentUser: "/users/1",
    accountNavbar: "/navbar",
    badges: "/navbar/badges",
    ...overrides,
  };
};

export const attachment = (overrides: any = {}) => {
  return {
    "@context": "/contexts/Attachment.jsonld",
    "@type": "Attachment",
    id: "/textus/messages/1/attachments/1",
    contentType: "image/jpeg",
    height: 800,
    key: "a3036c9799f85a9a8125dcdba535ccf9389b6d22f4ae8dd493610682568e04a3.jpeg",
    size: 320_578,
    url: "https://d37haxh5p8y0yt.cloudfront.net/a3036c9799f85a9a8125dcdba535ccf9389b6d22f4ae8dd493610682568e04a3.jpeg",
    width: 1200,
    ...overrides,
  };
};

export const attachmentsCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/messages/1/attachments", ...overrides });
};

export const message = (overrides: any = {}) => {
  const {
    id = "/textus/messages/1",
    body = faker.lorem.sentence(),
    timelinePosition = new Date().toISOString(),
  } = overrides;
  return {
    id,
    "@type": "Message",
    "@context": "/contexts/Message.jsonld",
    conversation: "/conversations/1",
    direction: "out",
    body,
    attachments: `${id}/attachments`,
    formattedBody: `<div>${body}</div>`,
    timelinePosition,
    latevetEventAt: new Date().toISOString(),
    scheduledAt: new Date().toISOString(),
    displayTimestamp: timelinePosition,
    deliveryState: "delivered",
    sender: "/users/1",
    ...overrides,
  };
};

export const participantsCollection = (overrides: any = {}) => {
  return collection({ id: "/conversations/1/participants", ...overrides });
};

export const conversationAssignment = (overrides: any = {}) => {
  return {
    id: "/assignments/1",
    assignee: "/users/1",
    assigner: "/users/1",
    ...overrides,
  };
};

export const conversationAssignmentsCollection = (overrides: any = {}) => {
  return collection({ id: "/conversations/1/assignments", ...overrides });
};

export const conversationTimeline = (overrides: any = {}) => {
  return collection(
    {
      id: "/conversations/1/timeline",
      "@type": "Timeline",
      ...overrides,
    },
    { pagination: true },
  );
};

export const requestingUserInfo = (overrides: any = {}) => {
  return {
    "@context": "/contexts/ConversationUserInfo.jsonld",
    "@type": "ConversationUserInfo",
    id: "/conversations/1/user_info",
    assigned: false,
    read: false,
    readToTimelinePosition: new Date().toISOString(),
    starred: false,
    ...overrides,
  };
};

export const conversation = (overrides: any = {}) => {
  const { id = "/conversations/1" } = overrides;
  return {
    "@context": "/contexts/Conversation.jsonld",
    "@type": "Conversation",
    id,
    account: "/accounts/textus",
    assignedContact: "/contacts/1",
    assignContact: `${id}/assign_contact`,
    assignments: `${id}/assignments`,
    associatedContacts: `${id}/associated_contacts`,
    blockConversation: `${id}/block`,
    blocked: false,
    closeConversation: `${id}/close`,
    currentState: "open",
    formattedPhoneNumber: "(303) 555-1234",
    latestPreviewableItem: "/messages/1",
    participants: `${id}/participants`,
    phoneNumber: "+13035551234",
    readConversation: `${id}/read`,
    reopenConversation: null,
    requestingUserInfo: `${id}/user_info`,
    slug: id.split("/")[2],
    starConversation: `${id}/star`,
    stats: `${id}/stats`,
    subscribeConversation: null,
    timeline: `${id}/timeline`,
    unanswered: false,
    unblockConversation: `${id}/block`,
    unreadConversation: `${id}/read`,
    unstarConversation: `${id}/star`,
    unsubscribeConversation: `${id}/subscribe`,
    unsubscribed: `${id}/subscribe`,
    ...overrides,
  };
};

export const conversationCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/textus/conversation_filters/all/conversations",
      "@type": "ConversationCollection",
      ...overrides,
    },
    { pagination: true },
  );
};

export const conversationFilter = (overrides: any = {}) => {
  const { id = "/textus/conversation_filters/all" } = overrides;
  return {
    "@context": "/contexts/ConversationFilter.jsonld",
    "@type": "ConversationFilter",
    id,
    assignConversations: `${id}/assign`,
    closeConversations: `${id}/close`,
    conversations: `${id}/conversations`,
    readConversations: `${id}/read`,
    reopenConversations: `${id}/reopen`,
    slug: "all",
    starConversations: `${id}/star`,
    title: "All Coversations",
    totalCount: 0,
    unassignConversations: `${id}/assign`,
    unreadConversations: `${id}/read`,
    unreadCount: 0,
    unstarConversations: `${id}/star`,
    ...overrides,
  };
};

export const conversationFilterCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/conversation_filters", ...overrides });
};

export const conversationStats = (overrides: any = {}) => {
  return {
    "@context": "/contexts/ConversationStats.jsonld",
    "@type": "ConversationStats",
    id: "/conversations/1/stats",
    conversationStartedAt: "2019-04-02T20:28:58Z",
    engagement: 2,
    outboundDelivered: 1,
    ...overrides,
  };
};

export const inbox = (overrides: any = {}) => {
  return {
    "@context": "/contexts/Inbox.jsonld",
    "@type": "Inbox",
    id: "/textus/inbox",
    conversationFilters: "/textus/conversation_filters",
    activeConversationFilter: "/textus/conversation_filters/all",
    activeConversation: "/conversations/1",
    ...overrides,
  };
};

export const savedReply = (overrides: any = {}) => {
  return {
    "@context": "/contexts/SavedReply.jsonld",
    "@type": "SavedReply",
    id: "/saved_replies/1",
    content: faker.lorem.sentence(),
    title: faker.lorem.words(),
    user: "/users/1",
    ...overrides,
  };
};

export const savedReplyCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/users/1/saved_replies",
      ...overrides,
    },
    { pagination: true },
  );
};

export const contactImportCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/contact_imports", ...overrides });
};

export const contactImport = (overrides: any = {}) => {
  const { id = "/contact_imports/1" } = overrides;
  return {
    "@context": "/contexts/ContactImport.jsonld",
    "@type": "ContactImport",
    id,
    batchStatus: "/batch_status/38d31b0f2b8fc98f.json",
    contactFilter: "/textus/contact_filters/import:1",
    createdAt: new Date().toISOString(),
    failureCount: 5,
    processedAt: new Date().toISOString(),
    recordCount: 100,
    records: `${id}/contact_import_records`,
    sourceAccount: "/accounts/textus",
    state: "processed",
    targetAccount: "/accounts/textus",
    title: "Contact Import",
    user: "/users/1",
    ...overrides,
  };
};

export const campaignRecipient = (overrides: any = {}) => {
  return {
    "@type": "CampaignRecipient",
    "@context": "/contexts/CampaignRecipient.jsonld",
    id: "/campaign_recipients/1",
    state: "sent",
    error: null,
    createdAt: "2018-11-19T20:49:03.581Z",
    scheduledAt: null,
    contactPhone: "/contact_phones/1",
    message: "/messages/1",
    campaign: "/campaigns/1",
    ...overrides,
  };
};

export const campaignRecipientCollection = (overrides: any = {}) => {
  const { id = "/campaigns/1/campaign_recipients" } = overrides;
  return collection(
    {
      id,
      addContacts: `${id}/add`,
      blockContacts: `${id}/block`,
      unblockContacts: `${id}/unblock`,
      subscribeContacts: `${id}/subscribe`,
      unsubscribeContacts: `${id}/unsubscribe`,
      ...overrides,
    },
    { pagination: true },
  );
};

export const campaign = (overrides: any = {}) => {
  const { id = "/campaigns/1" } = overrides;
  return {
    "@context": "/contexts/Campaign.jsonld",
    "@type": "Campaign",
    id,
    account: "/accounts/textus",
    cancel: `${id}/cancel`,
    createdAt: new Date().toISOString(),
    delete: id,
    edit: id,
    campaignTemplates: {
      totalItems: 1,
      members: [
        {
          messageBody: "The best campaign message.",
          attachments: [],
          deliveryStatistics: {
            total: 0,
            skipped: 0,
            sent: 0,
            delivered: 0,
            replied: 0,
            failed: 0,
          },
        },
      ],
    },
    deliveredRecipients: `${id}/campaign_recipients/delivered`,
    failedRecipients: `${id}/campaign_recipients/failed`,
    messageBody: "The best campaign message.",
    recipients: `${id}/campaign_recipients`,
    repliedRecipients: `${id}/campaign_recipients/replied`,
    schedule: "FREQ=WEEKLY;INTERVAL=2;BYDAY=WE;BYHOUR=12;BYMINUTE=00;",
    scheduledAt: new Date().toISOString(),
    sendingEndAt: new Date().toISOString(),
    sendingStartAt: new Date().toISOString(),
    sentRecipients: `${id}/campaign_recipients/sent`,
    skippedRecipients: `${id}/campaign_recipients/skipped`,
    state: "sent",
    title: "The Best Campaign",
    totalRecipients: `${id}/campaign_recipients/total`,
    user: "/users/1",
    deliveryStatistics: {
      total: 0,
      skipped: 0,
      sent: 0,
      delivered: 0,
      replied: 0,
      failed: 0,
    },
    ...overrides,
  };
};

export const campaignCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/textus/campaign_filters/mine/campaigns",
      ...overrides,
    },
    { pagination: true },
  );
};

export const campaignFilter = (overrides: any = {}) => {
  const { id = "/textus/campaigan_filters/mine", slug = id.split("/")[3] } =
    overrides;
  return {
    "@type": "CampaignFilter",
    "@context": "/contexts/CampaignFilter.jsonld",
    id,
    campaigns: `${id}/campaigns`,
    export: `${id}/export`,
    slug,
    title: `${slug} Campaigns`,
    ...overrides,
  };
};

export const campaignFilterCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/campaign_filters", ...overrides });
};

export const integration = (overrides: any = {}) => {
  return {
    "@type": "Integration",
    "@context": "/contexts/Integration.jsonld",
    id: "/textus/integrations/1",
    provider: "erecruit",
    active: true,
    config: {
      baseUrl: "http://erecruit.com",
      clientId: "123",
      clientSecret: "456",
    },
    ...overrides,
  };
};

export const integrationCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/integrations", ...overrides });
};

export const deliveryStats = (overrides: any = {}) => {
  return {
    "@context": "/contexts/DeliveryStats.jsonld",
    "@type": "DeliveryStats",
    id: "/textus/deliveries/stats",
    account: "/accounts/textus",
    date: "2019-02-10",
    campaignDailyLimit: 500,
    campaignMessagesUsed: 100,
    campaignMessagesScheduled: 50,
    ...overrides,
  };
};

export const optOutAnalytics = (overrides: any = {}) => {
  return {
    "@type": "OptOutAnalytics",
    id: "/textus/opt_outs/analytics",
    account: {
      totalOptOuts: 100,
      contactCreated: 50,
      userCreated: 50,
    },
    global: {
      totalOptOuts: 200,
      contactCreated: 100,
      userCreated: 100,
    },
    ...overrides,
  };
};

export const userApiToken = (overrides: any = {}) => {
  return {
    "@type": "UserApiToken",
    "@context": "/contexts/UserApiToken.jsonld",
    id: "/user_api_tokens/BYoaNE",
    accessToken: "abcdef",
    description: "New Token",
    createdAt: "2020-02-07T21:52:53.938Z",
    ...overrides,
  };
};

export const userApiTokenCollection = (overrides: any = {}) => {
  return collection({ id: "/user_api_tokens", ...overrides });
};

export const currentUser = (overrides: any = {}) => {
  return user({
    memberships: membershipCollection({
      members: [membership({ account: account() })],
    }),
    gettingStartedElements: gettingStartedElementsCollection({
      members: [gettingStartedElement()],
    }),
    signature: signature(),
    ...overrides,
  });
};

export const activeContact = (overrides: any = {}) => {
  return contact({
    notes: contactNoteCollection(),
    phones: contactPhoneCollection({
      members: [contactPhone()],
    }),
    ...overrides,
  });
};

export const activeConversation = (overrides: any = {}) => {
  const assignedContact = contact({
    notes: contactNoteCollection(),
    phones: contactPhoneCollection({
      members: [contactPhone()],
    }),
  });

  return conversation({
    assignedContact,
    associatedContacts: contactCollection({
      members: [assignedContact],
    }),
    participants: participantsCollection({
      members: [user()],
    }),
    phoneNumber: assignedContact.phones.members[0].phoneNumber,
    requestingUserInfo: requestingUserInfo({
      read: true,
    }),
    timeline: conversationTimeline({
      latestPosition: new Date().toISOString(),
      members: [
        message({
          attachments: attachmentsCollection(),
        }),
      ],
    }),
    ...overrides,
  });
};

export const activeCampaign = (overrides: any = {}) => {
  const id = "/campaigns/1";
  const recipient = campaignRecipient({
    contactPhone: contactPhone(),
  });
  return campaign({
    id,
    user: user(),
    totalRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/total`,
      members: [recipient],
    }),
    skippedRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/skipped`,
      members: [],
    }),
    sentRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/sent`,
      members: [recipient],
    }),
    deliveredRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/delivered`,
      members: [recipient],
    }),
    failedRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/failed`,
      members: [],
    }),
    repliedRecipients: campaignRecipientCollection({
      id: `${id}/campaign_recipients/replied`,
      members: [recipient],
    }),
    ...overrides,
  });
};

export const bootstrapResponse = (overrides: any = {}) => {
  return bootstrap({
    currentUser: currentUser({
      memberships: membershipCollection({
        members: [
          membership({
            account: account(),
          }),
        ],
      }),
    }),
    accountNavbar: accountNavbar({
      members: [
        accountNavbarElement({
          siteNavigation: siteNavigation({
            members: [siteNavigationElement()],
          }),
        }),
      ],
    }),
    ...overrides,
  });
};

export const groupFilter = (overrides: any = {}) => {
  const id = overrides.id || "/textus/group_filters/all";
  return {
    "@context": "/contexts/GroupFilter.jsonld",
    "@type": "GroupFilter",
    id,
    groups: `${id}/groups`,
    ...overrides,
  };
};

export const groupFilterCollection = (overrides: any = {}) => {
  return collection({ id: "/textus/group_filters", ...overrides });
};

export const optOutsCollection = (overrides: any = {}) => {
  return collection(
    { id: "/textus/opt_outs", ...overrides },
    { pagination: true },
  );
};

export const optOut = (overrides: any = {}) => {
  return {
    id: "/textus/opt_outs/1",
    formattedPhoneNumber: "303-123-4567",
    createdVia: "User",
    createdAt: new Date().toISOString(),
    originatingAccount: "/accounts/textus",
    user: "/users/1",
    ...overrides,
  };
};

export const groupContactCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/groups/1/contacts",
      ...overrides,
    },
    { pagination: true },
  );
};

export const group = (overrides: any = {}) => {
  const id = overrides.id || "/groups/1";
  const name = overrides.name || "Group 1";
  return {
    "@context": "/contexts/Group.jsonld",
    "@type": "Group",
    id,
    name,
    contacts: `${id}/contacts`,
    slug: id.replace("/groups/", ""),
    ...overrides,
  };
};

export const groupCollection = (overrides: any = {}) => {
  return collection(
    {
      id: "/textus/groups",
      ...overrides,
    },
    { pagination: true },
  );
};

export const customField = (overrides: any = {}) => {
  const {
    slug = `test-title`,
    id = `/textus-services/user_schema/${slug}`,
    inUse = false,
    description = "test description",
    title = "Test title",
    type = "text",
  } = overrides;
  return {
    "@context": "/contexts/CustomField.jsonld",
    "@type": "CustomField",
    createdAt: new Date().toISOString(),
    id,
    inUse,
    slug,
    title,
    description,
    type,
    ...overrides,
  };
};

export const customFieldsCollection = (overrides: any = {}) => {
  const { members = [] } = overrides;
  const totalItems = members.length;
  const id = overrides.id || "/textus-services/user_schema/fields";
  return collection({
    id,
    members,
    totalItems,
    ...overrides,
  });
};

export const userSchema = (overrides: any = {}) => {
  const accountSlug = overrides.accountSlug || "textus-services";
  const fields = overrides.fields || customFieldsCollection();
  const id = overrides.id || "/textus-services/user_schema";
  return {
    "@context": "/contexts/UserSchema.jsonld",
    "@type": "UserSchema",
    id,
    account: `/accounts/${accountSlug}`,
    fields,
  };
};

export const usersByOrganization = (overrides: any = {}) => {
  return {
    "@type": "AnalyticsReport",
    "@context": "/contexts/AnalyticsReport.jsonld",
    id:
      overrides.id ||
      "/analytics/users_by_organization?account=%2Faccounts%2test_account_1&endDate=2021-2-3&startDate=2021-1-1",
    startDate: "2021-01-01T00:00:00.000000+00:00",
    endDate: "2021-02-03T00:00:00.000000+00:00",
    headers: [
      {
        title: "Organization Name",
        slug: "organization_name",
        description: "The highest level account.",
      },
      {
        title: "Parent Account",
        slug: "parent_account_name",
        description: "The second highest level account.",
      },
      {
        title: "Account Name",
        slug: "account_name",
        description: "A child account of the organization.",
      },
      {
        title: "User Name",
        slug: "user_name",
        description:
          "The actual user that has a membership to that specific account.",
      },
      {
        title: "User Role",
        slug: "user_role",
        description:
          "The type of membership and permissions the user has in that account.",
      },
      {
        title: "Account Created",
        slug: "account_created",
        description: "The date the account was created.",
      },
      {
        title: "User Created",
        slug: "user_created",
        description: "The date the user was created.",
      },
    ],
    totalCount: 3,
    title: "Users by Organization",
    data: [
      [
        "Test Account 1",
        "Test Account 1",
        "Child Account",
        "User",
        "admin",
        "2021-01-25T17:51:49.083388Z",
        "2021-01-25T17:39:43.166565Z",
      ],
      [
        "Test Account 1",
        "Master Account",
        "Test Account 1",
        "User",
        "admin",
        "2021-01-25T18:17:27.562276Z",
        "2021-01-25T17:39:43.166565Z",
      ],
      [
        "Test Account 1",
        "Child Account",
        "Grandchild Account",
        "User",
        "admin",
        "2021-01-25T18:34:48.012618Z",
        "2021-01-25T17:39:43.166565Z",
      ],
    ],
  };
};

export const messageDeliverability = (overrides: any = {}) => {
  return {
    "@type": "AnalyticsReport",
    "@context": "/contexts/AnalyticsReport.jsonld",
    id:
      overrides.id ||
      "/analytics/message_deliverability?account=%2Faccounts%2Fbh_direct&endDate=2021-1-31&startDate=2021-1-1",
    startDate: "2021-01-01T00:00:00.000000Z",
    endDate: "2021-01-31T00:00:00.000000Z",
    headers: [
      {
        title: "Organization Name",
        slug: "organization_name",
        description: "The highest level account.",
      },
      {
        title: "Parent Account",
        slug: "parent_account_name",
        description: "The second highest level account.",
      },
      {
        title: "Account Name",
        slug: "account_name",
        description: "A child account of the organization.",
      },
      {
        title: "User Name",
        slug: "user_name",
        description:
          "The actual user that has a membership to that specific account.",
      },
      {
        title: "Active User",
        slug: "active_user",
        description: "Current active status of the user.",
      },
      {
        title: "Sent Messages",
        slug: "sent_messages",
        description:
          "The count of outbound messages by the user in the specified time frame.",
      },
      {
        title: "Delivered Messages",
        slug: "delivered_messages",
        description:
          "The count of outbound messages that were successfully delivered in the specified time frame.",
      },
      {
        title: "Failed Messages",
        slug: "failed_messages",
        description: "The percentage of failed messages from the total sent.",
      },
      {
        title: "Received Messages",
        slug: "received_messages",
        description:
          "The count of inbound messages received for a conversation assigned to that specific user.",
      },
      {
        title: "Delivered Rate",
        slug: "delivered_rate",
        description:
          "The percentage of delivered messages from the total sent.",
      },
      {
        title: "Failed Rate",
        slug: "failed_rate",
        description: "The percentage of failed messages from the total sent.",
      },
      {
        title: "Sent and Received Ratio",
        slug: "sent_and_received_ratio",
        description:
          "The proportion of sent messages to received messages. The message balance of how many messages sent to received.",
      },
    ],
    totalCount: 3,
    title: "Message Deliverability",
    data: [
      [
        "Test Account 1",
        "Test Account 1",
        "Child Account",
        "User 1",
        "Yes",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0.0",
      ],
      [
        "Test Account 1",
        "TextUs",
        "Test Account 1",
        "User 1",
        "Yes",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0.0",
      ],
      [
        "Test Account 1",
        "Child Account",
        "Grandchild Account",
        "User 1",
        "Yes",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0.0",
      ],
    ],
  };
};

export const userEngagement = (overrides: any = {}) => {
  return {
    "@type": "AnalyticsReport",
    "@context": "/contexts/AnalyticsReport.jsonld",
    id:
      overrides.id ||
      "/analytics/user_engagement?account=%2Faccounts%2Fbh_direct&endDate=2021-1-31&startDate=2021-1-1",
    startDate: "2021-01-01T00:00:00.000000Z",
    endDate: "2021-01-31T00:00:00.000000Z",
    headers: [
      {
        title: "Organization Name",
        slug: "organization_name",
        description: "The highest level account.",
      },
      {
        title: "Parent Account",
        slug: "parent_account_name",
        description: "The second highest level account.",
      },
      {
        title: "Account Name",
        slug: "account_name",
        description: "A child account of the organization.",
      },
      {
        title: "User Name",
        slug: "user_name",
        description:
          "The actual user that has a membership to that specific account.",
      },
      {
        title: "Active Conversations",
        slug: "active_conversations",
        description:
          "Any conversation with inbound and or outbound messages within the specified time frame.",
      },
      {
        title: "Messages per Conversation",
        slug: "messages_per_conversation",
        description:
          "The average number of messages sent by the user per conversation.",
      },
      {
        title: "Active Conversations with Responses",
        slug: "active_conversations_with_responses",
        description:
          "Tracks the number of conversations that have an inbound and outbound pair of messages within a week of each other.",
      },
      {
        title: "Active Conversations Response Rate",
        slug: "active_conversations_response_rate",
        description:
          "The proportion of total active conversations with a response. The formula for this rate is: Active Conversations with Responses/Active Conversations.",
      },
      {
        title: "Customer Initiated Conversations",
        slug: "customer_initiated_conversations",
        description:
          "The amount of active conversations that have been started by an inbound message.",
      },
      {
        title: "Total Response Rate",
        slug: "total_response_rate",
        description:
          "Response rate since the user account was created with active conversations. The formula for this rate is: Active Conversations with Responses (regardless of time window) / Active Conversations.",
      },
    ],
    totalCount: 3,
    title: "User Engagement",
    data: [
      [
        "Test Account 1",
        "Test Account 1",
        "Child Account",
        "User 1",
        "0",
        "0.0",
        "0",
        "0",
        "0",
        "0",
      ],
      [
        "Test Account 1",
        "TextUs",
        "Test Account 1",
        "User 1",
        "0",
        "0.0",
        "0",
        "0",
        "0",
        "0",
      ],
      [
        "Test Account 1",
        "Child Account",
        "Grandchild Account",
        "User 1",
        "0",
        "0.0",
        "0",
        "0",
        "0",
        "0",
      ],
    ],
  };
};

export const analyticsReports = (overrides: any = {}) => {
  return {
    "@type": "hydra:Collection",
    "@context": "/contexts/hydra:Collection.jsonld",
    id: overrides.id || "/analytics",
    members: [
      {
        "@type": "AnalyticsReport",
        "@context": "/contexts/AnalyticsReport.jsonld",
        id: "/analytics/users_by_organization",
        title: "Users by Organization",
        slug: "users_by_organization",
      },
      {
        "@type": "AnalyticsReport",
        "@context": "/contexts/AnalyticsReport.jsonld",
        id: "/analytics/message_deliverability",
        title: "Message Deliverability",
        slug: "message_deliverability",
      },
      {
        "@type": "AnalyticsReport",
        "@context": "/contexts/AnalyticsReport.jsonld",
        id: "/analytics/user_engagement",
        title: "User Engagement",
        slug: "user_engagement",
      },
    ],
    totalItems: 3,
  };
};

export const imageFile = () => {
  return new File(["mockFile"], "mockFile.jpg", {
    lastModified: 1_637_622_904_042,
    lastModifiedDate:
      "Mon Nov 22 2021 16:15:04 GMT-0700 (Mountain Standard Time)",
    size: 10_867,
    type: "image/jpeg",
    webkitRelativePath: "",
  });
};
export const billingReports = (overrides: any = {}) => {
  return {
    "@context": "/contexts/hydra:Collection.jsonld",
    "@type": "hydra:Collection",
    id: overrides.id || "/analytics/billing",
    members: [
      {
        "@context": "/contexts/AnalyticsReport.jsonld",
        "@type": "AnalyticsReport",
        id: "/textus-services/analytics/billing/message_usage",
        slug: "message_usage",
        title: "Message Usage",
      },
    ],
    totalItems: 1,
  };
};

export const analyticsReportsIds = (overrides: any = {}) => {
  return [
    {
      "@context": "/contexts/AnalyticsSection.jsonld",
      "@type": "AnalyticsSection",
      description: "General account overview statistics",
      id: overrides.id || "/textus-services/analytics/overview",
      reports: analyticsReports(),
      slug: "overview",
      title: "Overview",
    },
    {
      "@context": "/contexts/AnalyticsSection.jsonld",
      "@type": "AnalyticsSection",
      description: "General account overview statistics",
      id: overrides.id || "/textus-services/analytics/billing",
      reports: billingReports(),
      slug: "billing",
      title: "Billing",
    },
  ];
};

export const analyticsReportsList = (overrides: any = {}) => {
  return {
    "@context": "/contexts/hydra:Collection.jsonId",
    "@type": "/hydra:Collection",
    id: overrides.id || "/textus-services/analytics",
    members: analyticsReportsIds(),
    totalItems: 2,
  };
};

export const analyticReport = (overrides: any = {}) => {
  return {
    "@context": "/contexts/AnalyticsReport.jsonld",
    "@type": "AnalyticsReport",
    data: [["Sinch Dev", 94, 46, 48]],
    endDate: "2022-04-29T05:59:59.999000Z",
    headers: [
      {
        description: "Account associated to the associated aggregate data",
        slug: "account",
        title: "Account",
      },
      {
        description:
          "Total number of message segments for the account (sent & received)",
        slug: "total_text_messages",
        title: "Total Text Messages",
      },
      {
        description: "Inbound message segments received via this account",
        slug: "inbound_text_messages",
        title: "Inbound Text Messages",
      },
      {
        description: "Outbound message segments received via this account",
        slug: "outbound_text_messages",
        title: "Outbound Text Messages",
      },
    ],
    id:
      overrides.id ||
      "/sinch-dev/analytics_reports/message_usage?account=accounts%2Fsinch-dev&descendants=false&endDate=2022-04-29T05%3A59%3A59.999Z&startDate=2022-04-21T06%3A00%3A00.000Z",
    startDate: "2022-04-21T06:00:00.000000Z",
    title: "Message Usage",
    totalCount: 1,
  };
};

export const monthOptions = () => {
  return [
    {
      value: "May 2022",
      startDate: "2022-05-01T06:00:00.000Z",
      endDate: "2022-05-05T05:59:59.999Z",
    },
    {
      value: "April 2022",
      startDate: "2022-04-01T06:00:00.000Z",
      endDate: "2022-05-01T05:59:59.999Z",
    },
    {
      value: "March 2022",
      startDate: "2022-03-01T06:00:00.000Z",
      endDate: "2022-04-01T05:59:59.999Z",
    },
    {
      value: "February 2022",
      startDate: "2022-02-01T06:00:00.000Z",
      endDate: "2022-03-01T05:59:59.999Z",
    },
    {
      value: "January 2022",
      startDate: "2022-01-01T06:00:00.000Z",
      endDate: "2022-02-01T05:59:59.999Z",
    },
    {
      value: "December 2021",
      startDate: "2021-12-01T06:00:00.000Z",
      endDate: "2022-01-01T05:59:59.999Z",
    },
    {
      value: "November 2021",
      startDate: "2021-11-01T06:00:00.000Z",
      endDate: "2021-12-01T05:59:59.999Z",
    },
    {
      value: "October 2021",
      startDate: "2021-10-01T06:00:00.000Z",
      endDate: "2021-11-01T05:59:59.999Z",
    },
    {
      value: "September 2021",
      startDate: "2021-09-01T06:00:00.000Z",
      endDate: "2021-10-01T05:59:59.999Z",
    },
    {
      value: "August 2021",
      startDate: "2021-08-01T06:00:00.000Z",
      endDate: "2021-09-01T05:59:59.999Z",
    },
    {
      value: "July 2021",
      startDate: "2021-07-01T06:00:00.000Z",
      endDate: "2021-08-01T05:59:59.999Z",
    },
    {
      value: "June 2021",
      startDate: "2021-06-01T06:00:00.000Z",
      endDate: "2021-07-01T05:59:59.999Z",
    },
  ];
};

export const keyword = (overrides: any = {}) => {
  return {
    attachments: [],
    keyword: "New Keyword",
    responseBody: "New Auto-Response",
    createdBy: "TextUs",
    ...overrides,
  };
};

export const keywordResponse = (overrides: any = {}) => {
  return {
    page: {
      firstUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=true\u0026q[keywordCont]=\u0026items=10\u0026page=1",
      prevUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=true\u0026q[keywordCont]=\u0026items=10\u0026page=",
      pageUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=true\u0026q[keywordCont]=\u0026items=10\u0026page=1",
      nextUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=true\u0026q[keywordCont]=\u0026items=10\u0026page=2",
      lastUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=true\u0026q[keywordCont]=\u0026items=10\u0026page=2",
      count: 16,
      page: 1,
      pages: 2,
      items: 10,
      from: 1,
      to: 10,
    },
    items: [
      {
        id: "ZNZ6N6",
        keyword: "DEL",
        responseBody: "Delete me?",
        active: true,
        locked: false,
        hidden: false,
        timesReceived: 0,
        contactCount: 0,
        createdBy: "Twilio Dev",
        createdAt: "2023-12-18T19:32:56.774420Z",
        deletedAt: null,
        sequences: [],
        attachments: [],
      },
      {
        id: "kY16Lm",
        keyword: "JOBS",
        responseBody: "Interested in jobs? Text us today!",
        active: true,
        locked: false,
        hidden: false,
        timesReceived: 0,
        contactCount: 0,
        createdBy: "Twilio Dev",
        createdAt: "2023-11-15T20:55:11.109658Z",
        deletedAt: null,
        sequences: [
          {
            id: "BYoaNE",
            name: "Sequence001",
          },
        ],
        attachments: [],
      },
    ],
  };
};

export const inactiveKeywordResponse = (overrides: any = {}) => {
  return {
    page: {
      firstUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=false\u0026q[keywordCont]=\u0026items=10\u0026page=1",
      prevUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=false\u0026q[keywordCont]=\u0026items=10\u0026page=",
      pageUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=false\u0026q[keywordCont]=\u0026items=10\u0026page=1",
      nextUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=false\u0026q[keywordCont]=\u0026items=10\u0026page=2",
      lastUrl:
        "/api/v4/twilio-dev/automation_keywords?q[active]=false\u0026q[keywordCont]=\u0026items=10\u0026page=2",
      count: 13,
      page: 1,
      pages: 2,
      items: 10,
      from: 1,
      to: 10,
    },
    items: [
      {
        id: "aY0WlA",
        keyword: "CASH",
        responseBody: "Need some cash?",
        active: false,
        locked: false,
        hidden: false,
        timesReceived: 0,
        contactCount: 0,
        createdBy: "Twilio Dev",
        createdAt: "2023-12-18T14:48:52.042365Z",
        deletedAt: "2024-01-03T14:45:37.119469Z",
        sequences: [],
        attachments: [],
      },
    ],
  };
};

export const accountPlans = () => {
  return [
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: false,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/BYoaNE",
      name: "Analytics Pro | Monthly",
      quantity: 1,
      shared: true,
      size: null,
      type: "analytics",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/JYnGLo",
      name: "Campaign Pro | Monthly",
      quantity: 5,
      shared: false,
      size: null,
      type: "campaigns",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6LR9Yj",
      name: "Keywords | Monthly",
      quantity: 1,
      shared: false,
      size: null,
      type: "keywords",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
    {
      "@context": "/contexts/AccountPlan.jsonld",
      "@type": "AccountPlan",
      assignable: true,
      assignments: [],
      effectiveEndDate: "2023-07-15",
      effectiveStartDate: "2022-07-08",
      id: "/textus-services/account_plans/6NvZNG",
      name: "Pro Msg Account: Up to 2k Msg/Month",
      quantity: 2,
      shared: false,
      size: 2000,
      type: "message",
    },
  ];
};

export const contactWithCustomSchema = () => {
  return {
    "@type": "Contact",
    "@context": "/contexts/Contact.jsonld",
    id: "/contexts/Contact.jsonId",
    name: "Adelard Took",
    firstName: "Adelard",
    lastName: "Took",
    conversations: "/contacts/7LdVlM/conversations",
    createdAt: "2022-09-19T17:34:55.203564Z",
    data: {
      business: "TextUs",
      tags: [],
      bullhorn: {
        Candidate: {
          id: 226,
          link: "https://www.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=226",
          owner: { id: 73, firstName: "Product", lastName: "Team" },
          occupation: "Here it is!",
          status: ["Active"],
          email: "test@textus.com",
          prefix: "Mrs.",
        },
      },
    },
    notes: {
      id: "/contacts/7LdM12/notes",
      members: [],
      totalItems: 0,
    },
    phones: contactPhoneCollection({
      members: [contactPhone()],
    }),
    memberships: [],
  };
};

export const sequence = () => {
  return {
    contactCount: 6,
    createdBy: "Mock User",
    daySpan: 4,
    deliveryRate: 100,
    engagementRate: 100,
    enrollers: ["Cam Newton"],
    failedCount: 1,
    finishedCount: 3,
    finishedOnReply: true,
    id: "W1234T",
    linkedKeyword: {
      id: "AutomationKeywordId",
      keyword: "AutomationKeywordName",
    },
    name: "Nifty Sequence",
    noResponseCount: 1,
    omittedCount: 1,
    pendingCount: 0,
    respondedCount: 1,
    scheduleType: "relative",
    settings: {
      deliveryWindow: {
        startDay: 0,
        endDay: 6,
        startTime: 640,
        endTime: 1140,
      },
    },
    state: "active",
    stepCount: 0,
    stoppedCount: 1,
    updatedAt: new Date().toISOString(),
  };
};

export const sequenceList = () => {
  return [
    {
      contactCount: 6,
      createdBy: "Mock User",
      daySpan: 10,
      deliveryRate: 100,
      engagementRate: 100,
      failedCount: 1,
      finishedCount: 3,
      finishedOnReply: true,
      id: "W1234T",
      linkedKeyword: { id: null, keyword: null },
      name: "Nifty Sequence",
      noResponseCount: 1,
      omittedCount: 1,
      pendingCount: 0,
      respondedCount: 1,
      stepCount: 2,
      scheduleType: "relative",
      state: "active",
      stoppedCount: 1,
      updatedAt: new Date().toISOString(),
    },
    {
      contactCount: 50,
      createdBy: "Mock User",
      daySpan: 1,
      deliveryRate: 75,
      engagementRate: 50,
      failedCount: 1,
      finishedCount: 3,
      finishedOnReply: false,
      id: "3636gw",
      linkedKeyword: { id: null, keyword: null },
      name: "Cool Sequence",
      noResponseCount: 1,
      omittedCount: 1,
      pendingCount: 3,
      respondedCount: 1,
      stepCount: 4,
      scheduleType: "relative",
      state: "active",
      stoppedCount: 1,
      updatedAt: new Date().toISOString(),
    },
    {
      contactCount: 90,
      createdBy: "Mock User",
      daySpan: 8,
      deliveryRate: 80,
      engagementRate: 80,
      failedCount: 1,
      finishedCount: 3,
      finishedOnReply: true,
      id: "5g25y3",
      linkedKeyword: { id: "12345", keyword: "test" },
      name: "Rad Sequence",
      noResponseCount: 1,
      omittedCount: 1,
      pendingCount: 12,
      respondedCount: 1,
      stepCount: 8,
      scheduleType: "relative",
      state: "archive",
      stoppedCount: 1,
      updatedAt: new Date().toISOString(),
    },
  ];
};

export const sequenceStep = () => {
  return {
    id: "WL87Yw",
    absoluteTime: "",
    body: 'Hello {{contact.firstName | default: "friend"}}!',
    compoundedDay: 1,
    relativeDelay: {
      days: 0,
      hours: 0,
      minutes: 5,
    },
    deliveredCount: 2,
    deliveryRate: 10,
    failedRate: 10,
    failedCount: 2,
    finishedCount: 2,
    linksCount: 2,
    linksRate: 10,
    omittedCount: 0,
    optedOutCount: 1,
    pendingCount: 1,
    respondedCount: 2,
    respondedRate: 10,
    stepNumber: 1,
  };
};

export const sequencePagination = () => {
  return {
    firstUrl:
      "/api/v4/textus-services/sequences/JYnGLo/sequence_recipients?page=1&items=20",
    prevUrl:
      "/api/v4/textus-services/sequences/JYnGLo/sequence_recipients?page=&items=20",
    pageUrl:
      "/api/v4/textus-services/sequences/JYnGLo/sequence_recipients?page=1&items=20",
    nextUrl:
      "/api/v4/textus-services/sequences/JYnGLo/sequence_recipients?page=2&items=20",
    lastUrl:
      "/api/v4/textus-services/sequences/JYnGLo/sequence_recipients?page=3&items=20",
    count: 42,
    page: 1,
    pages: 3,
    items: 20,
    from: 1,
    to: 20,
  };
};

export const sequenceContact = () => {
  return [
    {
      id: "JYnGLo",
      contactId: "5g25y3",
      name: "Steve McQueen",
      phoneNumber: "(555) 555-5555",
      state: "pending",
      reason: "not finished",
      enrolledAt: new Date().toISOString(),
      currentStep: 1,
      user: {
        enroller: "W1234T",
        type: "User",
        enrollerAvatar: "image.jpg",
      },
    },
  ];
};
