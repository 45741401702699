import { useEffect } from "react";

import { Alert, Box, CircularProgress, Divider } from "@mui/material";

import { IndividualSequenceOverviewProps, StepValues } from "./types";
import { checkIfNeedsScheduling } from "./utils";
import { INDIVIDUAL_SEQUENCE_TABS, SEQUENCE_STATES } from "./constants";
import { MODAL_SELECTIONS } from "features/Sequences/components/CreateSequenceModal/constants";

import { createStep, updateStep } from "features/Sequences/api";

import { Dialog } from "components/Dialog";
import PageContent from "components/Page/PageContent";
import PageHeader from "components/Page/PageHeader";

import { AddStepModal } from "features/Sequences/components/AddStepModal";
import { ArchiveSequenceDialog } from "features/Sequences/components/ArchiveSequenceDialog";
import { CreateSequenceModal } from "features/Sequences/components/CreateSequenceModal";
import { DeleteStepDialog } from "features/Sequences/components/DeleteStepDialog";
import { EnrollContactsDrawer } from "features/Sequences/components/Drawers";
import { IndividualSequenceButton } from "features/Sequences/components/IndividualSequenceButton";
import { IndividualSequenceTabs } from "features/Sequences/components/IndividualSequenceTabs";
import { RefreshTimer } from "features/Sequences/components/RefreshTimer";
import { SequencesContactsTab } from "features/Sequences/components/SequencesContactsTab";
import { SequenceStats } from "features/Sequences/components/SequenceStats";
import { SequenceStepAccordion } from "features/Sequences/components/SequenceStepAccordion";

import { useDuplicateSequence } from "features/Sequences/hooks/useDuplicateSequence";
import { useIndividualSequence } from "features/Sequences/hooks/useIndividualSequence";
import { useRefreshTimer } from "features/Sequences/hooks/useRefreshTimer";
import { useSequenceSteps } from "features/Sequences/hooks/useSequenceSteps";
import { useArchiveSequenceDialog } from "features/Sequences/hooks/useArchiveSequence";
import { useActivateSequence } from "features/Sequences/hooks/useActivateSequence";
import { useDeleteStepDialog } from "features/Sequences/hooks/useDeleteStep";
import { SettingsTabContent } from "features/Sequences/components/SettingsTabContent";
import { BreadcrumbLinks } from "features/Sequences/components/BreadcrumbLinks";
import { IndividualScreenBody } from "features/Sequences/components/IndividualScreenBody";
import { TabPanel } from "components/TabPanel";

function IndividualSequenceOverview({
  currentAccount,
  isSinglePanel,
  returnPath,
  sequenceId,
  showSidebar,
  toggleSidebar,
  unlinkKeyword,
}: IndividualSequenceOverviewProps) {
  const { overview, contacts, settings } = INDIVIDUAL_SEQUENCE_TABS;
  const { duplicate } = MODAL_SELECTIONS;
  const { archive, inactive } = SEQUENCE_STATES;

  const { lastRefreshTimestamp, updateRefreshTimestamp } = useRefreshTimer();

  const {
    addStepDialogRef,
    individualSequence,
    isEnrollDrawerOpen,
    isLoadingIndividualSequence,
    rowState,
    selectedEnrollees,
    selectedTab,

    getSequence,
    handleChange,
    handleConfirmEnroll,
    openAddStepDialog,
    setSelectedEnrollees,
    toggleEnrollDrawer,
  } = useIndividualSequence(currentAccount, sequenceId);

  const {
    isLoadingSteps,
    noSteps,
    sequenceSteps,
    stepsComplete,
    getSequenceSteps,
    startLoadingSteps,
    stopLoadingSteps,
  } = useSequenceSteps(currentAccount);

  const {
    duplicateSequenceDialogRef,
    isLoadingDuplicate,
    handleDuplicateSequenceClick,
  } = useDuplicateSequence(returnPath);

  const {
    finishedOnReply,
    name: sequenceName,
    scheduleType,
    stepCount,
  } = individualSequence;

  const {
    isLoadingActivate,
    handleActivateSequence,
    handleDeactivateSequence,
  } = useActivateSequence();

  const {
    archiveSequenceDialogRef,
    isLoadingArchive,
    openArchiveSequenceDialog,
  } = useArchiveSequenceDialog();

  const needsScheduling = checkIfNeedsScheduling(sequenceSteps);

  const { deleteStepDialogRef, openDeleteStepDialog } =
    useDeleteStepDialog(currentAccount);

  const TabMap = {
    [overview]: 0,
    [contacts]: 1,
    [settings]: 2,
  };

  const buttonText = selectedTab === TabMap.Overview ? "Step" : "Contacts";
  const isArchived = individualSequence.state === archive;
  const allLoaded =
    !isLoadingSteps &&
    !isLoadingDuplicate &&
    !isLoadingArchive &&
    !isLoadingIndividualSequence &&
    !isLoadingActivate;

  useEffect(() => {
    getSequenceSteps(sequenceId);
  }, [getSequenceSteps, sequenceId]);

  useEffect(() => {
    getSequence();
  }, [getSequence, rowState.rows]);

  const refreshPage = (): void => {
    getSequence();
    getSequenceSteps(sequenceId);
    updateRefreshTimestamp();
  };

  const toggleAddStepDialog = (sequenceStepIndex?: number): void => {
    openAddStepDialog({ sequenceStepIndex }, async (values) => {
      if (values) {
        let response: Response;

        try {
          startLoadingSteps();
          // If we have sequence step data, we must be in the edit flow.
          if (typeof sequenceStepIndex === "number") {
            const sequenceStepId = sequenceSteps[sequenceStepIndex].id;
            response = await updateStep(currentAccount, values, sequenceStepId);
          } else {
            response = await createStep(currentAccount, values, sequenceId);
          }
        } catch (error: any) {
          throw new Error(`Error adding sequence step: ${error}`);
        } finally {
          // Loading stop handled in getSequenceSteps() in refreshPage()
          refreshPage();
        }
      }
    })();
  };

  const renderSteps = () => {
    return isLoadingSteps || isLoadingDuplicate || isLoadingArchive ? (
      <CircularProgress size={64} />
    ) : (
      sequenceSteps.map((step, idx) => {
        return (
          <SequenceStepAccordion
            key={step.id}
            index={idx}
            menuDisabled={isArchived}
            needsScheduling={needsScheduling}
            step={step}
            handleEditStep={() => {
              toggleAddStepDialog(idx);
            }}
            handleDeleteStep={() => {
              openDeleteStepDialog(
                step,
                refreshPage,
                startLoadingSteps,
                stopLoadingSteps,
              );
            }}
          />
        );
      })
    );
  };

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        aria-label="Individual Sequence Detail Page"
        title={
          <BreadcrumbLinks
            returnPath={returnPath}
            sequenceName={sequenceName}
          />
        }
        toggleSidebar={toggleSidebar}
      >
        <RefreshTimer
          lastRefreshTimestamp={lastRefreshTimestamp}
          clickHandler={refreshPage}
        />
      </PageHeader>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <IndividualSequenceTabs
          handleChange={handleChange}
          isLoading={isLoadingIndividualSequence || isLoadingDuplicate}
          refreshPage={refreshPage}
          selectedTab={selectedTab}
          sequence={individualSequence}
          sequenceSteps={sequenceSteps}
          handleEditStep={() => {
            toggleAddStepDialog(0);
          }}
          handleActivateSequence={handleActivateSequence}
          handleDeactivateSequence={handleDeactivateSequence}
        />
        {selectedTab === TabMap.Settings ? null : (
          <IndividualSequenceButton
            selectedTab={selectedTab}
            sequenceHasNoSteps={noSteps}
            sequence={individualSequence}
            stepsComplete={stepsComplete}
            toggleAddStepDialog={toggleAddStepDialog}
            toggleEnrollDrawer={toggleEnrollDrawer}
            handleArchiveSequenceClick={() => {
              openArchiveSequenceDialog(individualSequence, () => {
                refreshPage();
              });
            }}
            handleDuplicateSequenceClick={() => {
              handleDuplicateSequenceClick(individualSequence);
            }}
            text={buttonText}
          />
        )}
      </Box>

      <Divider />

      {/* ==== OVERVIEW TAB CONTENTS ==== */}
      <TabPanel
        value={selectedTab}
        index={0}
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          overflowY: "scroll",
        }}
      >
        <SequenceStats
          individualSequence={individualSequence}
          isLoading={
            isLoadingIndividualSequence ||
            isLoadingDuplicate ||
            isLoadingArchive
          }
        />
        {isArchived && allLoaded ? (
          <Alert
            severity="info"
            sx={{ margin: "0rem 0rem 1.5rem 1.5rem", width: "30rem" }}
          >
            This sequence cannot be unarchived, but can still be duplicated.
          </Alert>
        ) : null}
        <Box
          alignItems="center"
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          justifyContent={noSteps ? "center" : "start"}
          paddingX="24px"
          width="100%"
        >
          {allLoaded ? (
            <IndividualScreenBody
              isArchived={isArchived}
              noSteps={noSteps}
              toggleAddStepDialog={toggleAddStepDialog}
              renderSteps={renderSteps}
            />
          ) : (
            <CircularProgress size={64} />
          )}
        </Box>
      </TabPanel>

      <EnrollContactsDrawer
        hideBackdrop
        isOpen={isEnrollDrawerOpen}
        selectedEnrollees={selectedEnrollees}
        sequenceStepOne={sequenceSteps[0]}
        handleClose={toggleEnrollDrawer}
        handleConfirm={handleConfirmEnroll}
        setSelectedEnrollees={setSelectedEnrollees}
      />

      {/* ==== CONTACTS TAB CONTENTS ==== */}
      <TabPanel value={selectedTab} index={1} sx={{ height: "100%" }}>
        <SequencesContactsTab
          disableAddContacts={
            noSteps ||
            (individualSequence.state !== "active" &&
              individualSequence.state !== "" &&
              [archive, inactive].includes(individualSequence.state))
          }
          individualSequence={individualSequence}
          isLoadingNewRecipient={isLoadingIndividualSequence}
          refreshPage={refreshPage}
          rowState={rowState}
          showSidebar={showSidebar}
          toggleEnrollDrawer={toggleEnrollDrawer}
        />
      </TabPanel>

      {/* ==== SETTINGS TAB CONTENTS ==== */}
      <TabPanel
        value={selectedTab}
        index={2}
        sx={{ height: "100%", overflowY: "scroll" }}
      >
        <SettingsTabContent
          sequence={individualSequence}
          refreshPage={refreshPage}
          unlinkKeyword={unlinkKeyword}
        />
      </TabPanel>

      {/* ==== ADD STEP DIALOG ==== */}
      <Dialog<any, StepValues | false> ref={addStepDialogRef}>
        {({ close, parameters = {} }) => {
          const { sequenceStepIndex } = parameters;

          return (
            <AddStepModal
              close={close}
              finishedOnReply={finishedOnReply}
              scheduleType={scheduleType}
              sequenceSteps={sequenceSteps}
              sequenceStepIndex={sequenceStepIndex}
              stepCount={stepCount}
            />
          );
        }}
      </Dialog>

      {/* ==== DUPLICATE SEQUENCE DIALOG ==== */}
      <Dialog<any, any> ref={duplicateSequenceDialogRef}>
        {({ close }) => {
          return (
            <CreateSequenceModal
              close={close}
              initialSelection={duplicate}
              sequenceValues={{
                sequenceName: `Copy of ${individualSequence.name}`,
                scheduleType: individualSequence.scheduleType,
                finishedOnReply: String(individualSequence.finishedOnReply),
              }}
            />
          );
        }}
      </Dialog>

      {/* ==== ARCHIVE SEQUENCE DIALOG ==== */}
      <Dialog<any, boolean> ref={archiveSequenceDialogRef}>
        {({ close }) => {
          return <ArchiveSequenceDialog close={close} refresh={refreshPage} />;
        }}
      </Dialog>

      {/* ==== DELETE STEP DIALOG ==== */}
      <Dialog<any, boolean> ref={deleteStepDialogRef}>
        {({ close }) => {
          return <DeleteStepDialog close={close} />;
        }}
      </Dialog>
    </PageContent>
  );
}

export { IndividualSequenceOverview };
