import { Component } from "react";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class NewCampaignContactCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "Campaigns/containers/NewCampaignContactCollection",
  shape: { members: [{ phones: { members: [] } }] },
  showLoader: () => {
    return false;
  },
  type: "contactCollection",
})(NewCampaignContactCollection);
