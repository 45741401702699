import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import Table from "components/Table";
import TableWrapper from "features/Campaigns/components/TableWrapper";

const ContactName = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-weight: 700;
`;

const ContactNumber = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 0.75rem;
`;

export default class Root extends Component {
  static propTypes = {
    contactCollection: PropTypes.object.isRequired,
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (contact) => {
          return contact.name;
        },
      },
      {
        title: "Primary Number",
        getTableCellContent: (contact) => {
          return get(contact, ["phones", "members", 0, "formattedPhoneNumber"]);
        },
        nowrap: true,
      },
      {
        title: "Business Name",
        getTableCellContent: (contact) => {
          return contact?.data?.business || "";
        },
      },
    ];
  };

  getCollapsedColumns = () => {
    return [
      {
        title: "Contact",
        getTableCellContent: (contact) => {
          const contactName = contact.name;
          const contactNumber = get(contact, [
            "phones",
            "members",
            0,
            "formattedPhoneNumber",
          ]);
          return (
            <div>
              <ContactName>{contactName}</ContactName>
              {contactNumber && <ContactNumber>{contactNumber}</ContactNumber>}
            </div>
          );
        },
      },
    ];
  };

  render() {
    const { contactCollection } = this.props;
    return (
      <TableWrapper>
        <Table
          collapsedColumns={this.getCollapsedColumns()}
          columns={this.getColumns()}
          collection={contactCollection}
        />
      </TableWrapper>
    );
  }
}
