import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { Tooltip } from "@mui/material";
import { ContactSyncHyperlink } from "./ContactSyncHyperlink";
import { Contact } from "features/ContactSidebar/types";
import { capitalizedNames } from "features/ContactSync/constants";
import { IntegrationIcon } from "icons";

interface ContactDetailProps {
  contact: Contact;
  conversationName: string;
  number: string;
}

function ContactDetails({
  contact,
  conversationName,
  number,
}: ContactDetailProps) {
  
  const getIntegrationNames = () => {
    if (contact?.data) {
      const { bullhorn, greenhouse } = capitalizedNames;
      return Object.keys(contact.data)
        .map((integration) => {
          return capitalize(integration);
        })
        .filter((integration) => {
          return integration === bullhorn || integration === greenhouse;
        });
    }
    return [];
  };

  const integrationNames = getIntegrationNames();

  return (
    <Box
      mr="20px"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="center"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      minWidth={0}
    >
      <Box display="flex" flexDirection="row" width="12rem" alignItems="center">
        <Typography
          key="contact-name"
          variant="h6"
          color="primary.main"
          sx={(theme) => {
            return {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            };
          }}
        >
          {conversationName || number}
        </Typography>
        {integrationNames.length > 0 ? (
          <Tooltip
            title={`Linked to ${integrationNames.join(", ")}`}
            placement="top-start"
          >
            <span>
              <IntegrationIcon sx={{ marginLeft: "0.25rem" }} />
            </span>
          </Tooltip>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="row" width="12rem" alignItems="center">
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          {conversationName && number}
        </Typography>
        <ContactSyncHyperlink contact={contact} />
      </Box>
    </Box>
  );
}

export default ContactDetails;
