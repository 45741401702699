export default (email) => {
  const emailDomain = email.split("@")[1];
  const loginDomains = window?.nuauth?.login_domains;
  if (emailDomain) {
    return Object.keys(loginDomains).reduce((loginMethods, domain) => {
      if (domain === emailDomain) {
        const { provider } = loginDomains[domain] ?? {};
        return loginMethods.concat(provider);
      }
      return loginMethods;
    }, []);
  }
  return [];
};
