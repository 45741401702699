import { ColumnHeader } from "../models/AutomationModels";

const keywordsColumnHeaders: readonly ColumnHeader[] = [
  {
    id: "keyword",
    numeric: false,
    label: "Keyword",
  },
  {
    id: "responseBody",
    numeric: false,
    label: "Auto-Response",
  },
  {
    id: "timesReceived",
    numeric: true,
    label: "Times Received",
  },
  {
    id: "createdBy",
    numeric: false,
    label: "Created By",
  },
  {
    id: "createdAt",
    numeric: false,
    label: "Created At",
  },
];

const discardedKeywordsColumnHeaders: readonly ColumnHeader[] = [
  {
    id: "keyword",
    numeric: false,
    label: "Keyword",
  },
  {
    id: "responseBody",
    numeric: false,
    label: "Auto-Response",
  },
  {
    id: "timesReceived",
    numeric: true,
    label: "Times Received",
  },
  {
    id: "createdBy",
    numeric: false,
    label: "Created By",
  },
  {
    id: "deletedAt",
    numeric: false,
    label: "Deleted At",
  },
];

const keywordGroupsColumnHeader: readonly ColumnHeader[] = [
  {
    id: "keyword",
    label: "Group",
    numeric: false,
  },
  {
    id: "sequences",
    label: "Sequences linked",
    numeric: false,
  },
  {
    id: "numberOfContacts",
    label: "Number of contacts",
    numeric: false,
  },
  {
    id: "createdBy",
    label: "Created by",
    numeric: false,
  },
  {
    id: "createdAt",
    label: "Created at",
    numeric: false,
  },
];

const ACCOUNT_LICENSES = {
  campaigns: "campaigns",
  keywords: "keywords",
  sequences: "sequences",
  support: "support",
  analytics: "analytics",
  message: "message",
} as const;

export {
  ACCOUNT_LICENSES,
  discardedKeywordsColumnHeaders,
  keywordsColumnHeaders,
  keywordGroupsColumnHeader,
};
