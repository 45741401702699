import { useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { matchPath, Switch, Route, Redirect } from "react-router-dom";
import get from "lodash/get";

import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";

import BlankState from "components/BlankState";
import CreateGroupModal from "components/CreateGroupModal";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import Group from "features/Groups/pages/Group";
import GroupFilter from "features/Groups/pages/GroupFilter";
import GroupsSidebar from "features/Groups/GroupsSidebar";
import InfoTooltip from "components/InfoTooltip";
import PageContent from "components/Page/PageContent";
import PageSearchHeader from "components/Page/PageSearchHeader";
import PageSection from "components/Page/PageSection";
import PageWrapper from "components/Page/PageWrapper";
import SearchGraphic from "utils/images/contact-search-blank-state.svg";
import withSidebar from "higherOrderComponents/withSidebar";

function Groups({
  currentAccount,
  isSinglePanel,
  location,
  match,
  history,
  showSidebar,
  toggleSidebar,
}) {
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const { pathname } = location;
  const searchMode = pathname.includes("search");

  const handleBackClick = () => {
    history.push({
      pathname: `/${currentAccount.slug}/groups/mine`,
    });
  };

  const handleClear = () => {
    const { search } = location;
    if (search) {
      history.push({
        pathname: `/${currentAccount.slug}/groups/search`,
      });
    }
  };

  const handleSearch = (q) => {
    history.push({
      pathname: `/${currentAccount.slug}/groups/search`,
      search: `?q=${fixedEncodeURIComponent(q)}`,
    });
  };

  const toggleSearch = () => {
    const nextPathname = pathname.includes("search")
      ? `/${currentAccount.slug}/groups/mine`
      : `/${currentAccount.slug}/groups/search`;
    if (isSinglePanel) toggleSidebar();
    history.push({ pathname: nextPathname });
  };

  const getGroupFilterIdFromUrl = () => {
    const { search } = location;
    const currentMatch = matchPath(pathname, {
      path: "/:accountSlug/groups/:activeGroupFilterSlug",
    });
    const { filterSlug, query } =
      get(currentMatch, ["params", "activeGroupFilterSlug"]) === "search"
        ? { filterSlug: "accounts", query: search || "?q=" }
        : { filterSlug: currentMatch.params.activeGroupFilterSlug, query: "" };
    return currentMatch
      ? `/${currentMatch.params.accountSlug}/group_filters/${filterSlug}${query}`
      : undefined;
  };

  const getSearchValue = () => {
    const { search } = location;
    const { q: urlQuery } = queryString.parse(search);
    return urlQuery;
  };

  const renderGroupFilter = (routerProps) => {
    return (
      <GroupFilter
        {...routerProps}
        groupFilterId={getGroupFilterIdFromUrl()}
        currentAccount={currentAccount}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
    );
  };

  return (
    <PageWrapper>
      <CreateGroupModal
        closeModal={() => {
          return setGroupModalVisible(false);
        }}
        currentAccount={currentAccount}
        visible={groupModalVisible}
      />
      <GroupsSidebar
        currentAccount={currentAccount}
        isSinglePanel={isSinglePanel}
        history={history}
        handleOpenCreateGroupModal={() => {
          return setGroupModalVisible(true);
        }}
        toggleSearch={toggleSearch}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
      <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
        {searchMode && (
          <Box position="relative" width="100%">
            <InfoTooltip title="Begin typing a group name and hit enter to search!">
              <Box
                alignItems="center"
                color="text.secondary"
                display="flex"
                height="100%"
                left="3rem"
                position="absolute"
                px={1}
                style={{ zIndex: 1 }}
              >
                <InfoIcon color="inherit" style={{ cursor: "help" }} />
              </Box>
            </InfoTooltip>
            <PageSearchHeader
              autoFocus={!isSinglePanel}
              backClickHandler={handleBackClick}
              clearHandler={handleClear}
              localStorageKey="recentGroupQueries"
              placeholder="Search Groups"
              searchHandler={handleSearch}
              styles={{
                input: (provided) => {
                  return {
                    ...provided,
                    paddingLeft: "2rem",
                  };
                },
                placeholder: (provided) => {
                  return {
                    ...provided,
                    paddingLeft: "2rem",
                  };
                },
              }}
              type="groups"
              value={getSearchValue()}
            />
          </Box>
        )}
        <PageSection>
          <Switch location={location}>
            <Route
              exact
              path={`${match.path}`}
              render={() => {
                return <Redirect to={`${match.url}/mine`.replace("//", "/")} />;
              }}
            />
            <Route
              exact
              path={`${match.path}/search`}
              render={(props) => {
                return props.location.search === "" ? (
                  <BlankState
                    image={<img src={SearchGraphic} alt="Search" />}
                    title="Search for groups"
                    subTitle="You can search by name to find an individual group"
                  />
                ) : (
                  renderGroupFilter(props)
                );
              }}
            />
            <Route
              exact
              path={`${match.path}/:groupFilterSlug`}
              render={(props) => {
                return (
                  <GroupFilter
                    {...props}
                    groupFilterId={`/${currentAccount.slug}/group_filters/${props.match.params.groupFilterSlug}`}
                    currentAccount={currentAccount}
                    toggleSidebar={toggleSidebar}
                    showSidebar={showSidebar}
                  />
                );
              }}
            />
            <Route
              exact
              path={`${match.path}/:groupFilterSlug/:groupSlug`}
              render={(props) => {
                return (
                  <Group
                    {...props}
                    currentAccount={currentAccount}
                    toggleSidebar={toggleSidebar}
                    showSidebar={showSidebar}
                    groupId={`/groups/${props.match.params.groupSlug}`}
                  />
                );
              }}
            />
          </Switch>
        </PageSection>
      </PageContent>
    </PageWrapper>
  );
}

Groups.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  isSinglePanel: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default withSidebar(Groups);
