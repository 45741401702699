import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import withSizes from "react-sizes";
import get from "lodash/get";
import uniq from "lodash/uniq";

import ApiTokens from "../containers/ApiTokens";
import BasicProfile from "../containers/BasicProfile";
import ScheduledAnalytics from "../containers/ScheduledAnalytics";
import Signature from "../containers/Signature";
import UserPersonalization from "../containers/UserPersonalization";
import ChangePassword from "./ChangePassword";
import Notifications from "./Notifications";
import withSidebar from "higherOrderComponents/withSidebar";

import PageWrapper from "components/Page/PageWrapper";
import PageContent from "components/Page/PageContent";
import PageSection from "components/Page/PageSection";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import List from "components/List";
import Avatar from "components/Avatar";

import breakpoints from "utils/styles/breakpoints";

const getProfileOptions = () => {
  return [
    {
      title: "Basic Profile",
      to: "/basic",
      OptionComponent: BasicProfile,
    },
    {
      title: "User Personalization",
      to: "/user-personalization",
      OptionComponent: UserPersonalization,
    },
    {
      title: "Notifications",
      to: "/notifications",
      OptionComponent: Notifications,
    },
    {
      title: "Signature",
      to: "/signature",
      OptionComponent: Signature,
    },
    {
      title: "API Tokens",
      to: "/api-tokens",
      OptionComponent: ApiTokens,
      extraProps: { userApiTokenCollectionId: "/user_api_tokens" },
    },
    ...[
      {
        title: "Change Password",
        to: "/change-password",
        OptionComponent: ChangePassword,
      },
    ],
    {
      title: "Scheduled Analytics",
      to: "/scheduled-analytics",
      OptionComponent: ScheduledAnalytics,
      isHidden: (currentUser, accountList) => {
        return accountList?.length === 0;
      },
    },
  ];
};

const ProfileOptions = styled(List)`
  flex: 0 0 auto;
`;

const ProfileOption = styled.li``;

const StyledPageSidebarHeader = styled(PageSidebarHeader)`
  justify-content: flex-start;
  font-size: 13px;
  font-weight: bold;
  padding: 0 20px;

  span {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

class Root extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSinglePanel: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    showSidebar: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    accountList: PropTypes.array.isRequired,
  };

  handleSettingsOptionClick = () => {
    if (this.props.isSinglePanel) {
      this.props.toggleSidebar();
    }
  };

  render() {
    const {
      currentUser,
      isSinglePanel,
      match,
      showSidebar,
      toggleSidebar,
      accountList,
    } = this.props;
    const profileOptions = getProfileOptions();
    const defaultPath = `${match.path}${profileOptions[0].to}`;
    const userSchemaIdsArray = uniq(
      get(currentUser, ["memberships", "members"], [])
        .filter(({ account }) => {
          return account.userSchema;
        })
        .map(({ account }) => {
          return account.userSchema;
        }),
    );
    return (
      <PageWrapper>
        <PageSidebar>
          <StyledPageSidebarHeader>
            <Avatar subject={currentUser} />
            <span>{currentUser.name}</span>
          </StyledPageSidebarHeader>
          <ProfileOptions>
            {profileOptions.map(({ title, to, isHidden }) => {
              if (isHidden && isHidden(currentUser, accountList)) {
                return null;
              }
              return (
                <ProfileOption key={title}>
                  <PageSidebarNavLink
                    aria-label={title}
                    to={`${match.url}${to}`.replace("//", "/")}
                    onClick={this.handleProfileOptionClick}
                  >
                    {title}
                  </PageSidebarNavLink>
                </ProfileOption>
              );
            })}
          </ProfileOptions>
        </PageSidebar>
        <PageContent
          isSinglePanel={isSinglePanel}
          showSidebar={showSidebar}
          id="PageContent"
        >
          <PageSection>
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                render={() => {
                  return <Redirect to={defaultPath} />;
                }}
              />
              {profileOptions.map(
                ({ OptionComponent, title, to, isHidden, extraProps }) => {
                  if (isHidden && isHidden(currentUser, accountList)) {
                    return null;
                  }
                  return (
                    <Route
                      key={title}
                      exact
                      path={`${match.path}${to}`}
                      render={(props) => {
                        return (
                          <OptionComponent
                            {...this.props}
                            {...props}
                            {...extraProps}
                            currentUser={currentUser}
                            toggleSidebar={toggleSidebar}
                            userSchemaId={userSchemaIdsArray[0]}
                            userSchemaIdsArray={userSchemaIdsArray}
                          />
                        );
                      }}
                    />
                  );
                },
              )}
              <Route
                path={`${match.path}`}
                render={() => {
                  return <Redirect to={defaultPath} />;
                }}
              />
            </Switch>
          </PageSection>
        </PageContent>
      </PageWrapper>
    );
  }
}

export default withSidebar(
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
      isSmall: width < breakpoints.medium,
      isMedium: width >= breakpoints.small,
      isMediumLarge: width >= 600,
      isLarge: width >= breakpoints.large,
    };
  })(Root),
);
