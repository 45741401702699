import { useState, useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";

import Fade from "@mui/material/Fade";
import ButtonLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import { lighten } from "polished";
import { Box, Typography, useMediaQuery } from "@mui/material";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import CampaignIcon from "icons/CampaignIcon";

const Bar = styled.div`
  display: flex;
  flex: 0 0 auto;
  height: 9px;
  width: 100%;
  border-radius: 20px;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all ease-in-out 0.15s;
`;

const getSentColor = (props) => {
  return props.length > 79
    ? props.theme.colors.error.main
    : props.theme.colors.accent.main;
};

const getScheduledColor = (props) => {
  return props.length > 79
    ? lighten(0.15, props.theme.colors.error.main)
    : lighten(0.15, props.theme.colors.accent.main);
};

const Scheduled = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  width: ${(props) => {
    return props.length;
  }}%;
  background: ${(props) => {
    return getScheduledColor(props);
  }};
  border-radius: 20px;
  z-index: 3;
  background-size: 200% 200%;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
`;

const Info = styled.div`
  font-weight: 500;

  b {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    font-weight: 700;
  }
`;

const PopperContent = styled.div`
  padding: 15px;
  width: 300px;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  white-space: normal;
  font-size: 14px;
  line-height: 28px;

  header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    p {
      margin: 0;
      margin-left: 12px;
      font-weight: 700;
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
    }
  }

  footer {
    font-size: 12px;
    padding: 10px;
  }

  button {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: ${(props) => {
        return props.theme.colors.secondary.main;
      }};
    }
  }
`;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
  height: 35px;

  p {
    margin: 0;
    line-height: 40px;
    font-weight: 600;
  }
`;

const LegendKey = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background: ${(props) => {
    return (
      {
        sent: getSentColor(props),
        scheduled: getScheduledColor(props),
      }[props.type] || props.theme.colors.background.paper
    );
  }};
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
`;

export default function MainView({ currentAccount, deliveryStats }) {
  const popperAnchorRef = useRef({});
  const hoverTimeout = useRef(null);
  const [open, setOpen] = useState(false);

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const supportLink = get(
    currentAccount,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const handleClick = () => {
    clearTimeout(hoverTimeout);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setOpen(false);
  };

  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setOpen(true);
    }, 300);
  };

  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  const {
    campaignDailyLimit,
    campaignMessagesScheduled,
    campaignMessagesUsed,
  } = deliveryStats;
  const sentLength = (campaignMessagesUsed / campaignDailyLimit) * 100;
  const scheduledLength =
    ((campaignMessagesScheduled + campaignMessagesUsed) / campaignDailyLimit) *
    100;
  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-product-tour="CampaignUsage"
      ref={popperAnchorRef}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="0.5rem 0.75rem 1rem 0.75rem"
      position="relative"
      flex="0 0 auto"
      flexFlow="column nowrap"
      overflow="hidden"
      height="auto"
      sx={(theme) => {
        return {
          borderBottom: `1px solid ${theme.palette.divider}`,
          cursor: campaignsResponsiveness && mobileScreen ? "auto" : "help",
        };
      }}
    >
      {campaignDailyLimit && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Info>
            {/* TODO: Wnen flag is removed, delete CampaignCutoffInfo */}
            {campaignsResponsiveness ? null : (
              <CampaignCutoffInfo currentAccount={currentAccount} />
            )}
            <Typography variant="label" color="text.secondary">
              {campaignMessagesUsed + campaignMessagesScheduled} of{" "}
              {campaignDailyLimit} messages used today
            </Typography>
          </Info>
          <Bar>
            <Box
              sx={(theme) => {
                return {
                  position: "absolute",
                  width: `${sentLength}%`,
                  top: 0,
                  left: 0,
                  height: "100%",
                  zIndex: 5,
                  borderRadius: "20px",
                  transition: "all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s",
                  background: theme.palette.primary.main,
                };
              }}
            />
            <Scheduled length={scheduledLength} />
          </Bar>
        </Box>
      )}
      {/* TODO: When flag is removed, delete Popper altogether */}
      {campaignsResponsiveness ? null : (
        <Popper
          anchorEl={popperAnchorRef.current}
          open={open}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <PopperContent
                    sentLength={sentLength}
                    scheduledLength={scheduledLength}
                  >
                    <header>
                      <CampaignIcon color="primary" />
                      <p>Daily Campaign Message Usage</p>
                    </header>
                    <LegendRow>
                      <LegendKey type="sent" length={sentLength} />
                      <p>Used: {campaignMessagesUsed}</p>
                    </LegendRow>
                    <LegendRow>
                      <LegendKey type="scheduled" length={scheduledLength} />
                      <p>Scheduled: {campaignMessagesScheduled}</p>
                    </LegendRow>
                    <LegendRow>
                      <LegendKey type="limit" />
                      <p>Daily Limit: {campaignDailyLimit}</p>
                    </LegendRow>
                    <footer>
                      {supportLink && (
                        <ButtonLink
                          href={supportLink}
                          style={{ verticalAlign: "baseline" }}
                          target="_new"
                        >
                          Contact support
                        </ButtonLink>
                      )}{" "}
                      to increase your limit.
                    </footer>
                  </PopperContent>
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      )}
    </Box>
  );
}

MainView.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  deliveryStats: PropTypes.object.isRequired,
};
