import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";

import { Box, Typography } from "@mui/material";

import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";
import { Uploader } from "components/Uploader";

const Upload = styled(ImportSection)`
  align-items: start;
  text-align: left;
`;

export default class ImportUpload extends Component {
  static propTypes = {
    handleFilesAccepted: PropTypes.func.isRequired,
    withHeaders: PropTypes.bool,
    withInstructionText: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  handleFilesRejected = (filesWithErrors) => {
    const error = get(
      filesWithErrors,
      [0, "errors", 0, "message"],
      "Something went wrong. Please try again.",
    );

    this.setState({ error }, () => {
      clearTimeout(this.clearErrorTimeout);
      this.clearErrorTimeout = setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    });
  };

  render() {
    const {
      handleFilesAccepted,
      withHeaders,
      withInstructionText,
    } = this.props;
    const { error } = this.state;

    return (
      <Upload>
        <Box marginBottom="40px">
          {withHeaders && (
            <Typography variant="h6" color="primary.main" mb={1}>
              Select contacts
            </Typography>
          )}
          {withInstructionText && (
            <Typography variant="body2" className="textus-Import-helperText">
              Please select a spreadsheet (.csv, .xlsx, .xls) to import. At a
              minimum the file must contain a name and phone number.
            </Typography>
          )}
        </Box>

        <Uploader
          acceptedFileTypes="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxFileSize={1024 * 1024}
          onFileAccepted={handleFilesAccepted}
          onFileRejected={this.handleFilesRejected}
          className={error ? "error" : ""}
          error={error}
        />
      </Upload>
    );
  }
}
