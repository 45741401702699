import { ChangeEvent, useCallback, useState } from "react";

import {
  RelativeDelayOptions,
  TimeIncrements,
} from "features/Sequences/components/AddStepModal/types";
import { useCurrentAccount, useCurrentUser } from "hooks";
import { createMessageBody } from "utils/createMessageBody";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { StepValues } from "features/Sequences/screens/IndividualSequenceOverview/types";

const useAddStepModal = (
  initialStepDelayValues: RelativeDelayOptions | null,
) => {
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const { settings, shortenedLinkPreviewUrl } = currentAccount;

  const [stepDelayValues, setStepDelayValues] = useState(
    initialStepDelayValues || {
      days: 0,
      hours: 0,
      minutes: 5,
    },
  );
  const [isSignatureActive, setIsSignatureActive] = useState(false);
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: shortenedLinkPreviewUrl,
  });
  const [previousSequenceDate, setPreviousSequenceDate] = useState<
    Date | undefined
  >(undefined);

  const getValueOptions = useCallback((timeIncrement: TimeIncrements) => {
    // 0-364
    const dayValues = Array.from(Array(365).keys()).map((number, index) => {
      return {
        label: index === 0 ? "None" : String(number),
        value: number,
      };
    });

    // 0-23
    const hourValues = Array.from(Array(24).keys()).map((number, index) => {
      return {
        label: index === 0 ? "None" : String(number),
        value: number,
      };
    });

    // 0-55 in increments of 5
    const minuteValues = Array.from(Array(60).keys())
      .filter((number) => {
        return number % 5 === 0;
      })
      .map((number, index) => {
        return {
          label: index === 0 ? "None" : String(number),
          value: number,
        };
      });

    const optionValues = {
      days: dayValues,
      hours: hourValues,
      minutes: minuteValues,
    };

    return optionValues[timeIncrement];
  }, []);

  const handleDelayChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "days" | "hours" | "minutes",
  ) => {
    setStepDelayValues({
      ...stepDelayValues,
      [field]: Number(event.target.value),
    });
  };

  const { campaignMaxMessageLength } = settings;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;

  const handleStepParams = (values: StepValues): StepValues => {
    const { body } = values;
    const messageBody = createMessageBody(
      body,
      isSignatureActive,
      currentUser.signature?.content ?? "",
      shortenedLink,
    );
    return { ...values, body: messageBody };
  };

  return {
    currentAccount,
    currentUser,
    isSignatureActive,
    maxCharacterLength,
    previousSequenceDate,
    shortenedLink,
    stepDelayValues,

    getValueOptions,
    handleDelayChange,
    handleStepParams,
    setIsSignatureActive,
    setPreviousSequenceDate,
    setShortenedLink,
  };
};

export { useAddStepModal };
