import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { ColumnHeader, KeywordGroupsProps } from "../models/AutomationModels";
import { keywordGroupsColumnHeader } from "../constants/KeywordConstants";
import { StyledHeaderTableCell } from "../components/styles";
import Loader from "components/Loader";

import BlankState from "components/BlankState";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { Keyword } from "models/Keyword";

function KeywordGroups({
  isSinglePanel,
  loading,
  keywords,
  links,
  page,
  rowsPerPage,
  showSidebar,
  getRow,
  handlePageChange,
  toggleSidebar,
}: KeywordGroupsProps) {
  if (!keywords?.items || loading) {
    return <Loader isLoading />;
  }

  const tableColumns = keywordGroupsColumnHeader;

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        aria-label={`Keywords Subscribers (${keywords.page.count})`}
        title={`Keywords Subscribers (${keywords.page.count})`}
        toggleSidebar={toggleSidebar}
      />
      {keywords?.items?.length ? (
        <TableContainer>
          <Table aria-label="Keywords Subscribers">
            <TableHead>
              <TableRow style={{ height: 28 }}>
                {tableColumns.map((cell: ColumnHeader) => {
                  return (
                    <StyledHeaderTableCell
                      key={cell.id}
                      align={cell.numeric ? "right" : "left"}
                    >
                      {cell.label}
                    </StyledHeaderTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {keywords.items.map((row: Keyword) => {
                return getRow(row, links);
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={keywords.page.count}
                  onPageChange={handlePageChange}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[rowsPerPage]}
                  style={{ borderBottom: "none" }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <BlankState
          image={<Logo color="disabled" />}
          title="No keywords subscribers found"
          subTitle="Create a keyword and auto-reponse on the 'Keywords' page"
        />
      )}
    </PageContent>
  );
}

export default KeywordGroups;
