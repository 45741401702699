import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { capitalize, get, isEmpty } from "lodash";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import { Box, Tooltip, Typography, styled, useMediaQuery } from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { handleResponse } from "@tesseract/core";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import BlankState from "components/BlankState";
import NoCampaigns from "utils/images/no-campaigns.svg";
import Avatar from "components/Avatar";
import { useLoading, useTimeZones } from "hooks";
import { fetchCampaignCollection } from "features/Campaigns/api";
import { campaignTypes, columnTooltips } from "features/Campaigns/constants";
import { createSnackbarContent } from "components/Snackbar";

const CampaignLink = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover, &:active, &:visited": {
      color: "inherit",
    },
  };
});

const getCursor = (url) => {
  const {
    query: { cursor },
  } = queryString.parseUrl(url);

  return cursor;
};

function CampaignCollection({
  campaignCollectionId,
  currentAccount,
  campaignType,
}) {
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  const { oneTime, recurring } = campaignTypes;

  const abortControllerRef = useRef(null);

  const {
    status,
    sentCount,
    totalRecipients,
    skipped,
    deliveryRate,
    failureRate,
    replyRate,
  } = columnTooltips;

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const { accountTimeZone, abbreviatedAccountTimeZone } = useTimeZones();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const [campaignCollection, setCampaignCollection] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 30,
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
  });
  const [pageCursor, setPageCursor] = useState();

  const getCampaigns = useCallback(() => {
    startLoading();

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    fetchCampaignCollection(
      campaignCollectionId,
      {
        type: campaignType,
        cursor: pageCursor,
      },
      { signal: controller.signal },
    )
      .then(handleResponse)
      .then((data) => {
        setCampaignCollection(data);
        return stopLoading();
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          enqueueSnackbar("Something went wrong. Please try again.", {
            content: createSnackbarContent("error"),
          });
        }
        console.error(error.message);
      });
  }, [
    campaignCollectionId,
    campaignType,
    enqueueSnackbar,
    pageCursor,
    startLoading,
    stopLoading,
  ]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const handlePageChange = (newPaginationModel) => {
    const collectionView = campaignCollection?.view;

    if (!collectionView) {
      return;
    }

    const newCursor =
      newPaginationModel.page < paginationModel.page
        ? getCursor(collectionView?.previous ?? "")
        : getCursor(collectionView?.next ?? "");

    setPageCursor(newCursor);
    setPaginationModel(newPaginationModel);
  };

  const getCampaignCardCellContent = ({ row }) => {
    const { id, createdAt, scheduledAt, lastSentAt } = row;
    // TODO: When flag is deleted, keep check for campaignType
    const displayDate =
      !campaignsResponsiveness || campaignType === oneTime
        ? scheduledAt ?? createdAt
        : lastSentAt ?? createdAt;

    return (
      <CampaignLink
        to={`${global.document.location.pathname}${id.replace(
          "/campaigns",
          "",
        )}`}
      >
        <Box alignItems="center" display="flex" padding="5px 0">
          <Tooltip title={get(row, ["user", "name"])} placement="bottom">
            <Box flex="0 0 auto" marginRight="10px">
              <Avatar subject={get(row, ["user"])} />
            </Box>
          </Tooltip>
          <Box flex="1 1 auto">
            <Box
              fontWeight={700}
              sx={{ "&:hover": { textDecoration: "underline" } }}
            >
              {row.title}
            </Box>
            <Box>
              {`${formatInTimeZone(new Date(displayDate), accountTimeZone, "MM/dd/yyyy h:mm a")} ${abbreviatedAccountTimeZone}`}
            </Box>
          </Box>
        </Box>
      </CampaignLink>
    );
  };

  const getCampaignStateIcon = ({ row }) => {
    const icon = (type) => {
      return {
        canceled: <CancelIcon color="error" />,
        scheduled: <WatchLaterIcon color="primary" />,
        sending: <SendIcon color="primary" />,
        sent: <CheckCircleIcon color="primary" />,
      }[type];
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height-="100%"
      >
        <Tooltip title={capitalize(row.state)}>
          <span>{icon(row.state)}</span>
        </Tooltip>
      </Box>
    );
  };

  const columns = [
    {
      field: "campaign",
      headerName: "Campaign",
      flex: 1,
      minWidth: 260,
      renderCell: getCampaignCardCellContent,
      valueGetter: ({ row }) => {
        return row.scheduledAt;
      },
    },
    {
      field: "status",
      headerName: "Status",
      description: status,
      flex: 1,
      align: "center",
      headerAlign: "center",
      maxWidth: 80,
      renderCell: getCampaignStateIcon,
      valueGetter: ({ row }) => {
        return row.state;
      },
    },
    {
      field: "totalRecipients",
      headerName: "Total recipients",
      description: totalRecipients,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        return row.deliveryStatistics.total;
      },
    },
    {
      field: "skippedRecipients",
      headerName: "Skipped recipients",
      description: skipped,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        return row.deliveryStatistics.skipped;
      },
    },
    {
      field: "sentCount",
      headerName: "No. sent",
      description: sentCount,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        return row.deliveryStatistics.sent;
      },
    },
    {
      field: "deliveryRate",
      headerName: "Delivery %",
      description: deliveryRate,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        const { sent, delivered } = row.deliveryStatistics;
        return sent > 0 ? Math.round((delivered / sent) * 100) : 0;
      },
      valueFormatter: ({ value }) => {
        return `${value}%`;
      },
    },
    {
      field: "replyRate",
      headerName: "Reply %",
      description: replyRate,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        const { delivered, replied } = row.deliveryStatistics;
        return delivered > 0 ? Math.round((replied / delivered) * 100) : 0;
      },
      valueFormatter: ({ value }) => {
        return `${value}%`;
      },
    },
    {
      field: "failureRate",
      headerName: "Failure %",
      description: failureRate,
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => {
        const { total, failed } = row.deliveryStatistics;
        return total > 0 ? Math.round((failed / total) * 100) : 0;
      },
      valueFormatter: ({ value }) => {
        return `${value}%`;
      },
    },
  ];

  const displayedColumns = mobileScreen ? columns.slice(0, 2) : columns;

  const getNoCampaignsMessage = () => {
    return (
      <div>
        Select another filter or create a{" "}
        <Link to={`/${currentAccount.slug}/campaigns/new`}>New Campaign</Link>
      </div>
    );
  };

  return (
    <Box>
      {campaignsResponsiveness ? (
        <Typography
          variant="h5"
          margin="2rem 1rem 1rem 1rem"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Sent {campaignType === oneTime ? "one-time" : recurring} campaigns (
          {campaignCollection.totalItems || 0})
        </Typography>
      ) : null}
      {isEmpty(campaignCollection) || campaignCollection.totalItems > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            position: "relative",
            // TODO: When flag is deleted, delete nested ternary and comment-in code beneatn
            height: campaignsResponsiveness
              ? mobileScreen
                ? "calc(100vh - 292px)"
                : "calc(100vh - 235px)"
              : "auto",
            // height: mobileScreen
            //   ? "calc(100vh - 292px)"
            //   : "calc(100vh - 235px)",
          }}
        >
          <DataGridPro
            pagination
            paginationMode="server"
            pageSizeOptions={[]}
            columns={displayedColumns}
            disableRowSelectionOnClick
            rows={campaignCollection?.members ?? []}
            rowCount={campaignCollection?.totalItems ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePageChange}
            loading={isLoading}
          />
        </Box>
      ) : (
        <Box
          height="100%"
          position="relative"
          flex="1 1 auto"
          marginTop="6rem"
          overflowY="scroll"
        >
          <BlankState
            image={<img src={NoCampaigns} alt="Search" />}
            title="No Campaigns Here"
            subComponent={getNoCampaignsMessage()}
          />
        </Box>
      )}
    </Box>
  );
}

CampaignCollection.propTypes = {
  campaignCollectionId: PropTypes.string.isRequired,
  currentAccount: PropTypes.object.isRequired,
  campaignType: PropTypes.string,
};

export { CampaignCollection };
