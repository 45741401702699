import PropTypes from "prop-types";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroller";
import get from "lodash/get";
import { Box, List, Typography } from "@mui/material";
import PaginationLoader from "components/PaginationLoader";
import SavedReplyCollectionItem from "features/SavedReplies/containers/SavedReplyCollectionItem";
import SummaryListItem from "components/SummaryListItem";
import { fetchSavedReplyCollection } from "features/SavedReplies/api";

function SavedReplyCollection({
  activeSavedReply,
  children,
  hasManageOptions,
  fetchSavedReplyCollectionRequest,
  savedReplies: responsiveSavedReplies,
  savedReplySelectionHandler,
  savedReplyCollectionId,
  searchTerm,
  setActiveSavedReply,
  setSavedReplies,
}) {

  const showEmptySection =
    responsiveSavedReplies?.members.length === 0 &&
    searchTerm === "" &&
    !activeSavedReply;

  if (showEmptySection) {
    const emptyHeader = "You don't have any Templates!";
    return (
      <Box height="100%" padding="4rem 0rem" textAlign="center">
        <Typography variant="h5" color="text.secondary">
          {emptyHeader}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          fontSize="0.75rem"
          margin="0.25rem"
        >
          {hasManageOptions
            ? "Click on the create button to get started"
            : null}
        </Typography>
      </Box>
    );
  }

  const fetchNextPage = async () => {
    /*
     * TODO: When feature flag is deleted, can remove
     * fetchSavedReplyCollectionRequest and withRecord calls
     */
    const flaggedFetchFn = fetchSavedReplyCollection;

    try {
      const response = await flaggedFetchFn(responsiveSavedReplies?.view.next);
      const data = await response.json();
      setSavedReplies((prevState) => {
        return {
          ...prevState,
          members: [...prevState.members, ...data.members],
          view: data.view,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMore = () => {
    fetchNextPage().catch((error) => {
      console.error(error);
    });
  };

  return (
    <List sx={{ listStyle: "none", overflow: "auto" }}>
      <InfiniteScroll
        hasMore={Boolean(responsiveSavedReplies?.view.next)}
        initialLoad={false}
        loader={<PaginationLoader key="SavedReplyLoader" />}
        loadMore={handleLoadMore}
        threshold={100}
        useWindow={false}
      >
        {typeof children === "function"
          ? children({
              responsiveSavedReplies,
              selectSavedReply: savedReplySelectionHandler,
            })
          : responsiveSavedReplies?.members.map((savedReply) => {
              return (
                <SavedReplyCollectionItem
                  clickHandler={savedReplySelectionHandler}
                  fetchSavedReplyCollectionRequest={
                    fetchSavedReplyCollectionRequest
                  }
                  isActive={savedReply.id === get(activeSavedReply, ["id"])}
                  key={savedReply.id}
                  savedReply={savedReply}
                  savedReplyCollectionId={savedReplyCollectionId}
                  setActiveSavedReply={setActiveSavedReply}
                  setSavedReplies={setSavedReplies}
                />
              );
            })}
        {searchTerm ? (
          <SummaryListItem>All Results Loaded</SummaryListItem>
        ) : null}
      </InfiniteScroll>
    </List>
  );
}

export default SavedReplyCollection;

SavedReplyCollection.propTypes = {
  activeSavedReply: PropTypes.object, // passed
  hasManageOptions: PropTypes.bool, // passed
  fetchSavedReplyCollectionRequest: PropTypes.func.isRequired, // passed
  savedReplies: PropTypes.object, // passed
  savedReplySelectionHandler: PropTypes.func.isRequired, // passed
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  searchTerm: PropTypes.string, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  setSavedReplies: PropTypes.func.isRequired, // passed
  children: PropTypes.func,
};
