import { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import withStyles from "@mui/styles/withStyles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableFooter from "@mui/material/TableFooter";
import MuiTableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";

import { formatValues } from "../constants/analytics";
import createUUID from "utils/uuid";

const TableRow = withStyles((theme) => {
  return {
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  };
})(MuiTableRow);

const TableCell = withStyles((theme) => {
  return {
    root: {
      borderBottom: "none",
      fontSize: "12px",
      padding: "16px 8px",
    },
    head: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      fontWeight: 700,
    },
  };
})(MuiTableCell);

const TableFooter = withStyles((theme) => {
  return {
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      "& > .MuiTableRow-footer": {
        backgroundColor: "unset",
        "& > .MuiTableCell-root": {
          borderBottom: "none",
        },
      },
    },
  };
})(MuiTableFooter);

const style = {
  display: "flex",
  flex: "0 0 auto",
};

function ReportTable({ accountList, confirmDeletion, editReport, reports }) {
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    return setPage(newPage);
  };
  const rowsPerPage = 25;
  const formatCells = ({
    account: accountId,
    descendants,
    reports: report,
    schedule,
    timePeriod,
  }) => {
    const account =
      accountList.find(({ slug }) => {
        return isEqual(slug, accountId);
      }) ?? {};
    return formatValues({
      account,
      descendants,
      reports: report,
      schedule,
      timePeriod,
    });
  };

  const headers = [
    "Name of Report",
    "Account",
    "Descendants",
    "Frequency",
    "Time Period",
    "Day To Send",
    "",
    "",
  ];

  return (
    <TableContainer>
      <Table aria-label="Scheduled Analytics Reports Table">
        <TableHead>
          <TableRow style={{ height: 28 }}>
            {headers.map((header) => {
              return <TableCell key={createUUID()}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((report, i) => {
              return (
                <TableRow
                  key={createUUID()}
                  aria-label={`Scheduled Report Table Row ${i + 1}`}
                >
                  {formatCells(report).map(({ name }) => {
                    return (
                      <TableCell key={createUUID()} color="primary">
                        {name}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <IconButton
                      aria-label="Edit Report Settings"
                      color="primary"
                      onClick={() => {
                        return editReport(report);
                      }}
                      style={{ padding: "6px" }}
                      size="large"
                    >
                      <EditOutlinedIcon style={style} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Delete Report"
                      color="primary"
                      onClick={() => {
                        return confirmDeletion(report);
                      }}
                      style={{ padding: "6px" }}
                      size="large"
                    >
                      <DeleteOutlineIcon style={style} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={headers.length}
              count={reports.length}
              onPageChange={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

ReportTable.propTypes = {
  accountList: PropTypes.array.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  editReport: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
};

export default ReportTable;
