import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withStyles from "@mui/styles/withStyles";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { Redirect } from "react-router";

import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import MuiChip from "@mui/material/Chip";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TagCreationInfo from "./TagCreationInfo";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import InfoTooltip from "components/InfoTooltip";
import List from "components/List";
import PageLoader from "components/PageLoader";

const Tags = styled(List)``;

const Tag = styled.li`
  display: inline-block;
`;

const Chip = withStyles((muiTheme) => {
  return {
    colorPrimary: {
      background: muiTheme.palette.primary.main,
      color: muiTheme.palette.background.paper,
    },
  };
})(MuiChip);

const filter = createFilterOptions();

function Root({
  account,
  closeModal,
  contact,
  contactTagsId,
  fetchContactTagsRequest,
  updateContactRequest,
}) {
  const [nextLocation, setNextLocation] = useState(null);
  const [shouldRequest, setShouldRequest] = useState(true);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleDelete = (deletedTag) => {
    return () => {
      const tags = get(contact, ["data", "tags"], []);
      const newTags = uniq(tags).filter((tag) => {
        return tag !== deletedTag;
      });
      updateContactRequest(contact.id, {
        ...contact,
        data: { ...contact.data, tags: newTags },
      });
    };
  };

  const handleClick = (clickedTag) => {
    return () => {
      const tagLink = clickedTag.includes(" ")
        ? `q=tags:${fixedEncodeURIComponent(`"${clickedTag}"`)}`
        : `q=tags:${fixedEncodeURIComponent(`${clickedTag}`)}`;
      setNextLocation(tagLink);
      if (closeModal) closeModal();
    };
  };

  const handleSelectOption = (option) => {
    const { value = "" } = option;
    const sanitizedValue = value.replace(/["',]/g, "").trim();

    const tags = get(contact, ["data", "tags"], []);
    const newTags = uniq([...tags, sanitizedValue]);

    updateContactRequest(contact.id, {
      ...contact,
      data: { ...contact.data, tags: newTags },
    });
  };

  const requestTags = () => {
    if (shouldRequest) {
      fetchContactTagsRequest(contactTagsId, null, {
        successCallback: (record) => {
          const newOptions = get(record, ["members"], []).map((tag) => {
            return {
              value: tag.id,
              label: tag.tag,
            };
          });
          setOptions(newOptions);
          setShouldRequest(false);
        },
      });
    }
  };

  const contactTags = get(contact, ["data", "tags"], []) || [];
  const selectOptions = options.filter(({ value }) => {
    return !contactTags.includes(value);
  });

  if (nextLocation)
    return (
      <Redirect
        push
        to={{
          pathname: `/${account.slug}/contacts/search`,
          search: nextLocation,
        }}
      />
    );

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label;
  };

  const onChange = (event, newValue) => {
    if (typeof newValue === "string") {
      handleSelectOption({ value: newValue });
    } else if (newValue?.inputValue) {
      handleSelectOption({ value: newValue.inputValue });
    } else {
      handleSelectOption(newValue);
    }
  };

  const filterOptions = (opts, params) => {
    const filtered = filter(opts, params);

    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const onInputChange = (e, input, reason) => {
    if (reason === "reset") {
      setInputValue("");
    } else {
      setInputValue(input);
    }
  };

  return (
    <Box p={2.5} width="100%">
      <Box position="relative" width="100%">
        <InfoTooltip title={<TagCreationInfo />}>
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            color="text.secondary"
            px={1}
            height="100%"
            style={{ zIndex: 1 }}
          >
            <InfoIcon
              color="inherit"
              style={{
                cursor: "help",
              }}
              fontSize="small"
            />
          </Box>
        </InfoTooltip>
        <Autocomplete
          onOpen={requestTags}
          onChange={onChange}
          disableClearable
          inputValue={inputValue}
          onInputChange={onInputChange}
          options={selectOptions}
          filterOptions={filterOptions}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="autocomplete-tags"
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => {
            return <li {...props}>{option.label}</li>;
          }}
          blurOnSelect
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Add or create tags"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: { paddingLeft: 24 },
                }}
              />
            );
          }}
        />
      </Box>
      <Box mt={2.5} position="relative">
        <Tags>
          {uniq(contactTags).map((tag) => {
            return (
              <Tag key={tag}>
                <Chip
                  size="small"
                  label={tag}
                  onClick={handleClick(tag)}
                  onDelete={handleDelete(tag)}
                />
              </Tag>
            );
          })}
        </Tags>
        {false && <PageLoader />}
      </Box>
    </Box>
  );
}

Root.propTypes = {
  account: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  contact: PropTypes.object.isRequired,
  contactTagsId: PropTypes.string.isRequired,
  fetchContactTagsRequest: PropTypes.func.isRequired,
  updateContactRequest: PropTypes.func.isRequired,
};

export default Root;
