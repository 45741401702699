import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { transparentize } from "polished";
import { ShortenedLinkModalProps } from "../models";
import ModalContent from "components/ModalContent";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";

function ShortenedLinkModal({
  shortenedLink,
  setShortenedLink,
  handleToggle,
  open,
  selectionHandler,
}: ShortenedLinkModalProps) {
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const [linkInput, setLinkInput] = useState("");

  useEffect(() => {
    setLinkInput(shortenedLink?.fullLink);
  }, [shortenedLink?.fullLink]);

  const isEditing = shortenedLink?.fullLink.length > 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return setLinkInput(event?.target.value);
  };

  const handleSave = () => {
    setShortenedLink({ ...shortenedLink, fullLink: linkInput });
    if (!isEditing) selectionHandler(shortenedLink);
    handleToggle();
  };

  const modalCopy = {
    header: "Link shortener",
    body: `Enter the link below. Once your message is sent, recipients will see a branded, shortened URL link.`,
    caption: `Your branded base URL for shortened links is ${shortenedLink?.shortLink}`,
    helperText: "Please enter a valid URL",
    label: "Add URL",
    ariaLabel: "Shortened Link Modal",
  };

  // URL validation RegEx from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
  const websiteRegEx =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  const invalidInput = linkInput?.length > 0 && !websiteRegEx.test(linkInput);
  return (
    <Dialog
      open={open}
      fullScreen={mobileScreen}
      onClose={handleToggle}
      aria-label={modalCopy.ariaLabel}
      slotProps={{
        backdrop: {
          sx: (theme) => {
            return {
              background: mobileScreen
                ? "transparent"
                : transparentize(0.1, theme.palette.primary.main ?? "#283C59"),
            };
          },
        },
      }}
      sx={{ top: mobileScreen ? "55px" : 0 }}
    >
      <DialogHeader
        title="Link shortener"
        onClose={handleToggle}
        fullScreen={mobileScreen}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <ModalContent>
          <Box>
            <Typography gutterBottom>{modalCopy.body}</Typography>
            <TextField
              error={invalidInput}
              fullWidth
              helperText={invalidInput && modalCopy.helperText}
              label={modalCopy.label}
              onChange={handleChange}
              size="medium"
              sx={{ marginTop: "12px" }}
              value={linkInput}
            />
            <Typography variant="caption" color="text.secondary">
              {modalCopy.caption}
            </Typography>
          </Box>
        </ModalContent>
        <DialogFooter
          onCancel={handleToggle}
          onConfirm={handleSave}
          confirmText="Save"
          isConfirmDisabled={linkInput?.length === 0 || invalidInput}
        />
      </Box>
    </Dialog>
  );
}

export default ShortenedLinkModal;
