import { useState } from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "redux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InfoTooltip from "../InfoTooltip";
import { InfoOutlinedIcon } from "icons";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

function AutomaticMessageIndicator({ theme, currentAccount }) {
  const autoReplyObject = get(currentAccount, [
    "settings",
    "autoReplyMessage",
    "value",
  ]);

  const introMessage = get(currentAccount, [
    "settings",
    "introMessage",
    "value",
  ]);

  const autoReplyMessageEnabled = get(currentAccount, [
    "settings",
    "autoReplyMessageEnabled",
    "value",
  ]);

  const introMessageEnabled = get(currentAccount, [
    "settings",
    "introMessageEnabled",
    "value",
  ]);

  const getAutoReplyDescription = () => {
    if (autoReplyObject && autoReplyObject.officeHours) {
      return "This message has been configured to send once every 24 hours outside your office hours.";
    }
    return "This message has been configured to send every 24 hours in response to all recipients.";
  };

  const getAutomaticMessages = () => {
    return (
      <Box
        p={2}
        sm={{ maxWidth: "90vw" }}
        md={{ maxWidth: "400px" }}
        fontSize={12}
      >
        <Box component="h3" mt={0}>
          Automated Messages Enabled
        </Box>
        {introMessageEnabled && introMessage && (
          <>
            <Box component="h4" mb={0}>
              Introduction Message
            </Box>
            <Box fontSize={10} color="text.secondary" mb={1}>
              The following introduction message will be sent the first time you
              message a contact.
            </Box>
            <Box
              p={2}
              borderRadius="16px"
              bgcolor={theme.colors.message.outboundBackground}
              color="text.primary"
            >
              <Typography gutterBottom>{introMessage}</Typography>
              <Typography gutterBottom>
                Reply STOP to unsubscribe. Msg & Data Rates May Apply.
              </Typography>
            </Box>
          </>
        )}
        {autoReplyMessageEnabled && autoReplyObject && (
          <>
            <h4>Auto Reply</h4>
            <Box fontSize={10} color="text.secondary" mt={-2} mb={1}>
              {getAutoReplyDescription()}
            </Box>
            <Box
              p={2}
              borderRadius="16px"
              bgcolor={theme.colors.message.outboundBackground}
              color="text.primary"
            >
              <Typography gutterBottom>{autoReplyObject.message}</Typography>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const shouldRender = autoReplyMessageEnabled || introMessageEnabled;

  const [open, setOpen] = useState(false);

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    !!shouldRender && (
      <Box
        display="flex"
        flex="0 0 1"
        padding="0.25rem"
        justifyContent="flex-end"
        sx={(muiTheme) => {
          return {
            backgroundColor:
              muiTheme.palette.customColors.backgrounds.secondary,
            borderColor: muiTheme.palette.customColors.enabledBorder,
            borderRadius: "0 0 0.5rem 0.5rem",
          };
        }}
      >
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <InfoTooltip
            aria-label="More information"
            title={getAutomaticMessages()}
            onClick={handleTooltipToggle}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Box
              display="flex"
              flex="0 0 auto"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              color="text.secondary"
              style={{ zIndex: 10 }}
            >
              <Box display="flex" flex="0 0 auto" mr={1} color="text.secondary">
                Automated Messages Enabled
              </Box>
              <InfoOutlinedIcon fontSize="small" />
            </Box>
          </InfoTooltip>
        </ClickAwayListener>
      </Box>
    )
  );
}

AutomaticMessageIndicator.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTheme)(AutomaticMessageIndicator);
